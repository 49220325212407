import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import TemporaryDrawer from "../../../components/modal/DrawerTable";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";

function Temporary(props) {
  //   const [drawerOpen, setDrawerOpen] = useState(st);
  const { elem, drawerOpen, setDrawerOpen } = props;

  const { pathname } = useLocation();

  if (!elem) return null;
  return (
    <TemporaryDrawer
      header="Techers information"
      width="1600px"
      // height="1500px"
      paddingBottom="10px"
      marginTop="30px"
      access={true}
      open={drawerOpen}
      handleClose={() => {
        setDrawerOpen(false);
      }}
      style={{ zIndex: 2 }}
      child="hello"
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "800", fontSize: "28px" }}>
            {elem.fullName}
          </Typography>
          <Typography sx={{ fontSize: "16px" }}>
            {pathname == "/dashboard/courses/:id/tutors" ? (
              <div>
                Position: <span className="font-bold">{elem.position}</span>
              </div>
            ) : (
              ""
            )}
          </Typography>
        </Box>
        <Box>
          <img
            style={{ border: "1px solid white", borderRadius: "50px" }}
            width={98}
            src={`${process.env.REACT_APP_ASSETS_URL}${elem.photo}`}
            alt=""
          />
      
        </Box>
      </CardContent>

      {pathname == "/dashboard/courses/:id/tutors" ? (
        <div>
          <Card
            sx={{
              width: { xs: " 90vw", sm: 464 },
              // height: "100px",
              padding: "10px",
              mt: 3,
            }}
          >
            <CardContent style={{ padding: "18px" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Grid
                  direction="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Work Place:
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                      textAlign: "right",
                    }}
                  >
                    {elem.workplace}
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Experience:
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                      textAlign: "right",
                    }}
                  >
                    {elem.experience}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div>
          <Card
            sx={{
              width: { xs: " 90vw", sm: 464 },
              height: "200px",
              mt: 3,
            }}
          >
            <CardContent style={{ padding: "18px" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Grid
                  direction="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Registrated:
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    {`${new Date(elem.registrationDate).getFullYear()} 
                    ${new Date(elem.registrationDate).getMonth() + 1} 
                    ${new Date(elem.registrationDate).getDate()}`}
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Birth date:
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                      textAlign: "right",
                    }}
                  >
                    {`${new Date(elem.birthDate).getFullYear()} 
                    ${new Date(elem.registrationDate).getMonth() + 1} 
                    ${new Date(elem.registrationDate).getDate()}`}
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Address:
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                      textAlign: "right",
                    }}
                  >
                    {elem.addres}
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Phone number:
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "14px",

                      lineHeight: "20px",
                      textAlign: "right",
                    }}
                  >
                    {elem.phone}
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    E-mail:
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                      textAlign: "right",
                    }}
                  >
                    {elem.email}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ marginTop: 3, height: "95px" }}>
            <Grid
              direction="row"
              sx={{
                display: "flex",
                flexDirection: "row",

                margin: "auto",
                paddingLeft: 3,
                paddingRight: 6,
                paddingTop: 4,
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                md={5}
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "20px",
                }}
              >
                Group
              </Grid>
              <Grid
                item
                md={5}
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "20px",
                  textAlign: "right",
                }}
              >
                {elem.phone}
                {/* {group.find((el) => el.id === elem?.groupId).name} */}
              </Grid>
            </Grid>
          </Card>
        </div>
      )}
      <Card sx={{ marginTop: 3, height: "95px" }}>
        <Grid
          direction="row"
          sx={{
            display: "flex",
            flexDirection: "row",

            margin: "auto",
            paddingLeft: 3,
            paddingRight: 6,
            paddingTop: 4,
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            md={5}
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "20px",
            }}
          >
            Status
          </Grid>
          <Grid
            item
            md={5}
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              textAlign: "right",
            }}
          >
            {elem.status}
            {/* {group.find((el) => el.id === elem?.groupId).name} */}
          </Grid>
        </Grid>
      </Card>
    </TemporaryDrawer>
  );
}

export default Temporary;
