import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../utils/axiosRequest";
import paramsForUrl from "../../utils/paramsForUrl";

import paramsForUrl2 from "../../utils/paramsForUrl2";

import axios from "axios";
import toast from "react-hot-toast";

export const getALLCourses = createAsyncThunk(
  "courses/getALLCourses",
  async (param, { rejectWithValue }) => {
    let obj = paramsForUrl2(param);
    try {
      const { data } = await axiosRequest.get(`Course/GetAllCourses?${obj}`);

      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
export const getReviewerById = createAsyncThunk(
  "courses/getReviewerById",
  async (param, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(
        `Reviewer/GetReviewerById?ReviewerId=${param.ReviewerId}&Language=${param.lang}`
      );

      return data.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getCourseById = createAsyncThunk(
  "courses/getCourseById",
  async (param, { rejectWithValue }) => {
    let obj = paramsForUrl(param);
    try {
      const { data } = await axiosRequest.get(`Course/GetCourseById${obj}`);
      return data.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deleteCourseById = createAsyncThunk(
  "courses/deleteCourseById",
  async (params, { dispatch, rejectWithValue }) => {
    let obj = paramsForUrl(params);

    try {
      const { data } = await axiosRequest.delete(`Course/DeleteCourse${obj}`);
      toast.success(data?.message);
    } catch (error) {
      rejectWithValue(error);
      toast.error(error);
    }
  }
);

export const deleteSyllabusById = createAsyncThunk(
  "courses/deleteSyllabusById",
  async (param, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.delete(
        `Syllabus/DeleteSyllabus?SyllabusId=${param.id}`
      );
      if (data.code === 200) {
        toast.success(data.message); // Display success message
        dispatch(getSyllabus(param.obj));
      }
    } catch (error) {
      return rejectWithValue(error.response.data); // Return error data with rejectWithValue
    }
  }
);

export const getSyllabus = createAsyncThunk(
  "courses/getSyllabus",
  async (param) => {
    let obj = paramsForUrl(param);

    try {
      const { data } = await axiosRequest.get(
        `Syllabus/GetAllSyllabuses${obj}&PageSize=1000`
      );

      return data.data;
    } catch (error) {}
  }
);

export const getLearn = createAsyncThunk("courses/getLearn", async (param) => {
  let obj = paramsForUrl(param);

  try {
    const { data } = await axiosRequest.get(
      `CourseSkill/GetCourseSkills${obj}&PageSize=1000`
    );

    return data.data;
  } catch (error) {}
});
export const deleteLearn = createAsyncThunk(
  "courses/deleteLearn",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.delete(
        `CourseSkill/DeleteCourseSkill?CourseSkillId=${id}`
      );

      // dispatch(getLearn());
      if (data.code < 300) {
        toast.success(data.message);
      } else if (data.code >= 400) {
        toast.error(data.message);
      }
    } catch (error) {}
  }
);

export const addSyllbus = createAsyncThunk(
  "courses/addSyllbus",
  async ({ courseId, syllabuses }, { dispatch }) => {
    try {
      const response = await axiosRequest.put(
        `Syllabus/AddSyllabusesToCourse`,
        {
          courseId: courseId,
          syllabuses: syllabuses,
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message); // Display success message
      }

      dispatch(
        getSyllabus({ Language: syllabuses[0].language, courseId: courseId })
      ); // Подстройте язык по умолчанию в соответствии с вашими нуждами

      return response.data;

      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        dispatch(
          getSyllabus({ Language: syllabuses[0].language, courseId: courseId })
        );
        return response.data;
      }
    } catch (error) {
      console.error("Ошибка при добавлении учебного плана:", error);
      throw error;
    }
  }
);

export const addLearn = createAsyncThunk(
  "courses/addLearn",
  async ({ courseId, Language, learn }, { dispatch }) => {
    try {
      const response = await axiosRequest.post(
        `CourseSkill/AddOrUpdateCourseSkill`,
        {
          courseId: +courseId,
          Language: Language.toLowerCase(),
          CourseSkillDtos: learn,
        }
      );

      dispatch(getLearn({ Language: Language, courseId: courseId }));
      return response.data;
    } catch (error) {
      console.error("Ошибка при добавлении учебного плана:", error);
      throw error;
    }
  }
);

export const deleteSyllabus = createAsyncThunk(
  "courses/deleteSyllabus",
  async (param) => {
    let obj = paramsForUrl(param);
    try {
      const { data } = await axiosRequest.get(`Syllabus/DeleteSyllabus${obj}`);
      getSyllabus();
      return data.data;
    } catch (error) {}
  }
);
export const getTraining = createAsyncThunk(
  "courses/getTraining",
  async (param) => {
    let obj = paramsForUrl(param);

    try {
      const { data } = await axiosRequest.get(
        `CourseTraining/GetCourseTrainings?CourseId=${param.id}&Language=${param.language}`
      );

      return data.data;
    } catch (error) {}
  }
);
export const deleteTraining = createAsyncThunk(
  "courses/deleteTraining",
  async (param) => {
    let obj = paramsForUrl(param);
    try {
      const { data } = await axiosRequest.delete(
        `CourseTraining/DeleteCourseTrainingById?CourseTrainingId=${param.id}`
      );
      getTraining();
      return data.data;
    } catch (error) {}
  }
);

export const getAllTutors = createAsyncThunk(
  "courses/getAllTutors",
  async (params) => {
    let obj = paramsForUrl(params);

    try {
      const { data } = await axiosRequest.get(`Teacher/GetAllTeachers${obj}`);

      return data.data;
    } catch (error) {}
  }
);

export const getTutor = createAsyncThunk("courses/getTutor", async (params) => {
  let obj = paramsForUrl(params);

  try {
    const { data } = await axiosRequest.get(`Teacher/GetTeacher${obj}`);

    return data.data;
  } catch (error) {}
});
export const deleteTutorsSkills = createAsyncThunk(
  "courses/deleteTutorsSkills",
  async (id) => {
    try {
      const { data } = await axiosRequest.delete(
        `Teacher?teacherSkillId=${id}`,
        id
      );
      if (data.code === 200) {
        toast.success(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }
);
export const editSubCategory = createAsyncThunk(
  "todos/editSubCategory",
  async function (check, { dispatch }) {
    try {
      const { data } = await axiosRequest.put(
        `Teacher/UpdateTeacher/LastName=${check.title}`,
        {
          title: check.LastName,
        }
      );
      dispatch(getAllTutors());
    } catch (error) {}
  }
);

export const editUser = createAsyncThunk(
  "todos/editUser",
  async function (check, { dispatch }) {
    try {
      const { data } = await axiosRequest.put(
        `Teacher/UpdateTeacher/?Id=${check.id}/?fullName=${check.fullName}/?position=${check.position}/?about=${check.about}/?workplace=${check.workplace}/?experience=${check.experience}`,
        {
          fullName: check.fullName,
          position: check.position,
          about: check.about,
          workplace: check.workplace,
          experience: check.experience,
        }
      );
      dispatch(getAllTutors());
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteTutors = createAsyncThunk(
  "tutors/deleteTutors",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(`Course/teachers/un-assing`, id);

      if (data.code == 200) {
        toast.success("teacher deleted");
      }
      dispatch(getAllTutors({ Language: "rus" }));
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addCourseTutors = createAsyncThunk(
  "course/addCourseTutors",
  async (obj) => {
    try {
      const { data } = await axiosRequest.post("Course/teachers/assing", obj);
      if (data?.code == 200) {
        toast.success(data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const postCourse = createAsyncThunk(
  "courses/postCourse",
  async ({ formData, callback }) => {
    try {
      const { data } = await axiosRequest.post(
        `Course/AddNewCourse`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (data.code == 200) {
        toast.success(data.message);

        callback();
      }
    } catch (e) {
      toast.error("Course Not added");
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "courses/deleteCourse",
  async (param, { rejectWithValue }) => {
    let obj = paramsForUrl(param);
    try {
      const { data } = await axiosRequest.delete(`Course/DeleteCourse${obj}`);
    } catch (e) {
      rejectWithValue();
    }
  }
);

export const getSallaries = createAsyncThunk(
  "courses/getSallaries",
  async (param, { rejectWithValue }) => {
    try {
      let obj = paramsForUrl2(param).slice(1);
      const { data } = await axiosRequest.get(`Salary/GetAllSalaries?${obj}`);

      return data.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);
export const deleteSallaries = createAsyncThunk(
  "courses/deleteSallaries",
  async (param, { rejectWithValue }) => {
    let obj = paramsForUrl(param);
    try {
      const { data } = await axiosRequest.delete(
        `Salary/DeleteSalary?SalaryId=${param}`
      );
      getSallaries();
    } catch (e) {
      rejectWithValue();
    }
  }
);

export const getReviewers = createAsyncThunk(
  "courses/getReviewers",
  async (obj, { rejectWithValue }) => {
    const param = paramsForUrl(obj);
    try {
      const { data } = await axiosRequest.get(
        `Reviewer/GetAllReviewers${param}`
      );

      return data.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);
export const deleteReviewer = createAsyncThunk(
  "courses/deleteReviewer",
  async (param, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.delete(
        `Reviewer/DeleteReviewer?ReviewerId=${param}`
      );
      getReviewers();
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getAllCourseSkills = createAsyncThunk(
  "courses/getAllCourseSkills",
  async (obj, { rejectWithValue }) => {
    const param = paramsForUrl(obj);

    try {
      const { data } = await axiosRequest.get(
        `CourseSkill/GetCourseSkills${param}`
      );
      return data.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const postCourseSkills = createAsyncThunk(
  "courses/postCourseSkills",
  async (obj, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.put(
        `CourseSkill/AddOrUpdateCourseSkill`,
        obj.form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (data.code < 300) {
        toast.success(data.message);
        dispatch(getLearn(obj.obj));
      }
    } catch (e) {
      toast.error(e);
      rejectWithValue(e);
    }
  }
);
export const postCourseSyllbus = createAsyncThunk(
  "courses/postCourseSyllbus",
  async (obj, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        `CourseSkill/AddOrUpdateCourseSkill`,
        obj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      dispatch(getAllCourseSkills({}));
      return data.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const deleteCourseSkills = createAsyncThunk(
  "courses/deleteCourseSkills",
  async (obj, { rejectWithValue }) => {
    const param = paramsForUrl(obj);

    try {
      const { data } = await axiosRequest.delete(
        `CourseSkill/DeleteCourseSkill${param}`
      );
      return data.data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);
