import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

export const FormInputRadio = ({ name, edit, selected, control, options }) => {
  const generateRadioOptions = (value) => {
    return options.map((singleOption) => {
      console.log(singleOption.value);
      if (singleOption.value === value) {
        return (
          <FormControlLabel
            key={singleOption.value}
            required={true}
            sx={{
              border: "2px solid #39A0CC",
              width: "131px",
              height: "48px",
              borderRadius: "16px",
            }}
            value={singleOption.value}
            label={singleOption.label}
            control={<Radio />}
          />
        );
      }

      return (
        <FormControlLabel
          required={true}
          sx={{
            width: "131px",
            height: "48px",
            borderRadius: "16px",
          }}
          value={singleOption.value}
          label={singleOption.label}
          control={<Radio />}
        />
      );
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <RadioGroup
          value={value}
          defaultValue={edit}
          onChange={onChange}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          {generateRadioOptions(value)}
        </RadioGroup>
      )}
    />
  );
};
