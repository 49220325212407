import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import DashboardLayout from "./components/dashboard/dashboard-layout";
import SplashScreen from "./components/splash-screen";
import Login from "./pages/Login";
import AddCourse from "./pages/dashboard/Courses/Add";
import AddReviewers from "./pages/dashboard/Courses/AddReviewers";
import AddTutor from "./pages/dashboard/Courses/AddTutor";
import Course from "./pages/dashboard/Courses/Course";
import EditCourse from "./pages/dashboard/Courses/Edit.jsx";
import Group from "./pages/dashboard/Courses/Group";
import Learn from "./pages/dashboard/Courses/Learn";
import Reviewers from "./pages/dashboard/Courses/Reviewers";
import SalaryPerCompanies from "./pages/dashboard/Courses/SalaryPerCompanies";
import Syllbus from "./pages/dashboard/Courses/Syllbus";
import Tutors from "./pages/dashboard/Courses/Tutors";

import EditReviewers from "./pages/dashboard/Courses/EditReviewers";
import EditTutors from "./pages/dashboard/Courses/EditTutors.jsx";
import Training from "./pages/dashboard/Courses/Training.jsx";
import Overview from "./pages/dashboard/Overview";
import Edit from "./pages/dashboard/Posts/Edit.jsx";
import Posts from "./pages/dashboard/Posts/Posts";
import AddPost from "./pages/dashboard/Posts/add";
import Students from "./pages/dashboard/Students/Students";
import Translation from "./pages/dashboard/Translation/Translation";
import AuthCheck from "./utils/AuthCheck.js";
import ProtectedRout from "./utils/ProtectedRout.js";
function App() {

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <AuthCheck>
              <Login />
            </AuthCheck>
          }
        />
        <Route
          path="dashboard"
          element={
            <Suspense fallback={<SplashScreen />}>
              <ProtectedRout>
                <DashboardLayout />
              </ProtectedRout>
            </Suspense>
          }
        >
          <Route index element={<Overview />} />
          <Route path="courses" element={<Course />} />
          <Route path="group" element={<Group />} />
          <Route path="courses/add" element={<AddCourse />} />
          <Route path="courses/:id/edit" element={<EditCourse />} />
          <Route path="courses/:id/syllbus" element={<Syllbus />} />
          <Route path="courses/:id/training" element={<Training />} />
          <Route path="courses/:id/tutors" element={<Tutors />} />
          <Route path="courses/:id/tutors/addtutor" element={<AddTutor />} />
          <Route
            path="courses/:id/tutors/:edittutorId/edit"
            element={<EditTutors />}
          />
          <Route path="courses/:id/learn" element={<Learn />} />
          <Route
            path="courses/:id/salarypercompanies"
            element={<SalaryPerCompanies />}
          />
          <Route path="courses/:id/reviewers" element={<Reviewers />} />
          <Route path="courses/:id/reviewers/add" element={<AddReviewers />} />
          <Route
            path="courses/:courseId/reviewers/:id/edit"
            element={<EditReviewers />}
          />
          <Route path="posts" element={<Posts />} />
          <Route path="posts/add" element={<AddPost />} />
          <Route path="posts/:id/update" element={<Edit />} />

          <Route path="students" element={<Students />} />
          <Route path="translation" element={<Translation />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;