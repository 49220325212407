import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { axiosLogin, saveToken } from "../utils/axiosRequest";
import { toast } from "react-hot-toast";

function Login(props) {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { data } = await axiosLogin.post("Auth/Login", {
          username: values.username,
          password: values.password,
        });
        console.log(data.code);

        if (data.code === 200) {
          console.log("Success");
          toast.success(data?.message);
          saveToken(data.data.token);
          setTimeout(() => {
            {
              console.log(data);
            }
            navigate("/dashboard");
          }, 500);
          return;
        }

        if (data.code !== 200) {
          toast.error(data?.message);
        }
      } catch (err) {
        toast.error(err);
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} {...props}>
      <TextField
        fullWidth
        label="Username"
        name="username"
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
        error={Boolean(formik.touched.username && formik.errors.username)}
        helperText={formik.touched.username && formik.errors.username}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />

      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
      />
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{formik.errors.submit}</FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Log In
        </Button>
      </Box>
    </form>
  );
}

export default Login;
