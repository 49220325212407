import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import Select from "@mui/material/Select";
import "@uiw/react-markdown-preview/markdown.css";
import "@uiw/react-md-editor/markdown-editor.css";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "../../../components/dashboard/courses/CustomTable";
import nextIcon from "../../../icons/next.png";
import { Search as SearchIcon } from "../../../icons/search";
// import { useParams } from "react-router-dom";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Loading from "../../../components/custom/Loading";
import PostListCard from "../../../components/dashboard/Posts/postsCard";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { GetAllPosts, deletePost } from "../../../api/posts/posts";
import engIcon from "../../../icons/english.png";
import russianIcon from "../../../icons/rusian.png";
import tjkIcon from "../../../icons/tjk.png";
import {
  handleLanguagesChange,
  reset,
} from "../../../reducers/courses/courses";

function Posts() {
  const [value, setValue] = useState("");
  const [isCard, setIsCard] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  // modal
  console.log(pathname);
  const [open, setOpen] = useState(false);

  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const posts = useSelector(({ posts }) => posts.posts.posts);
  const totalPages = useSelector(({ posts }) => posts.posts.totalPages);
  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 
  const handleChangeTab = (event, newValue) => {
    console.log(event, newValue);
    setValue(newValue);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    console.log(event);
    setPage(1);
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(
      GetAllPosts({
        Title: "",
        Language: value ? value : "rus",
        PostStatus: statusFilter ? statusFilter : "",
        PageNumber: page === 0 ? 1 : page,
        PageSize: 10,
      })
    );
  }, [page, value, rowsPerPage, dispatch, statusFilter]);

  const handleStatusSelect = (event) => {
    setStatusFilter(event.target.value);
    dispatch(
      GetAllPosts({
        PostStatus: event.target.value,
        Language: value ? value : "rus",
      })
    );
  };

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    dispatch(
      GetAllPosts({
        Title: searchTerm,
        Language: value?value:"rus",
        PostStatus: "",
        PageNumber: page === 0 ? 1 : page,
        PageSize: 10,
      })
    );
  };

  return (
    <>
      {isLoading ? (
        width >= 1200 ? (
          <Loading width={true} />
        ) : (
          <Loading width={false} />
        )
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          px: 2,
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 4 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{ lineHeight: "36px", fontSize: "24px" }}
                >
                  Posts
                </Typography>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  width: "405px",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Box
                    sx={{
                      width: "216px",
                      height: "48px",
                      background: "#D4D4D4",
                      borderRadius: "16px",
                      padding: "4px",
                    }}
                  >
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      aria-label="Disabled elevation buttons"
                    >
                      <IconButton
                        sx={
                          isCard === true
                            ? { color: "#212121", background: "#fff" }
                            : {}
                        }
                        onClick={() => setIsCard(true)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "14px",
                            marginX: "10px",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <FormatListBulletedIcon />
                          <Typography>List</Typography>
                        </Box>
                      </IconButton>
                      <IconButton
                        sx={
                          isCard === false
                            ? { color: "#212121", background: "#fff" }
                            : {}
                        }
                        onClick={() => setIsCard(false)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "14px",
                            marginX: "10px",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <AppsIcon />
                          <Typography>Grid</Typography>
                        </Box>
                      </IconButton>
                    </ButtonGroup>
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <IconButton
                      onClick={handleClickOpen}
                      // onClick={() => navigate(`${pathname}/add`)}
                      style={{
                        background: "#506A85",
                        color: "#fff",
                        borderRadius: "16px",

                        height: "48px",
                        width: "150px",
                      }}
                    >
                      <AddIcon />
                      <Typography
                        sx={{
                          ml: "5px",
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontStyle: "normal",
                        }}
                      >
                        Add new
                      </Typography>
                    </IconButton>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <TextField
                value={searchTerm}
                onChange={handleSearchInputChange}
                defaultValue=""
                // name="firstName"
                size="small"
                sx={{
                  borderRadius: "10px !important",
                  border: "2px solid #B1C4D7",
                  backgroundColor: "white",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />
            </Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "380px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value}
              onChange={handleChangeTab}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              <Tab
                icon={<img src={russianIcon} alt="russianIcon" />}
                iconPosition="start"
                label="RUS"
                value={"RUS"}
              />
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  border: "1px solid rgb(163 163 155)",
                  marginTop: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></span>
              <Tab
                icon={<img src={tjkIcon} alt="tjkIcon" />}
                iconPosition="start"
                label="TAJ"
                value={"TAJ"}
              />
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  border: "1px solid rgb(163 163 155)",
                  marginTop: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></span>
              <Tab
                icon={<img src={engIcon} alt="engIcon" />}
                iconPosition="start"
                label="ENG"
                value="ENG"
              />
            </Tabs>

            <Select
              sx={{
                border: "2px solid #B1C4D7",
                borderRadius: "16px",
                width: "240px",
                height: "48px",
              }}
              id="demo-simple-select-helper"
              value={statusFilter}
              onChange={handleStatusSelect}
            >
              <MenuItem value="All Status">
                <em>All Status</em>
              </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Hidden">Hidden</MenuItem>
            </Select>
          </Box>
          {/* Table  */}
          {isCard && (
            <CustomTable
              headers={[
                "Title",
                "Subtitle",
                "Like & dislike",
                "Publish date",
                "Comments",
                "Status",
                "Action",
              ]}
            >
              {posts?.length > 0 &&
                posts?.map((elem, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        sx={{ fontSize: "16px", textAlign: "center" }}
                      >{`${index + 1}. ${elem?.title}`}</TableCell>
                      <TableCell
                        sx={{
                          // width: "270px",
                          fontSize: "16px",
                          textAlign: "center",
                          lineHeight: "20px",
                        }}
                      >
                        {elem?.subTitle?.slice(0, 100)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ThumbUpOffAltIcon color="success" />
                            {elem?.like}
                          </Box>
                          <Box
                            sx={{
                              width: "1px",
                              height: "36px",
                              backgroundColor: "#E6E6E6",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ThumbDownOffAltIcon color="error" />
                            {elem?.disLike}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {elem?.publishDate?.slice(0, 10)}
                      </TableCell>
                      <TableCell sx={{}}>
                        {elem?.comments?.slice(0, 3).map((e) => {
                          return (
                            <div key={e.id}>
                              <>{e.content}</>
                            </div>
                          );
                        })}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          onClick={() => {
                            dispatch(deletePost(elem?.id));
                            dispatch(
                              GetAllPosts({
                                Title: searchTerm,
                                Language: value ? value : "rus",
                                PostStatus: "",
                                PageNumber: page === 0 ? 1 : page,
                                PageSize: 10,
                              })
                            );
                          }}
                          sx={{
                            backgroundColor: "#DCFCE7",
                            width: "78px",
                            height: "28px",
                            borderRadius: "100px",
                          }}
                        >
                          {elem?.postStatus}
                        </Button>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          onClick={() =>
                            navigate(`${pathname}/${elem.id}/update`)
                          }
                        >
                          <img src={nextIcon} alt="img" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </CustomTable>
          )}
          {isCard === false && (
            <PostListCard
              posts={posts}
              postsCount={posts.length}
              count={Math.ceil(posts.length / rowsPerPage)}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          )}
        </Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
            <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

            <Card>
              <CardContent>
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 486,
                    bgcolor: "background.paper",
                  }}
                >
                  {dataLanguages.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                      <ListItem
                        key={value}
                        secondaryAction={
                          <Checkbox
                            checked={value.completed}
                            edge="end"
                            onClick={() => handleToggle(value.id)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemAvatar>
                            <Avatar alt={`Avatar`} src={value.avatar} />
                          </ListItemAvatar>

                          <ListItemText
                            id={labelId}
                            primary={` ${value.language}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </CardContent>
            </Card>
            <Box
              container
              sx={{
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                onClick={() => navigate(`${pathname}/add`)}
                sx={{ m: 1 }}
                variant="contained"
              >
                Add
              </Button>

              <Button
                sx={{
                  m: 1,
                  mr: "auto",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "end",
            mr: "20px",
            pb: "30px",
          }}
        >
          <Pagination
            color="primary"
            page={page}
            count={totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
}

export default Posts;
