import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteLearn,
  getLearn,
  postCourseSkills,
} from "../../../api/courses/courses";
import engIcon from "../../../icons/english.png";
import russianIcon from "../../../icons/rusian.png";
import tjkIcon from "../../../icons/tjk.png";
import { fileToBase64 } from "../../../utils/file-to-base64";

function Learn() {
  const { pathname } = useLocation();

  const learn = useSelector(({ courses }) => courses.courses.learn);

  const navigate = useNavigate();
  const courseSkills = useSelector(
    ({ courses }) => courses.courses.courseSkills
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState("RUS");
  const [cover, setCover] = useState(
    JSON.parse(localStorage.getItem("cover")) || {}
  );
  const [file, setFile] = useState(
    JSON.parse(localStorage.getItem("file")) || {}
  );

  useEffect(() => {
    localStorage.setItem("cover", JSON.stringify(cover));
    localStorage.setItem("file", JSON.stringify(file));
  }, [cover, file]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDropCover = async (id, file) => {
    const data = await fileToBase64(file);
    setCover((prevCover) => ({
      ...prevCover,
      [id]: data,
    }));
    setFile((prevFiles) => ({
      ...prevFiles,
      [id]: file,
    }));
  };
  const handleRemove = (id) => {
    setCover((prevCover) => ({
      ...prevCover,
      [id]: null,
    }));
    setFile((prevFiles) => ({
      ...prevFiles,
      [id]: null,
    }));
  };

  const submit = async () => {
    if (!value) {
      return alert("Please choose language");
    }

    let formData = new FormData();
    formData.append("courseId", +id);
    formData.append("language", value);
    formData.append("courseSkillDtos[0].id", 0);
    formData.append("courseSkillDtos[0].logo", file[id]);
    formData.append("courseSkillDtos[0].title", "string");
    formData.append("courseSkillDtos[0].description", "string");
    try {
      await dispatch(
        postCourseSkills({
          form: formData,
          obj: { CourseId: id, Language: value },
        })
      );
      // dispatch(getAllCourseSkills({ id }));
    } catch (error) {}
  };

  // const handleRemoveLogo = (id) => {
  //   let arr1 = arr.map((elem) => {
  //     if (elem.id === id) {
  //       elem.logo = "";
  //       elem.cover = "";
  //     }
  //     return elem;
  //   });

  //   setArr(arr1);
  // };

  const [idx, setIdx] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getLearn({ CourseId: id, Language: value }));
  }, [dispatch, value, id, courseSkills]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ marginLeft: 2.5 }}>
            <Typography variant="h4">
              <Button>
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginRight: "20px" }}
                />
              </Button>
              Courses / What will you learn
            </Typography>
          </Box>
          <Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "380px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              <Tab
                icon={<img src={russianIcon} alt="russianIcon" />}
                iconPosition="start"
                label="RUS"
                value={"RUS"}
              />
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  border: "1px solid rgb(163 163 155)",
                  marginTop: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></span>
              <Tab
                icon={<img src={tjkIcon} alt="tjkIcon" />}
                iconPosition="start"
                label="TAJ"
                value={"TAJ"}
              />
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  border: "1px solid rgb(163 163 155)",
                  marginTop: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></span>
              <Tab
                icon={<img src={engIcon} alt="engIcon" />}
                iconPosition="start"
                label="ENG"
                value="ENG"
              />
            </Tabs>
          </Box>
        </Grid>
        {learn?.length > 0 &&
          learn?.map((elem, i) => {
            return (
              <div
                key={elem.id}
                style={{
                  borderRadius: "20px",
                  position: "relative",
                }}
              >
                <IconButton
                  sx={{ position: "absolute", zIndex: 2, right: 10, top: 5 }}
                  onClick={async () => {
                    try {
                      await dispatch(deleteLearn(elem.id)).unwrap();
                      dispatch(
                        getLearn({
                          CourseId: id,
                          language: value ? value : "rus",
                        })
                      );
                    } catch (error) {}
                  }}
                >
                  <ClearIcon />
                </IconButton>
                <Accordion
                  onChange={() => {
                    setTitle(elem.title);
                    setIdx(elem.id);
                    setDescription(elem.description);
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <Typography
                          sx={{
                            marginRight: "13px",
                            fontWeight: 700,
                            fontSize: "20px",
                            lineHeight: "24px",
                          }}
                        >
                          Syllabus {i + 1}
                        </Typography>
                      </div>
                      <ExpandMoreIcon />
                    </div>
                  </AccordionSummary>

                  <AccordionDetails sx={{ rowGap: 4 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <div>
                          <Box>
                            <Typography>Title</Typography>
                            <Box sx={{ mt: 1 }}>
                              <TextField
                                fullWidth
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                InputProps={{
                                  inputProps: {
                                    style: { borderRadius: "16px" },
                                  },
                                  style: { borderRadius: "16px" },
                                }}
                                name="title"
                              />
                            </Box>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography>Description</Typography>
                            <Box sx={{ mt: 1 }}>
                              <TextField
                                fullWidth
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                InputProps={{
                                  inputProps: {
                                    style: { borderRadius: "16px" },
                                  },
                                  style: { borderRadius: "16px" },
                                }}
                                name="description"
                              />
                            </Box>
                          </Box>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent={"flex-end"}
                      >
                        <div>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "20px",
                            }}
                          >
                            Logo
                          </Typography>
                          <Box
                            sx={{
                              width: "250px",
                              height: "250px",
                              border: "2px dashed #C9DCEC",
                              borderRadius: "20px",
                              mt: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {cover[elem.id] ? (
                              <>
                                <img
                                  src={cover[elem.id]}
                                  alt="alt"
                                  style={{
                                    width: "100%",
                                    height: 245,
                                    borderRadius: 20,
                                  }}
                                />
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: 60,
                                    borderRadius: "50%",
                                    right: 20,
                                    color: "#fff",
                                    background: "rgba(0, 0, 0, 0.48)",
                                    "&:hover": {
                                      background: "rgba(0, 0, 0, 0.48)",
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove(elem.id);
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </>
                            ) : (
                              <label htmlFor={`Logo-${elem.id}`}>
                                <Box
                                  sx={{
                                    backgroundColor: "#F2F4F5",
                                    borderRadius: "100px",
                                    width: "250px",
                                    height: "64px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "100%",
                                      height: 245,
                                      borderRadius: 20,
                                    }}
                                    src={`${process.env.REACT_APP_ASSETS_URL}  ${elem.logo}`}
                                    alt="upload"
                                  />
                                </Box>
                                <input
                                  type="file"
                                  id={`Logo-${elem.id}`}
                                  onChange={(event) => {
                                    handleDropCover(
                                      elem.id,
                                      event.target.files[0]
                                    );
                                  }}
                                  style={{ display: "none" }}
                                />
                              </label>
                            )}
                          </Box>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        <Box
          sx={{
            width: "200px",
            height: "56px",
            borderRadius: "16px",
            border: "2px dashed #B1C4D7",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "40px",
            marginLeft: "40px",
          }}
        >
          <Button
            sx={{ width: "200px" }}
            onClick={() => {
              submit();
            }}
          >
            <AddIcon fontSize="small" /> Add new
          </Button>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              width: "200px",
              height: "48px",
              borderRadius: "16px",
              backgroundColor: "#506A85",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "40px",
              marginLeft: "40px",
            }}
          >
            <Button
              sx={{ width: "200px", color: "white" }}
              onClick={(e) => {
                e.preventDefault();

                let formData2 = new FormData();
                formData2.append("courseId", +id);
                formData2.append("language", value);
                formData2.append("courseSkillDtos[0].id", idx);
                formData2.append("courseSkillDtos[0].logo", file[idx]);
                formData2.append("courseSkillDtos[0].title", title);
                formData2.append("courseSkillDtos[0].description", description);

                dispatch(
                  postCourseSkills({
                    form: formData2,
                    obj: { CourseId: id, Language: value },
                  })
                );
              }}
            >
              Add
            </Button>
          </Box>
          <Box
            sx={{
              width: "200px",
              height: "48px",
              borderRadius: "16px",
              border: "2px solid #506A85",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "40px",
              marginLeft: "40px",
            }}
          >
            <Button sx={{ width: "200px" }}>Cancel</Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Learn;
