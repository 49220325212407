const durationTypeListEng = [
  {
    value: "1",
    label: "Days",
  },
  {
    value: "2",
    label: "Weeks",
  },
  {
    value: "3",
    label: "Months",
  },
  {
    value: "4",
    label: "Years",
  },
];

const durationTypeListTaj = [
  {
    value: "1",
    label: "Руз",
  },
  {
    value: "2",
    label: "Ҳафта",
  },
  {
    value: "3",
    label: "Моҳ",
  },
  {
    value: "4",
    label: "Сол",
  },
];

const durationTypeListRus = [
  {
    value: "1",
    label: "День",
  },
  {
    value: "2",
    label: "Неделя",
  },
  {
    value: "3",
    label: "Месяц",
  },
  {
    value: "4",
    label: "Год",
  },
];

export { durationTypeListEng, durationTypeListTaj, durationTypeListRus };
