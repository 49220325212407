import React from "react";
import { Box, Card, Container, Typography } from "@mui/material";
import LoginView from "../components/Login";

function Login(props) {
  return (
    <div>
      <Box
        component="main"c
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >     
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: "60px",
              md: "120px",
            },
          }}
        >
          <Card elevation={16} sx={{ p: 4 }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              /> */}
              <Typography variant="h4">Log in</Typography>
              <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
                Admin Panel SoftClub.tj
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <LoginView />
            </Box>
          </Card>
        </Container>
      </Box>
    </div>
  );
}

export default Login;
