import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
// import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import editIcon from "../../../icons/edit.svg";
import engIcon from "../../../icons/english.png";
import russianIcon from "../../../icons/rusian.png";
import tjkIcon from "../../../icons/tjk.png";
import { useDispatch } from "react-redux";
import { axiosRequest } from "../../../utils/axiosRequest";
import { id } from "date-fns/locale";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import { handleLanguagesChange } from "../../../reducers/courses/courses";
import {
  deleteTraining,
  getSyllabus,
  getTraining,
} from "../../../api/courses/courses";
import { borderRadius, flexbox } from "@mui/system";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import toast from "react-hot-toast";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function Training() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [arr, setArr] = useState([]);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [value, setValue] = React.useState("");
  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  const [idx, setIdx] = useState(null);
  const [open2, setOpen2] = useState(false);
  const training = useSelector(({ courses }) => courses.courses.training);
  console.log(training);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleRemove = (id) => {
    console.log(id);
    const arr1 = arr.filter((elem) => elem.id !== id);
    setArr(arr1);
  };

  const handleInputsChange = (id, name, value) => {
    const arr2 = arr.map((elem) => {
      if (elem.id === id) {
        elem[name] = value;
      }
      return elem;
    });

    setArr(arr2);
  };

  const submit = async () => {
    if (!value) return alert("Please select language");

    try {
      const { data } = await axiosRequest.put(
        "CourseTraining/AddOrUpdateGetCourseTraining",
        {
          courseId: +id,
          language: value?.toLowerCase(),
          trainingDtos: [
            {
              id: idx ? idx : 0,
              content: content,
            },
          ],
        }
      );
      if (data.code === 200) {
        dispatch(getTraining({ id: id, language: value }));
        setOpen(false);
        setOpen2(false);
        setContent("");
        setIdx(null);
        toast.success("Success");
      }
      // Do something with the response if needed
      console.log(data);
    } catch (e) {
      // Handle errors
      console.error(e);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }
  useEffect(() => {
    dispatch(getTraining({ id: id, language: value }));
  }, [dispatch, value, id]);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ marginLeft: 2.5 }}>
            <Typography variant="h4">
              <Button>
                {" "}
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginRight: "20px" }}
                />
              </Button>
              New course / Training
            </Typography>
          </Box>
          <Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "380px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              <Tab
                icon={<img src={russianIcon} alt="russianIcon" />}
                iconPosition="start"
                label="RUS"
                value={"RUS"}
              />
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  border: "1px solid rgb(163 163 155)",
                  marginTop: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></span>
              <Tab
                icon={<img src={tjkIcon} alt="tjkIcon" />}
                iconPosition="start"
                label="TAJ"
                value={"TAJ"}
              />
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  border: "1px solid rgb(163 163 155)",
                  marginTop: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></span>
              <Tab
                icon={<img src={engIcon} alt="engIcon" />}
                iconPosition="start"
                label="ENG"
                value="ENG"
              />
            </Tabs>
          </Box>
        </Grid>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            How is the training
          </Typography>
          <Box
            sx={{
              mt: "10px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "28px",
            }}
          >
            {training?.map((e, i) => {
              return (
                <Box
                  className="border "
                  sx={{
                    borderRadius: "16px",
                    background: "white",
                    width: "314px",
                    padding: "20px 24px",
                  }}
                >
                  <Box
                    sx={{
                      mb: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        handleClickOpen();
                        setContent(e.content);
                        setIdx(e.id);
                      }}
                    >
                      <Typography
                        sx={{
                          bgcolor: "rgba(30, 41, 59, 1)",
                          borderRadius: "100px",
                          display: "flex",
                          width: "40px",
                          height: "40px",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "rgba(56, 189, 248, 1)",
                          textAlign: "center",
                          fontWeight: "700",
                          lineHeight: "28px",
                        }}
                      >
                        {i + 1}
                      </Typography>
                    </IconButton>
                    <IconButton
                      onClick={async () => {
                        await dispatch(deleteTraining({ id: e?.id }));
                        dispatch(getTraining({ id: id, language: value }));
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    sx={{
                      margin: "5px",
                      bgcolor: "rgba(226, 231, 236, 1)",
                      padding: "12px 15px",
                      borderRadius: "15px",
                      textAlign: "inherit",
                      fontWeight: "400",
                    }}
                  >
                    {e?.content}
                  </Typography>
                </Box>
              );
            })}
            <Box
              onClick={handleClickOpen2}
              sx={{
                width: "157.335px",
                display: "flex",
                height: "196px",
                bgcolor: "white",
                borderRadius: "16px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "rgba(80, 106, 133, 1)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                }}
              >
                <AddIcon />
                Add
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      {/* Modal for add */}
      <BootstrapDialog
        onClose={handleClose2}
        aria-labelledby="customized-dialog-title"
        open={open2}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(226, 231, 236, 1)" }}
          id="customized-dialog-title"
        >
          Add
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose2}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ bgcolor: "rgba(226, 231, 236, 1)" }} dividers>
          <Box
            sx={{
              bgcolor: "white",
              width: "550px",
              p: "15px",
              borderRadius: "16px",
            }}
          >
            <TextField
              label="content"
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              sx={{ width: "100%" }}
              maxWidth
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "rgba(226, 231, 236, 1)" }}>
          <Button autoFocus onClick={submit}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(226, 231, 236, 1)" }}
          id="customized-dialog-title"
        >
          Edit
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ bgcolor: "rgba(226, 231, 236, 1)" }} dividers>
          <Box
            sx={{
              bgcolor: "white",
              width: "550px",
              p: "15px",
              borderRadius: "16px",
            }}
          >
            <TextField
              label="content"
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              sx={{ width: "100%" }}
              maxWidth
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "rgba(226, 231, 236, 1)" }}>
          <Button autoFocus onClick={submit}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}

export default Training;
