import { createSlice } from "@reduxjs/toolkit";
import { GetAllStudents } from "../../api/students/students";

export const slice = createSlice({
  name: "students",
  initialState: {
    students: [],
    tutors: [],
    loading: false,
    totalPages: 0,
  },
  reducers: {},
  extraReducers: {
    [GetAllStudents.pending]: (state) => {
      state.loading = true;
    },
    [GetAllStudents.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalPages = action.payload.totalPages;
      if (action.payload.data) {
        state.students = action.payload.data;
      }
    },
    [GetAllStudents.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {} = slice.actions;

export default slice.reducer;
