import { combineReducers } from "@reduxjs/toolkit";
import coursesReducer from "./courses";
import groupReducer from "./group";

const courses = combineReducers({
  courses: coursesReducer,
  group: groupReducer,
});

export default courses;
