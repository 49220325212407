import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiAssetsUrl } from "../../../config/config";
import { useMounted } from "../../../hooks/use-mounted";
import editIcon from "../../../icons/edit.svg";
import uploadIcon from "../../../icons/upload.png";
import { handleLanguagesChange } from "../../../reducers/courses/courses";
import { axiosRequest } from "../../../utils/axiosRequest";
import { FormInputText } from "../../../components/custom/form-components/FormInputText";
import { FormInputRadio } from "../../../components/custom/form-components/FormInputRadio";
import { getALLCourses, getCourseById } from "../../../api/courses/courses";
import { Close } from "@mui/icons-material";
import { fileToBase64 } from "../../../utils/file-to-base64";
import {
  durationTypeList,
  durationTypeListEng,
  durationTypeListRus,
  durationTypeListTaj,
} from "./enums";
import toast from "react-hot-toast";
import { SketchPicker } from "react-color";

import { FormInputDate } from "../../../components/custom/form-components/FormInputDate";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

function Edit() {
  const course = useSelector(({ courses }) => courses?.courses?.course);
  const isLoading = useSelector(({ courses }) => courses.courses.loading);
  console.log(course);
  const dataLanguages = useSelector(
    ({ courses }) => courses?.courses?.dataLanguages
  );
  console.log(dataLanguages);
  const [groups, setGroups] = useState([]);
  const isMounted = useMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorFile, setErrorFile] = useState(false);
  const [isCard, setIsCard] = useState(true);
  const { id } = useParams();
  const [month, setMonth] = useState("none");
  const [open, setOpen] = useState(false);
  const [cover, setCover] = useState(`${apiAssetsUrl}${course?.logo}`);
  const [fieldValue, setFieldValue] = useState("");
  const [discount, setDiscount] = useState(course?.enabled);

  const [value, setValue] = React.useState(
    dataLanguages?.filter((elem) => elem?.completed)[0]?.name
  );
  const defaultValues = {
    logo: course?.logo,
    language: value,
    title: course?.title,
    subTitle: course?.subTitle,
    description: course?.description,
    teachingFormat: course?.teachingFormat == "Online" ? 2 : 1,
    duration: course?.duration,
    durationType: course?.durationType,
    neededStudents: course?.neededStudents,
    startDate: new Date(course?.startDate) || new Date(),
    price: course?.price,
    enabled: false,
  };

  console.log(defaultValues);

  console.log("Course Description:", course?.description);

  const onSubmit = async (values) => {
    console.log(values);

    const formattedValues = {
      Id: id,
      Title: values.title,
      SubTitle: values.subTitle,
      Language: value?.toLowerCase(),
      Description: fieldValue,
      TeachingFormat: values.teachingFormat,
      Logo: values.Logo,
      Duration: values.duration,
      DurationType: values.durationType,
      StartDate: dayjs(values.startDate).format("MM/DD/YYYY"),
      NeededStudents: values.neededStudents,
      DescriptionPrice: values.descriptionPrice,
      Price: values.price,
      Enabled: values.enabled,
      Color: values.color,
      // ... other fields as needed
    };
    const formData = new FormData();
    for (let key in formattedValues) {
      formData.append(key, formattedValues[key]);
    }

    try {
      if (!value) {
        toast("Select language", {
          icon: "🙏",
        });
      } else {
        const { data } = await axiosRequest.put(
          `Course/UpdateCourse`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (data?.code === 200) {
          toast.success("successfuly updated");
        }
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const { pathname } = useLocation();

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // function changeDescValue(value) {
  //   setFieldValue(value);
  //   setFormValue("description", fieldValue);
  // }
  // const handleColor = (e) => {
  //   setFormValue("color", e.target.value);
  // };
  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };
  const handleSwitchChange = (event) => {
    setDiscount(event.target.checked);
    setFormValue("enabled", event.target.checked);
  };
  const getGroups = useCallback(async () => {
    try {
      const { data } = await axiosRequest.get(`Group`);

      if (isMounted()) {
        setGroups(data?.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const handleChange = (event, newValue) => {
    setFormValue("Language", newValue);
    setValue((prev) => {
      prev = newValue;
      return prev;
    });
    dispatch(
      getCourseById({
        CourseId: id,
        Language: newValue,
      })
    );
  };

  useEffect(
    () => {
      // dispatch(
      //   getALLCourses({
      //     Language: value,

      //     PageSize: 500,
      //   })
      // );

      dispatch(
        getCourseById({
          CourseId: id,
          Language: value,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, id]
  );
  const methods = useForm({
    defaultValues: {
      logo: course?.logo,
      language: value,
      title: course?.title,
      subTitle: course?.subTitle,
      description: course?.description,
      teachingFormat: course?.teachingFormat,
      duration: course?.duration,
      durationType: course?.durationType,
      neededStudents: course?.neededStudents,
      descriptionPrice: course?.descriptionPrice,
      startDate: new Date(course?.startDate) || new Date(),
      color: course?.color,
      price: course?.price,
      enabled: course?.enabled,
    },
  });
  useEffect(() => {
    methods.reset({
      logo: course?.logo,
      language: value,
      title: course?.title,
      subTitle: course?.subTitle,
      description: course?.description,
      teachingFormat: course?.teachingFormat,
      duration: course?.duration,
      durationType: course?.durationType,
      neededStudents: course?.neededStudents,
      descriptionPrice: course?.descriptionPrice,
      startDate: new Date(course?.startDate) || new Date(),
      color: course?.color,
      price: course?.price,
      enabled: course?.enabled,
    });
    setFieldValue(course.description);
    setCover(`${apiAssetsUrl}${course?.logo}`);
    setDiscount(course.enabled);
  }, [course, value]);
  const {
    handleSubmit,
    reset,
    register,
    watch,
    control,
    setValue: setFormValue,
  } = methods;

  console.log(isLoading);

  const handleDropCover = async (name, file) => {
    const data = await fileToBase64(file);
    setCover(data);
    setFormValue(name, file);
    return;
  };

  const handleRemove = (name) => {
    setCover(null);
    setFormValue(name, "");
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ marginLeft: 1 }}>
            <Typography variant="h4">
              <Button>
                {" "}
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginRight: "5px" }}
                />
              </Button>
              Edit Course / Main
            </Typography>
          </Box>
          <Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "435px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value ? value : "rus"}
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              {dataLanguages
                .filter((elem) => elem.completed)
                .map((e, index) => {
                  return (
                    <Tab
                      key={`${e.id}-${index}`}
                      icon={<img src={e.avatar} alt="russianIcon" />}
                      iconPosition="start"
                      value={e.name}
                      label={e.name}
                    >
                      <span
                        style={{
                          width: "1px",
                          height: "24px",
                          border: "1px solid rgb(163 163 155)",
                          marginTop: "12px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      ></span>
                    </Tab>
                  );
                })}
              <Tab
                onClick={handleClickOpen}
                icon={<img src={editIcon} alt="engIcon" />}
                style={{
                  height: "41px",
                  width: "41px",
                  background: "#E6E6E6",
                  lineHeight: "24px",
                  marginLeft: "15px",
                }}
                iconPosition="start"
              >
                {" "}
              </Tab>
            </Tabs>
          </Box>
        </Grid>

        {/* Edit Course */}
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Card sx={{ mt: 4, position: "relative" }}>
                    <CardContent
                      sx={{
                        width: {
                          xs: "full",
                          sm: "500",
                          md: "550",
                          lg: "600px",
                        },
                      }}
                    >
                      <Typography variant="h6">Basic details</Typography>
                      <Box sx={{ mt: 3 }}>
                        <label style={{ marginBottom: "10px" }} for="Title">
                          Title
                        </label>

                        <FormInputText name="title" control={control} />
                        <Box sx={{ mt: 2 }}>
                          <label for="Subtitle">Subtitle</label>
                          <FormInputText
                            multiline={true}
                            name="subTitle"
                            control={control}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "500px",
                            paddingTop: "8px",
                            gap: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Teaching format
                          </Typography>
                          <Box>
                            <FormInputRadio
                              control={control}
                              edit={course?.teachingFormat == "Online" ? 2 : 1}
                              options={[
                                {
                                  value: "1",
                                  label: value === "ENG" ? "Offline" : "Офлайн",
                                },
                                {
                                  value: "2",
                                  label: value === "ENG" ? "Online" : "Онлайн",
                                },
                              ]}
                              name="teachingFormat"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              mt: 2,
                              height: "88px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Price
                            </Typography>
                            <FormInputText
                              type="number"
                              name="price"
                              control={control}
                            />
                          </Box>

                          <Box
                            sx={{
                              mt: 2,
                              width: "150px",
                              height: "88px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Duration
                            </Typography>
                            <FormInputText name="duration" control={control} />
                          </Box>

                          <Box
                            sx={{
                              mt: 2,
                              width: "150px",
                              borderRadius: "16px",
                              height: "88px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Duration type
                            </Typography>
                            <Box sx={{ minWidth: 150 }}>
                              <FormInputText
                                name="durationType"
                                control={control}
                                select={true}
                                options={
                                  value === "ENG"
                                    ? durationTypeListEng
                                    : value === "TAJ"
                                    ? durationTypeListTaj
                                    : durationTypeListRus
                                }
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Start date
                            </Typography>
                            <FormInputDate name="startDate" control={control} />
                          </Box>
                          <Box>
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                Needed Students
                              </Typography>
                              <FormInputText
                                type="number"
                                name="neededStudents"
                                control={control}
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          style={{
                            position: "absolute",
                            right: 50,
                            top: 10,
                            width: "250px",
                          }}
                        >
                          <label for="Logo">
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "20px",
                              }}
                            >
                              Logo
                            </Typography>
                            <Box
                              sx={{
                                width: "250px",
                                height: "250px",
                                border: "2px dashed #C9DCEC",
                                borderRadius: "20px",
                                mt: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {cover ? (
                                <>
                                  <img
                                    src={cover}
                                    alt="alt"
                                    style={{
                                      width: "100%",
                                      height: 245,
                                      borderRadius: 20,
                                    }}
                                  />
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      top: 60,
                                      borderRadius: "50%",
                                      right: 20,
                                      color: "#fff",
                                      background: "rgba(0, 0, 0, 0.48)",
                                      "&:hover": {
                                        background: "rgba(0, 0, 0, 0.48)",
                                      },
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemove("Logo");
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </>
                              ) : (
                                <Box
                                  sx={{
                                    backgroundColor: "#F2F4F5",
                                    borderRadius: "100px",
                                    width: "64px",
                                    height: "64px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src={uploadIcon} alt="upload" />
                                </Box>
                              )}
                            </Box>
                            <input
                              type="file"
                              id="Logo"
                              {...register("Logo")}
                              onChange={(event) => {
                                handleDropCover("Logo", event.target.files[0]);
                              }}
                              style={{ display: "none" }}
                            />
                          </label>
                          <Box
                            sx={{
                              mt: "20px",

                              fontWeight: 700,
                              fontSize: "20px",
                              mb: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "20px",
                                mb: 1,
                              }}
                            >
                              Color
                            </Typography>
                            <FormInputText
                              type="color"
                              sx={{ width: "100%" }}
                              name="color"
                              control={control}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              mt: 2,
                              height: "48px",
                              display: "flex",
                              width: "50%",
                              gap: "10px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderTop: "1px solid gray",
                              borderBottom: "1px solid gray",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Discount
                            </Typography>
                            <Switch
                              name="enabled"
                              checked={discount}
                              onChange={handleSwitchChange}
                            />
                          </Box>

                          <Box
                            sx={{
                              mt: 2,
                              height: "88px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Price
                            </Typography>
                            <FormInputText
                              disabled={!discount}
                              type="number"
                              name="descriptionPrice"
                              control={control}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>

                    <Box sx={{ p: 3 }}>
                      <Typography>Description</Typography>
                      <Box
                        sx={{
                          border: "1px solid #E2E7EC",
                          height: "283px",
                          borderRadius: "16px",
                        }}
                      >
                        <ReactQuill
                          value={fieldValue}
                          name="description"
                          onChange={(value) => {
                            console.log("Quill Value:", value);
                            setFieldValue(value);
                          }}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: [] }],
                              [{ size: [] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],

                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "image", "video"],
                              ["clean"],
                            ],
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "link",
                            "image",
                            "video",
                          ]}
                          theme="snow"
                          readOnly={false}
                          placeholder="Type something..."
                          className="custom-quill-editor"
                          style={{ height: "200px", borderRadius: "16px" }}
                          bounds=".scrollable-container"
                          scrollingContainer=".scrollable-container"
                        />
                      </Box>
                    </Box>
                  </Card>
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Button type="submit" variant="contained" value="submit">
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      sx={{ border: "2px solid #B1C4D7", borderRadius: "16px" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>

      {/* Modal for Languages */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                {dataLanguages.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          checked={value.completed}
                          onClick={() => handleToggle(value.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt={`Avatar`} src={value.avatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={` ${value.language}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button onClick={handleClose} sx={{ m: 1 }} variant="contained">
              Edit
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Edit;
