import parse from "html-react-parser";
import PropTypes from "prop-types";
import edjsParser from "./editorjs-html-extended";
import "./editor.css";

export default function EditorTextParser({ data }) {
  if (typeof data === "string") {
    data = JSON.parse(data);
  }
  console.log(data);

  if (!data || !data.blocks || !Array.isArray(data.blocks)) {
    return <div className="text-container">Нет данных для отображения</div>;
  }

  try {
    const html = edjsParser.parse(data);
    return <div className="text-container">{parse(html.join(""))}</div>;
  } catch (error) {
    console.error("Ошибка при парсинге данных:", error);
    return <div className="text-container">Ошибка при отображении данных</div>;
  }
}

EditorTextParser.propTypes = {
  data: PropTypes.object.isRequired,
};
