import { createSlice } from "@reduxjs/toolkit";
import { GetAllUITranslations } from "../../api/Translate/translate";

export const slice = createSlice({
  name: "translate",
  initialState: {
    translate: [],
    loading: false,
    totalPages: 0,
  },
  reducers: {},
  extraReducers: {
    [GetAllUITranslations.pending]: (state) => {
      state.loading = true;
    },
    [GetAllUITranslations.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalPages = action.payload.totalPages;

      if (action.payload.data) {
        state.translate = action.payload.data;
      }
    },
    [GetAllUITranslations.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {} = slice.actions;

export default slice.reducer;
