import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  TablePagination,
  Typography,
  Button,
  CardMedia,
  Box,
  Divider,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PencilAlt as PencilAltIcon } from "../../../icons/pencil-alt";
import { apiAssetsUrl } from "../../../config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomDropDown from "./CustomDropDown";

function CourseListCard(props) {
  const {
    courses,
    coursesCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
  } = props;

  const navigate = useNavigate();

  return (
    <div>
      <Grid container spacing={2}>
        {courses?.map((course) => {
          return (
            <Grid item md={4} lg={4} sm={12} xs={12}>
              <Card
                sx={{
                  padding: 0,
                  width: "300px",
                  height: "300px",
                  borderRadius: "20px",
                }}
              >
                <Box sx={{ padding: 0 }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                  >
                    <CustomDropDown />
                  </Typography>

                  <CardMedia
                    component="img"
                    sx={{
                      width: 90,
                      height: 90,
                      borderRadius: 100,
                      margin: "auto",
                    }}
                    image={`${process.env.REACT_APP_ASSETS_URL}${course.logo}`}
                    alt="Live from space album cover"
                  />
                  <CardContent sx={{ padding: 0 }}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSixe: "24px",
                        lineHeight: "36px",
                        textAlign: "center",
                        letterSpacing: "0.5px",
                        paddingTop: "10px",
                      }}
                    >
                      {course?.title}
                    </Typography>
                    <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />
                    <Box
                      sx={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "24px",
                            lineHeight: "32px",
                          }}
                        >
                          {course.duration}
                        </Typography>
                        <Typography> {course?.durationType}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "24px",
                            lineHeight: "32px",
                          }}
                        >
                          {course.price}
                        </Typography>
                        <Typography>coм / мес</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {/* 
      <TablePagination
        component="div"
        count={coursesCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      /> */}
    </div>
  );
}

export default CourseListCard;
