import { useState } from "react";
import Editor from "./editor/Editor";
import EditorTextParser from "./editor-parser/editorTextParser";

function MyEditorJs({ fieldValue, setFieldValue }) {
  const [isEditMode, setIsEditMode] = useState(false);
  function toggleEditMode(e) {
    
    if (isEditMode) {
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  }

  return (
    <div className="bg-white p-10">
      <button id="toggle-edit-btn" type="button" onClick={toggleEditMode}>
        {isEditMode ? "Save" : "Edit"}
      </button>

      <div className="app-content bg-slate-50">
        {isEditMode ? (
          <Editor data={fieldValue} setData={setFieldValue} />
        ) : (
          <EditorTextParser data={fieldValue} />
        )}
      </div>
    </div>
  );
}

export default MyEditorJs;
