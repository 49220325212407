import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  TablePagination,
  Typography,
  Button,
  CardMedia,
  Box,
  Divider,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PencilAlt as PencilAltIcon } from "../../../icons/pencil-alt";
import { apiAssetsUrl } from "../../../config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pagination from "@mui/material/Pagination";
let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function PostListCard(props) {
  const {
    posts,
    postsCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
  } = props;

  const navigate = useNavigate();
  console.log(posts);
  return (
    <div>
      <Grid container spacing={2}>
        {posts?.map((post, i) => {
          return (
            <Grid key={i} item md={4} lg={4} sm={12} xs={12}>
              <Card
                sx={{
                  padding: 1,
                  width: "333px",
                  height: "420px",
                  borderRadius: "16px",
                }}
              >
                <Box sx={{ padding: 0 }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                  ></Typography>

                  <CardMedia
                    component="img"
                    sx={{
                      width: 310,
                      height: 200,
                      borderRadius: "10px",
                      margin: "auto",
                      objectFit: "fill",
                    }}
                    image={`${process.env.REACT_APP_ASSETS_URL}${post.file}`}
                    // alt="Live from space album cover"
                  />

                  <CardContent sx={{ padding: 1 }}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSixe: "18px",
                        lineHeight: "24px",
                        letterSpacing: "0.5px",
                        paddingTop: "10px",
                        height: "50px",
                      }}
                    >
                      {post.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSixe: "14px",
                        paddingTop: "35px",
                        lineHeight: "20px",
                      }}
                    >
                      {post?.subTitle?.slice(0, 100)}...
                    </Typography>
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        bottom: "20px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: 4,
                        // paddingBottom: 3,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{ fontWeight: 400, fontSize: "14px" }}
                        >{`${new Date(post.publishDate).getDate()} ${
                          months[new Date(post.publishDate).getMonth()]
                        } ${new Date(
                          post.publishDate
                        ).getFullYear()}`}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          color: "#39A0CC",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "32px",
                            paddingRight: 1,
                          }}
                        >
                          More
                        </Typography>
                        <ArrowForwardIosIcon
                          sx={{ fontSize: "15px", marginTop: "10px" }}
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <TablePagination
        component="div"
        count={postsCount}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          onRowsPerPageChange(event.target.value);
        }}
      />
    </div>
  );
}

export default PostListCard;
