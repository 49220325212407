import { Children, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from '@material-ui/icons/Menu';
// import { makeStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@material-ui/styles';

import CloseIcon from "@mui/icons-material/Close";
// import { useRouter } from 'next/router';
import { Button } from "@mui/material";

// const useStyles = makeStyles({
//     list: {
//         width: 250,
//     },
// });

export default function TemporaryDrawer({
  child1,
  children,
  header,
  head,
  width,
  height,
  backgroundColor,
  open,
  handleOpen,
  handleClose,
  paddingBottom,
  marginTop,
}) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
    // console.log(open)
  }, [open]);
  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div style={{ paddingBottom: paddingBottom }}>
      {/* <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleOpen}
            >
                <Button style={{ position: "absolute", color: "transparent", width: width, height: height, borderRadius: "100%", backgroundColor: backgroundColor }}>btn</Button>
            </IconButton> */}
      <SwipeableDrawer
        anchor="right"
        // container={container}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          handleClose();
        }}
        onOpen={() => {}}
      >
        <Box sx={{ width: { xs: "100vw", sm: 520 } }}>
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-between",
              height: height,
              padding: { xs: "0 10px", sm: "0 32px" },
            }}
          >
            <Button
              sx={{
                padding: "0",
                minWidth: "24px",
                maxHeight: "24px",
                margin: "auto 0",
              }}
              onClick={() => {
                setIsOpen(false);
                handleClose();
              }}
            >
              <CloseIcon />
            </Button>
            <h3 style={{ margin: "auto 0" }}>{header}</h3>
          </Box>
          {head}

          <List
            sx={{
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            >
            {child1}
          </List>
          <Box sx={{ padding: { xs: "0 10px", sm: "0 32px" } }}>{children}</Box>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
