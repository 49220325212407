import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getPostById, updatePost } from "../../../api/posts/posts";
import { FormInputDate } from "../../../components/custom/form-components/FormInputDate";
import { FormInputRadio } from "../../../components/custom/form-components/FormInputRadio";
import { FormInputText } from "../../../components/custom/form-components/FormInputText";
import editIcon from "../../../icons/edit.svg";
import uploadIcon from "../../../icons/upload.png";
import { handleLanguagesChange } from "../../../reducers/courses/courses";
import { fileToBase64 } from "../../../utils/file-to-base64";
import MyEditorJs from "./components/MyEditor";

function Edit() {
  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );

  const [cover, setCover] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(
    dataLanguages.filter((elem) => elem.completed)[0]?.name
  );
  const [open, setOpen] = useState(false);
  const post = useSelector(({ posts }) => posts.posts.postById);

  const parsedDescription = post?.description
    ? JSON.parse(post?.description)
    : {};


  const defaultValues = {
    Title: post?.title,
    SubTitle: post?.subTitle,
    Language: value,
    File: post?.file,
    PublishDate: new Date(post?.publishDate) || new Date(),
    PostStatus: post?.postStatus === "Hidden" ? 2 : 1,
    Description: parsedDescription,
  };
  const [fieldValue, setFieldValue] = useState(parsedDescription);
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue: setFormValue,
  } = methods;
  const { id } = useParams();
  const titleValue = watch("Title");
  const Subtitle = watch("SubTitle");
  let PublishDate = watch("PublishDate");
  let Date2 = dayjs(PublishDate).format("MM/DD/YYYY");
  const onSubmit = async (values) => {
    console.log(values);

    if (!value) {
      toast.error("Select Language");
    } else {
      if (!values.Language) {
        values.Language = value;
      }

      values.PublishDate = dayjs(values.PublishDate).format("MM/DD/YYYY");

      // Ensure Description is a string
      if (typeof values.Description !== "string") {
        values.Description = JSON.stringify(values.Description);
      }

      const formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }

      formData.append("Id", id);
      dispatch(
        updatePost({
          formData,
          callback: () => {
            navigate(-1);
          },
        })
      );
    }
  };

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setFormValue("Language", newValue);
    setValue(newValue);
    dispatch(getPostById({ PostId: id, Language: newValue }));
  };
  const handleDropCover = async (name, file) => {
    const data = await fileToBase64(file);
    setCover(data);
    setFormValue(name, file);
    return;
  };

  const handleRemove = (name) => {
    setCover(null);
    setFormValue(name, "");
  };

  useEffect(() => {
    dispatch(getPostById({ PostId: id, Language: value ? value : "rus" }));
  }, [dispatch, id, value]);
  useEffect(() => {
    setFieldValue(post?.description);
    methods.reset({
      Title: post?.title,
      SubTitle: post?.subTitle,
      Language: value,
      File: post?.file,
      PublishDate: new Date(post?.publishDate) || new Date(),
      PostStatus: post?.postStatus === "Hidden" ? 2 : 1,
      Description: post?.description,
    });
  }, [methods, post, value]);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ marginLeft: 1 }}>
            <Typography variant="h4">
              <Button>
                {" "}
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginRight: "5px" }}
                />
              </Button>
              Update post
            </Typography>
          </Box>
          <Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "435px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              {dataLanguages
                .filter((elem) => elem.completed)
                .map((e) => {
                  return (
                    <Tab
                      key={e.id}
                      icon={<img src={e.avatar} alt="russianIcon" />}
                      iconPosition="start"
                      value={e.name}
                      label={e.name}
                    >
                      <span
                        style={{
                          width: "1px",
                          height: "24px",
                          border: "1px solid rgb(163 163 155)",
                          marginTop: "12px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      ></span>
                    </Tab>
                  );
                })}
              <Tab
                onClick={handleClickOpen}
                icon={<img src={editIcon} alt="engIcon" />}
                style={{
                  height: "41px",
                  width: "41px",
                  background: "#E6E6E6",
                  lineHeight: "24px",
                  marginLeft: "15px",
                }}
                iconPosition="start"
              >
                {" "}
              </Tab>
            </Tabs>
          </Box>
        </Grid>

        {/* Add Course */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Card sx={{ mt: 4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "12px",
                      alignItems: "flex-start",
                    }}
                  >
                    <CardContent
                      sx={{
                        width: {
                          xs: "full",
                          sm: "500",
                          md: "550",
                          lg: "600px",
                        },
                      }}
                    >
                      <Box sx={{ mt: 3 }}>
                        <label style={{ marginBottom: "10px" }} for="Title">
                          Title
                        </label>
                        <FormInputText
                          name="Title"
                          control={control}
                          // ref={register}
                        />

                        <Box sx={{ mt: 2 }}>
                          <label for="Subtitle">Subtitle</label>
                          <FormInputText name="SubTitle" control={control} />
                        </Box>

                        <Box sx={{ mt: 3 }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Publish date
                          </Typography>
                          <FormInputDate name="PublishDate" control={control} />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "500px",
                            paddingTop: "8px",
                            gap: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Status
                          </Typography>
                          <Box>
                            <FormInputRadio
                              control={control}
                              options={[
                                { value: "1", label: "Active" },
                                { value: "2", label: "Hidden" },
                              ]}
                              name="PostStatus"
                            />
                          </Box>
                        </Box>

                        {/* Upload start*/}
                        <Box>
                          <label for="Logo">
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "20px",
                              }}
                            >
                              Logo
                            </Typography>
                            <Box
                              sx={{
                                width: "550px",
                                height: "220px",
                                border: "2px dashed #C9DCEC",
                                borderRadius: "20px",
                                mt: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                              }}
                            >
                              {cover ? (
                                <>
                                  <img
                                    src={cover}
                                    alt="alt"
                                    style={{
                                      width: "100%",
                                      height: 220,
                                      borderRadius: 20,
                                    }}
                                  />
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      top: 2,
                                      right: 2,
                                      borderRadius: "50%",

                                      color: "#fff",
                                      background: "rgba(0, 0, 0, 0.48)",
                                      "&:hover": {
                                        background: "rgba(0, 0, 0, 0.48)",
                                      },
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemove("Logo");
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </>
                              ) : (
                                <Box
                                  sx={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "#F2F4F5",
                                      borderRadius: "100px",
                                      marginX: "auto",
                                      width: "64px",
                                      height: "64px",
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img src={uploadIcon} alt="upload" />
                                  </Box>
                                  <Typography
                                    fontSize={"20px"}
                                    fontWeight={600}
                                  >
                                    Upload
                                  </Typography>
                                  <Typography>
                                    Click or drag file to this area to upload
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <input
                              type="file"
                              id="Logo"
                              {...register("File")}
                              onChange={(event) => {
                                handleDropCover("File", event.target.files[0]);
                              }}
                              style={{ display: "none" }}
                            />
                          </label>
                        </Box>
                        {/* Upload end */}
                      </Box>
                    </CardContent>
                    {/* Card */}
                    <Box>
                      <Typography
                        sx={{
                          marginLeft: "70px",
                          marginTop: "40px",
                          fontWeight: 600,
                          fontSize: "20px",
                        }}
                      >
                        Preview
                      </Typography>
                      <Card
                        sx={{
                          width: "333px",
                          height: "350px",
                          borderRadius: "16px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          padding: "8px",
                          marginTop: "10px",
                          marginLeft: "70px",
                          gap: "4px",
                          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#F3F4F6",
                            width: 317,
                            height: 200,
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "10px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            sx={{
                              width: 120,
                              height: 120,
                              margin: "auto",
                              objectFit: "cover",
                              padding: "10px",
                            }}
                            image={
                              post?.file && !cover
                                ? `${process.env.REACT_APP_ASSETS_URL}${post?.file}`
                                : cover
                            }
                            alt="Live from space album cover"
                          />
                        </Box>
                        <CardContent sx={{ padding: 1 }}>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSixe: "24px",
                                lineHeight: "36px",
                                letterSpacing: "0.5px",
                                paddingTop: "10px",
                              }}
                            >
                              {titleValue ? titleValue : "Title"}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSixe: "20px",
                                paddingTop: "6px",
                              }}
                            >
                              {Subtitle ? Subtitle : "SubTitle"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              width: "300px",
                              justifyContent: "space-between",
                              pt: "5px",
                              flexDirection: "row",
                              color: "#39A0CC",
                              paddingRight: "15px",
                              cursor: "pointer",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "32px",
                                paddingRight: "5px",
                              }}
                            >
                              {Date2 ? Date2 : "Published date"}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  lineHeight: "32px",
                                  paddingRight: "5px",
                                }}
                              >
                                More
                              </Typography>
                              <ArrowForwardIosIcon
                                sx={{
                                  fontSize: "15px",
                                  marginTop: "8.5px",
                                }}
                              />
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                    {/*  */}
                  </Box>

                  <Box sx={{ p: 3 }}>
                    <Typography>Description</Typography>
                    <Box
                      sx={{
                        height: "283px",
                        borderRadius: "16px",
                      }}
                    ></Box>
                    <MyEditorJs
                      fieldValue={fieldValue}
                      setFieldValue={(value) => {
                        setFieldValue(value);
                        setFormValue("Description", JSON.stringify(value));
                      }}
                    />
                  </Box>
                </Card>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Button type="submit" variant="contained" value="submit">
                    Add
                  </Button>
                  <Button
                    sx={{ border: "2px solid #B1C4D7", borderRadius: "16px" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* Modal for Languages */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                {dataLanguages.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          checked={value.completed}
                          onClick={() => handleToggle(value.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt={`Avatar`} src={value.avatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={` ${value.language}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button onClick={handleClose} sx={{ m: 1 }} variant="contained">
              Edit
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Edit;
