import { createSlice } from "@reduxjs/toolkit";
import {
  getALLCourses,
  getCourseById,
  getSyllabus,
  getAllTutors,
  getSallaries,
  getReviewers,
  getAllCourseSkills,
  deleteCourseSkills,
  deleteCourseById,
  getTraining,
  getLearn,
  getTutor,
  getReviewerById,
} from "../../api/courses/courses";
import russianIcon from "../../icons/rusian.png";
import tjkIcon from "../../icons/tjk.png";
import engIcon from "../../icons/english.png";

let dataLanguages1 = [
  {
    id: 1,
    language: "Русский (RUS)",
    name: "RUS",
    avatar: russianIcon,
    completed: false,
  },
  {
    id: 2,
    language: "Таджикский (TAJ)",
    name: "TAJ",
    avatar: tjkIcon,
    completed: false,
  },
  {
    id: 3,
    language: "Английский (ENG)",
    name: "ENG",
    avatar: engIcon,
    completed: false,
  },
];

export const slice = createSlice({
  name: "courses",
  initialState: {
    courses: [],
    course: {},
    syllabus: [],
    training: [],
    sallaries: [],
    reviewers: [],
    editReviewer: null,
    courseSkills: [],
    editTutors: [],
    tutors: [],
    courseList: [],
    tutor: [],
    totalPages: 0,
    dataLanguages: dataLanguages1,
    loading: false,
    learn: [],
    reviewerById: [],
  },
  reducers: {
    setEditReviewer(state, action) {
      state.editReviewer = action.payload;
      console.log(action.payload);
    },
    setSyllabus(state, action) {
      state.syllabus = action.payload;
    },
    setEditTutors(state, action) {
      state.editTutors = action.payload;
      console.log(action.payload);
    },
    handleLanguagesChange(state, action) {
      state.dataLanguages = state.dataLanguages.map((e) => {
        if (e.id == action.payload) {
          e.completed = !e.completed;
        }
        return e;
      });
    },
    reset(state) {
      state.dataLanguages = [...dataLanguages1];
    },
  },
  extraReducers: {
    [getALLCourses.pending]: (state) => {
      state.loading = true;
    },
    [getALLCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalPages = action.payload.totalPages;
      // let arr = [];
      action.payload.data.forEach((elem) => {
        state.courseList.push({ label: elem.title, value: String(elem.id) });
      });
      // state.courseList = arr;
      if (action.payload.data) {
        state.courses = action.payload.data;
      }
    },
    [getALLCourses.rejected]: (state) => {
      state.loading = false;
    },
    [getCourseById.pending]: (state) => {
      state.loading = true;
    },
    [getCourseById.fulfilled]: (state, action) => {
      state.course = { ...action.payload };
      state.loading = false;
    },
    [getCourseById.rejected]: (state) => {
      state.loading = false;
    },

    [getSyllabus.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCourseById.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCourseById.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteCourseById.rejected]: (state, action) => {
      state.loading = false;
    },
    [getSyllabus.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.syllabus = action.payload;
      state.loading = false;
    },
    [getSyllabus.rejected]: (state, action) => {
      state.loading = false;
    },
    [getTraining.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.training = action.payload;
      state.loading = false;
    },
    [getTraining.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllTutors.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllTutors.fulfilled]: (state, action) => {
      state.tutors = action.payload;
      state.loading = false;
    },
    [getAllTutors.rejected]: (state, action) => {
      state.loading = false;
    },
    [getTutor.pending]: (state, action) => {
      state.loading = true;
    },
    [getTutor.fulfilled]: (state, action) => {
      state.tutor = action.payload;
      state.loading = false;
    },
    [getTutor.rejected]: (state, action) => {
      state.loading = false;
    },
    [getSallaries.pending]: (state, action) => {
      state.loading = true;
    },
    [getSallaries.fulfilled]: (state, action) => {
      state.sallaries = action.payload;
      state.loading = false;
    },
    [getSallaries.rejected]: (state, action) => {
      state.loading = false;
    },
    [getReviewers.pending]: (state, action) => {
      state.loading = true;
    },
    [getReviewers.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewers = action.payload;
    },
    [getReviewers.rejected]: (state, action) => {
      state.loading = false;
    },
    [getLearn.pending]: (state, action) => {
      state.loading = true;
    },
    [getLearn.fulfilled]: (state, action) => {
      state.loading = false;
      state.learn = action.payload;
    },
    [getLearn.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllCourseSkills.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllCourseSkills.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload);
      state.courseSkills = action.payload;
    },
    [getAllCourseSkills.rejected]: (state, action) => {
      state.loading = false;
    },
    [getReviewerById.pending]: (state, action) => {
      state.loading = true;
    },
    [getReviewerById.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload);
      state.reviewerById = action.payload;
    },
    [getReviewerById.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteCourseSkills.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCourseSkills.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteCourseSkills.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  handleLanguagesChange,
  reset,
  setEditTutors,
  setEditReviewer,
  setSyllabus,
} = slice.actions;

export default slice.reducer;
