import EditorJSHTML from "editorjs-html";

const customParsers = {
  table: (block) => {
    const { content } = block.data;

    let tableHtml = "<table>";

    content.forEach((row) => {
      tableHtml += "<tr>";
      row.forEach((cell) => {
        tableHtml += `<td>${cell}</td>`;
      });
      tableHtml += "</tr>";
    });

    tableHtml += "</table>";
    return tableHtml;
  },
  linkTool: (block) => {
    const { link, meta } = block.data;
    return `<a href="${link}" target="_blank" rel="noopener noreferrer">${
      meta.title || link
    }</a>`;
  },
  warning: (block) => {
    const { title, message } = block.data;
    return `
      <div class="warning-block">
        <strong>${title}</strong>
        <p>${message}</p>
      </div>
    `;
  },
  raw: (block) => {
    return block.data.html;
  },
  checklist: (block) => {
    const { items } = block.data;

    let checklistHtml = '<ul class="checklist">';

    items.forEach((item) => {
      checklistHtml += `<li class="${item.checked ? "checked" : ""}">${
        item.text
      }</li>`;
    });

    checklistHtml += "</ul>";
    return checklistHtml;
  },
  delimiter: (block) => {
    return "<p>***</p>";
  },
};

const edjsParser = EditorJSHTML(customParsers);

export default edjsParser;
