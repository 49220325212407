import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import Chip from "@mui/material/Chip";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

import { PencilAlt as PencilAltIcon } from "../../../icons/pencil-alt.js";
import { SyllbusIcon } from "../../../icons/syllbusIcon.js";
import { LearnIcon } from "../../../icons/learnIcon.js";
import { Delete } from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useNavigate, useLocation } from "react-router-dom";
import {
  deleteCourseById,
  getALLCourses,
} from "../../../api/courses/courses.js";
import { useDispatch } from "react-redux";

let options = [
  {
    text: "What will you learn",
    iconCss: <SyllbusIcon />,
    link: "learn",
  },
  {
    text: "Syllabus",
    iconCss: <LearnIcon />,
    link: "syllbus",
  },
  {
    text: "Training",
    iconCss: <LearnIcon />,
    link: "training",
  },
  {
    text: "Tutors",
    iconCss: <PeopleAltIcon />,
    link: "tutors",
  },
  {
    text: "Salary per companies",
    iconCss: <MonetizationOnIcon />,
    link: "salarypercompanies",
  },
  {
    text: "Reviews",
    iconCss: <DoneAllIcon />,
    link: "reviewers",
  },
  {
    text: "Delete course",
    iconCss: <Delete color="error" />,
    // link: "reviewers",
  },
];

export default function CustomDropDown(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        size="small"
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </Button>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => {
                    if (option.text.includes("Delete")) {
                      return (
                        <Box
                          key={index}
                          onClick={async () => {
                            await dispatch(
                              deleteCourseById({ CourseId: props.id })
                            );
                            dispatch(getALLCourses(props.values));
                          }}
                        >
                          <MenuItem
                            sx={{
                              width: "90%",
                              margin: "0 auto",
                              borderTop: "2px solid black",
                              paddingX: "5 ",
                            }}
                            key={option.text}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            <ListItemIcon>
                              <Icon>{option.iconCss}</Icon>
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: 400,
                                color: "red",
                              }}
                            >
                              {option.text}
                            </ListItemText>
                          </MenuItem>
                        </Box>
                      );
                    }

                    return (
                      <Box
                        onClick={() => {
                          navigate(`${pathname}/${props.id}/${option?.link}`);
                        }}
                      >
                        <MenuItem
                          key={option.text}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          <ListItemIcon>
                            <Icon>{option.iconCss}</Icon>
                          </ListItemIcon>
                          <ListItemText
                            sx={{ display: "flex", flexDirection: "row" }}
                          >
                            {option.text}
                          </ListItemText>
                        </MenuItem>
                      </Box>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
