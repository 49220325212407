import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { PencilAlt as PencilAltIcon } from "../../../icons/pencil-alt";
import { Link as RouterLink } from "react-router-dom";
import deleteIcon from "../../../icons/delete.svg";
// import Modal from "../../../modal/Modal";
import AppsIcon from "@mui/icons-material/Apps";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Link,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getALLCourses } from "../../../api/courses/courses";
import Loading from "../../../components/custom/Loading";
import CustomDropDown from "../../../components/dashboard/courses/CustomDropDown";
import CustomTable from "../../../components/dashboard/courses/CustomTable";
import CourseListCard from "../../../components/dashboard/courses/course-list-card";
import { apiAssetsUrl } from "../../../config/config";
import { useMounted } from "../../../hooks/use-mounted";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
// import { Search as SearchIcon } from '../../icons/Search';
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { FormInputText } from "../../../components/custom/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { FormInputRadio } from "../../../components/custom/form-components/FormInputRadio";
import {
  handleLanguagesChange,
  reset,
} from "../../../reducers/courses/courses";
import Select from "@mui/material/Select";

import { Search as SearchIcon } from "../../../icons/search";
import {
  deleteGroup,
  getAllGroups,
  postGroup,
  putGroup,
} from "../../../api/courses/group";
import { Option } from "@mui/joy";

const defaultValues = {
  name: "",
  courseId: "",
  groupStatus: "",
  requiredStudents: "",
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
const Group = () => {
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const dispatch = useDispatch();
  const courseList = useSelector(({ courses }) => courses.courses.courseList);

  const totalPages = useSelector(({ courses }) => courses.group.totalPages);

  const group = useSelector(({ courses }) => courses.group.group);

  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );
  // const [courses, setCourses] = useState([]);
  const isMounted = useMounted();
  const [isCard, setIsCard] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(false);
  const { width } = useWindowDimensions();
  const [age, setAge] = useState("Status");
  const [age3, setAge3] = React.useState("");
  const [select, setSelect] = useState("ALL");
  const [select2, setSelect2] = useState("All");

  const handleChange3 = (event) => {
    setAge3(event.target.value);
  };
  const [editTitle, setEditTitle] = useState("");
  const [editCourse, setEditCourse] = useState("");
  const [courseIdx, setCourseIdx] = useState(null);
  const [idx, setIdx] = useState(null);
  // const [editRegisterStudent, setEditRegisterStudent] = useState(0);
  const [editRequredStudent, setEditRequaredStudent] = useState(0);
  const [editStatus, setEditStatus] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    reset,
    register,
    watch,
    control,
    setValue: setFormValue,
  } = methods;

  const onSubmit = async (values) => {
    console.log(values);
    values.groupStatus = +values.groupStatus;
    dispatch(
      postGroup({
        values,
        callback: () => {
          setOpen(false);
          reset();
        },
      })
    );
  };
  // const onSubmit2 = async (obj) => {
  //   dispatch(putGroup(obj));
  // };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
    
  };

  const handleClose = () => {
    setOpen(false);
    value.name = ""
  };
  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    dispatch(
      getAllGroups({
        Name: searchTerm,
        CourseId: "",
        GroupStatus: "",
        PageNumber: page === 0 ? 1 : page,
        PageSize: 10,
      })
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const editHandleChange = (event) => {
    setEditStatus(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (editCourse) {
      // Find the corresponding courseId when editCourse changes
      const selectedCourse = courseList.find((e) => e.label === editCourse);
      setCourseIdx(selectedCourse?.value);
    }
  }, [editCourse, courseList]);

  useEffect(
    () => {
      window.scrollTo(0, 0);
      dispatch(
        getAllGroups({
          Name: "",
          CourseId: "",
          GroupStatus: "",
          PageNumber: page === 0 ? 1 : page,
          PageSize: 10,
        })
      );
      dispatch(getALLCourses({}));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, rowsPerPage]
  );

  return (
    <>
      {isLoading ? (
        width >= 1200 ? (
          <Loading width={true} />
        ) : (
          <Loading width={false} />
        )
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              sx={{ marginBottom: 5 }}
            >
              <Grid sx={{ marginLeft: 2.5 }}>
                <Typography variant="h4">Group</Typography>
              </Grid>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "45px" }}>
                <Box>
                  <IconButton
                    onClick={handleClickOpen}
                    style={{
                      background: "#506A85",
                      color: "#fff",
                      borderRadius: "16px",

                      height: "48px",
                      width: "150px",
                    }}
                  >
                    <AddIcon />
                    <Typography
                      sx={{
                        ml: "5px",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontStyle: "normal",
                      }}
                    >
                      Add new
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            <Box
              component="form"
              // onSubmit={handleQueryChange}
              sx={{
                marginBottom: 5,
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                gap: "14px",
                flexWrap: "wrap",
              }}
            >
              <TextField
                defaultValue=""
                value={searchTerm}
                onChange={handleSearchInputChange}
                name="firstName"
                size="small"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "10px !important",

                  border: "2px solid #B1C4D7",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />
              <Box sx={{}}>
                <Select
                  sx={{
                    border: "2px solid #B1C4D7",
                    borderRadius: "16px",
                    width: "240px",
                    height: "48px",
                  }}
                  id="demo-simple-select-helper"
                  value={select2}
                  onChange={(event) => setSelect2(event.target.value)}
                >
                  <MenuItem value="All">
                    <em>Status</em>
                  </MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive ">Inactive</MenuItem>
                </Select>
                {/* Select Courses */}
                <Select
                  sx={{
                    border: "2px solid #B1C4D7",
                    borderRadius: "16px",
                    width: "240px",
                    height: "48px",
                    marginLeft: "20px",
                  }}
                  id="demo-simple-select-helper"
                  value={select}
                  onChange={(event) => setSelect(event.target.value)}
                >
                  <MenuItem value="ALL">
                    <em>Courses</em>
                  </MenuItem>
                  {courseList?.map((e) => {
                    return (
                      <MenuItem key={e.value} value={e.value}>
                        {e.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
          </Box>
          {isCard && (
            <CustomTable
              headers={[
                "Title",
                "Course",
                "Registered students",
                "Required students",
                "Status",
                "Action",
              ]}
            >
              {group.length > 0 &&
                group
                  ?.filter((e) => {
                    if (select2 === "All") {
                      return e;
                    }
                    return e.groupStatus == select2;
                  })
                  ?.filter((elem) => {
                    if (select === "ALL") {
                      return elem;
                    }

                    return elem.courseId == select;
                  })
                  ?.map((elem) => {
                    return (
                      <TableRow>
                        <TableCell
                          sx={{ textAlign: "center", fontSize: "16px" }}
                        >
                          {elem?.name}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontSize: "16px" }}
                        >
                          {
                            courseList?.find((e) => {
                              if (elem.courseId == e.value) {
                                return e.label;
                              }
                            })?.label
                          }
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontSize: "16px" }}
                        >
                          {elem?.registeredStudents}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontSize: "16px" }}
                        >
                          {elem?.requiredStudents}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontSize: "16px" }}
                        >
                          {elem?.groupStatus}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            fontFamily: "Montserrat",
                            cursor: "pointer",
                          }}
                          align="right"
                          style={{ width: "120px" }}
                        >
                          <Link component={RouterLink}>
                            <IconButton component="a">
                              <PencilAltIcon
                                onClick={() => {
                                  handleClickOpen2();
                                  setIdx(elem?.id);
                                  setEditCourse(
                                    courseList?.find((e) => {
                                      if (elem.courseId == e.value) {
                                        return e;
                                      }
                                    })?.label
                                  );
                                  setEditTitle(elem?.name);
                                  setIdx(elem?.id);
                                  setEditCourse(
                                    courseList?.find((e) => {
                                      if (elem.courseId == e.value) {
                                        return e;
                                      }
                                    })?.label
                                  );
                                  setEditRequaredStudent(
                                    elem?.requiredStudents
                                  );
                                  setEditStatus(
                                    elem?.groupStatus == "Active" ? 1 : 2
                                  );
                                }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Link>
                          <IconButton
                            onClick={() => {
                              dispatch(deleteGroup(elem?.id));
                              dispatch(getAllGroups());
                            }}
                            component="a"
                            sx={{ marginLeft: "auto" }}
                          >
                            <img src={deleteIcon} alt="delete" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </CustomTable>
          )}
          {isCard === false && (
            <CourseListCard
              courses={group}
              coursesCount={group.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          )}
        </Container>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "end",
            mr: "20px",
            pb: "30px",
          }}
        >
          <Pagination
            color="primary"
            page={page}
            count={totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
          />
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardContent>
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 486,
                    bgcolor: "background.paper",
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="name">
                      Name
                    </label>
                    <FormInputText name="name" control={control} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="requiredStudents">
                      Required Students
                    </label>
                    <FormInputText name="requiredStudents" control={control} />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="groupId">
                      Course
                    </label>
                    <FormInputText
                      name="courseId"
                      control={control}
                      select={true}
                      options={courseList}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "500px",
                      paddingTop: "8px",
                      gap: "30px",
                    }}
                  >
                    <Box>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Status
                        </FormLabel>
                        <Box>
                          <FormInputRadio
                            control={control}
                            options={[
                              { value: "1", label: "Active" },
                              { value: "2", label: "Hidden" },
                            ]}
                            name="groupStatus"
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </Box>
                </List>
              </CardContent>
            </Card>
            <Box
              container
              sx={{
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                type="submit"
                sx={{ m: 1 }}
                variant="contained"
                onClick={handleClose}
              >
                Add
              </Button>

              <Button
                sx={{
                  m: 1,
                  mr: "auto",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <BootstrapDialog
        onClose={handleClose2}
        aria-labelledby="customized-dialog-title"
        open={open2}
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Edit"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                <TextField
                  fullWidth
                  sx={{ mb: "10px" }}
                  value={editTitle}
                  id="outlined-basic"
                  onChange={(event) => setEditTitle(event.target.value)}
                  label="Title"
                  variant="outlined"
                />
                <FormControl sx={{ mb: "10px" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">Course</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editCourse}
                    label="Age"
                    onChange={(event) => setEditCourse(event.target.value)}
                  >
                    {courseList?.map((e) => {
                      return (
                        <MenuItem key={e.id} value={e.label}>
                          {e.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  sx={{ mb: "10px" }}
                  value={editRequredStudent}
                  label="Required Students"
                  onChange={(event) =>
                    setEditRequaredStudent(event.target.value)
                  }
                  type="number"
                  variant="outlined"
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    statusGroup
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editStatus}
                    label="statusGroup"
                    onChange={editHandleChange}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={2}>Inavtive</MenuItem>
                  </Select>
                </FormControl>
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              onClick={() => {
                setCourseIdx(
                  courseList?.find((e) => {
                    if (e.label == editCourse) {
                      return e;
                    }
                  })?.value
                );
                dispatch(
                  putGroup({
                    name: editTitle,
                    requiredStudents: editRequredStudent,
                    id: idx,
                    courseId: courseIdx,
                    groupStatus: editStatus,
                  }),

                  dispatch(getAllGroups()),
                  setOpen2(false)
                );
              }}
            >
              Add
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={() => setOpen2(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default Group;
