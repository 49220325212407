import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../utils/axiosRequest";
import paramsForUrl from "../../utils/paramsForUrl";

export const GetAllStudents = createAsyncThunk(
  "Student/GetAllStudents",
  async (param, { rejectWithValue }) => {
    let obj = paramsForUrl(param);
    try {
      const { data } = await axiosRequest.get(`Student/GetAllStudents${obj}`);

      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const GetAllStudentsById  = createAsyncThunk(
  "Student/GetAllGroupStudents_ByGroup",
  async (param, { rejectWithValue }) => {
    let obj = paramsForUrl(param);
    try {
      const { data } = await axiosRequest.get(`Student/GetAllGroupStudents_ByGroup${obj}`);

      return data.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);


export const postStudents = createAsyncThunk(
  "Student/postStudents",
  async ({ values, callback }, { dispatch }) => {
    try {
      console.log(values);
      const response = await axiosRequest.post(`Student/addStudent`, values);
      dispatch(GetAllStudents({}));
      if (!response.ok) {
        return new Error("Couldn't post");
      }
      callback();
    } catch (e) {}
  }
);
