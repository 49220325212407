import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllTutors } from "../../../api/courses/courses";
import { FormInputText } from "../../../components/custom/form-components/FormInputText";
import editIcon from "../../../icons/edit.svg";
import uploadIcon from "../../../icons/upload.png";
import { handleLanguagesChange } from "../../../reducers/courses/courses";
import { axiosRequest } from "../../../utils/axiosRequest";
import { fileToBase64 } from "../../../utils/file-to-base64";
import generateUUID from "../../../utils/guid";

const defaultValues = {
  FirstName: "",
  LastName: "",
  Position: "",
  Workplace: "",
  Experience: 1,
  Photo: "",
};
function AddTutor() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [skills, setSkills] = useState([]);
  const [cover, setCover] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );
  const { id } = useParams();
  const [fieldValue, setFieldValue] = useState("");
  const [value, setValue] = React.useState(
    dataLanguages.filter((elem) => elem.completed)[0]?.name
  );
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, register, control, setValue: setFormValue } = methods;

  const handleChange = (event, newValue) => {
    setFormValue("Language", newValue);
    setValue(newValue);
  };

  const onSubmit = async (values) => {
    if (!value) {
      toast.error("Select language");
    } else {
      const formData = new FormData();

      for (let key in values) {
        formData.append(key, values[key]);
      }
      formData.append("Language", value);
      skills.forEach((e, ix) => {
        formData.append(`TeacherSkills[${ix}].id`, 0);
        formData.append(`TeacherSkills[${ix}].skillImage`, e.skill);
      });

      try {
        const { data } = await axiosRequest.post(
          `Teacher/AddTeacher`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (data.code === 200) {
          navigate(-1);
          toast.success("Teacher Added");
          dispatch(getAllTutors({ Language: value ? value : "rus" }));
          setValue("");
        }
      } catch (e) {}
    }
  };

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }
  function changeDescValue(value) {
    setFieldValue(value);
    setFormValue("About", value);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDropCover = async (name, file) => {
    const data = await fileToBase64(file);
    setCover(data);
    setFormValue(name, file);
    return;
  };
  const handleDropSkill = async (files) => {
    const skill = [...skills];
    for (let i = 0; i < files.length; i++) {
      const data = await fileToBase64(files[i]);
      skill.push({
        id: generateUUID(),
        cover: data,
        skill: files[0],
      });
    }
    setSkills(skill);
  };

  const handleRemoveSkill = (id) => {
    setSkills(skills.filter((elem) => elem.id !== id));
  };
  const handleRemove = (name) => {
    setCover(null);
    setFormValue(name, "");
  };
  useEffect(
    () => {
      dispatch(getAllTutors({ Language: value ? value : "rus" }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, value, id]
  );
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ marginLeft: 1 }}>
            <Typography variant="h4">
              <Button>
                {" "}
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginRight: "5px" }}
                />
              </Button>
              Courses / Tutor / New tutor
            </Typography>
          </Box>
          <Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "435px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              {dataLanguages
                .filter((elem) => elem.completed)
                .map((e) => {
                  return (
                    <Tab
                      key={e.id}
                      icon={<img src={e.avatar} alt="russianIcon" />}
                      iconPosition="start"
                      value={e.name}
                      label={e.name}
                    >
                      <span
                        style={{
                          width: "1px",
                          height: "24px",
                          border: "1px solid rgb(163 163 155)",
                          marginTop: "12px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      ></span>
                    </Tab>
                  );
                })}
              <Tab
                onClick={handleClickOpen}
                icon={<img src={editIcon} alt="engIcon" />}
                style={{
                  height: "41px",
                  width: "41px",
                  background: "#E6E6E6",
                  lineHeight: "24px",
                  marginLeft: "15px",
                }}
                iconPosition="start"
              >
                {" "}
              </Tab>
            </Tabs>
          </Box>
        </Grid>
        {/* width:{xs:"150px",sm:"200px"} */}
        {/* Add Course */}

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Card sx={{ mt: 4, position: "relative" }}>
                  <CardContent
                    sx={{
                      width: {
                        xs: "full",
                        sm: "500",
                        md: "550",
                        lg: "600px",
                      },
                    }}
                  >
                    <Box sx={{ mt: 3 }}>
                      <label style={{ marginBottom: "10px" }} for="Name">
                        Name
                      </label>
                      <FormInputText name="FirstName" control={control} />

                      <Box sx={{ mt: 2 }}>
                        <label for="Surname">Surname</label>
                        <FormInputText name="LastName" control={control} />
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <label for="Position">Position</label>
                        <FormInputText name="Position" control={control} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            mt: 2,
                            height: "88px",
                            width: "306px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Work place
                          </Typography>
                          <FormInputText name="Workplace" control={control} />
                        </Box>

                        <Box
                          sx={{
                            mt: 2,
                            width: "170px",
                            height: "88px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Experience (year)
                          </Typography>
                          <FormInputText name="Experience" control={control} />
                        </Box>
                      </Box>

                      <Box sx={{ mt: 3 }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          Description
                        </Typography>

                        <Box
                          sx={{
                            height: "283px",
                            borderRadius: "16px",
                          }}
                        >
                          <ReactQuill
                            value={fieldValue}
                            onChange={(value) => changeDescValue(value)}
                            modules={{
                              toolbar: [
                                [
                                  { header: "1" },
                                  { header: "2" },
                                  { font: [] },
                                ],
                                [{ size: [] }],
                                [
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "blockquote",
                                ],

                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image", "video"],
                                ["clean"],
                              ],
                              clipboard: {
                                matchVisual: false,
                              },
                            }}
                            formats={[
                              "header",
                              "font",
                              "size",
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                              "list",
                              "bullet",
                              "link",
                              "image",
                              "video",
                            ]}
                            theme="snow"
                            readOnly={false}
                            placeholder="Type something..."
                            className="custom-quill-editor"
                            style={{ height: "200px", borderRadius: "16px" }}
                            bounds=".scrollable-container"
                            scrollingContainer=".scrollable-container"
                          />
                        </Box>
                      </Box>
                      <Box
                        style={{
                          position: "absolute",
                          right: 50,
                          top: 10,
                          width: "250px",
                        }}
                      >
                        <label for="Logo">
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "20px",
                            }}
                          >
                            Logo
                          </Typography>
                          <Box
                            sx={{
                              width: "250px",
                              height: "250px",
                              border: "2px dashed #C9DCEC",
                              borderRadius: "20px",
                              mt: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {cover ? (
                              <>
                                <img
                                  src={cover}
                                  alt="alt"
                                  style={{
                                    width: "100%",
                                    height: 245,
                                    borderRadius: 20,
                                  }}
                                />
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: 60,
                                    borderRadius: "50%",
                                    right: 20,
                                    color: "#fff",
                                    background: "rgba(0, 0, 0, 0.48)",
                                    "&:hover": {
                                      background: "rgba(0, 0, 0, 0.48)",
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove("Logo");
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </>
                            ) : (
                              <Box
                                sx={{
                                  backgroundColor: "#F2F4F5",
                                  borderRadius: "100px",
                                  width: "64px",
                                  height: "64px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={uploadIcon} alt="upload" />
                              </Box>
                            )}
                          </Box>
                          <input
                            type="file"
                            id="Logo"
                            {...register("Logo")}
                            onChange={(event) => {
                              handleDropCover("Photo", event.target.files[0]);
                            }}
                            style={{ display: "none" }}
                          />
                        </label>
                        <Box
                          sx={{
                            mt: "20px",
                          }}
                        ></Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          mt: "20px",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{ fontWeight: 700, fontSize: "20px" }}
                          >
                            Scills (image .svg)
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 2,
                            }}
                          >
                            {skills.length > 0 ? (
                              skills.map((elem) => {
                                return (
                                  <Box
                                    sx={{
                                      width: "120px",
                                      height: "120px",
                                      border: "2px dashed #C9DCEC",
                                      borderRadius: "20px",
                                      mt: "20px",
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={elem.cover}
                                      alt="alt"
                                      style={{
                                        width: "72px",
                                        height: 83,
                                        borderRadius: 20,
                                      }}
                                    />
                                    <IconButton
                                      sx={{
                                        position: "absolute",
                                        top: 5,
                                        width: "22px",
                                        height: "22px",
                                        borderRadius: "50%",
                                        right: 5,
                                        color: "#fff",
                                        background: "rgba(0, 0, 0, 0.48)",
                                        "&:hover": {
                                          background: "rgba(0, 0, 0, 0.48)",
                                        },
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveSkill(elem.id);
                                      }}
                                    >
                                      <Close style={{ height: 15 }} />
                                    </IconButton>
                                  </Box>
                                );
                              })
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "120px",
                                      height: "120px",
                                      border: "2px dashed #C9DCEC",
                                      borderRadius: "20px",
                                      mt: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <label for="Skills">
                                      <Box
                                        sx={{
                                          backgroundColor: "#F2F4F5",
                                          borderRadius: "100px",
                                          width: "64px",
                                          height: "64px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <img src={uploadIcon} alt="Skills" />
                                        <Typography>Upload</Typography>
                                      </Box>
                                      <input
                                        type="file"
                                        multiple
                                        id="Skills"
                                        onChange={(event) => {
                                          handleDropSkill(event.target.files);
                                        }}
                                        style={{ display: "none" }}
                                      />
                                    </label>
                                  </Box>
                                </Box>
                              </>
                            )}

                            <label for="skill">
                              <input
                                ref={ref}
                                type="file"
                                id="Skill"
                                multiple
                                onChange={(event) => {
                                  handleDropSkill(event.target.files);
                                }}
                                style={{ display: "none" }}
                              />
                              <Button
                                variant="contained"
                                sx={{
                                  background: "#F2F4F5",
                                  width: 100,
                                  height: 120,
                                  mt: "20px",
                                  color: "#506A85",
                                  borderRadius: "20px",
                                }}
                                onClick={() => ref.current.click()}
                              >
                                + <br /> Add
                              </Button>
                            </label>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Button type="submit" variant="contained" value="submit">
                    Add
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    sx={{ border: "2px solid #B1C4D7", borderRadius: "16px" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* Modal for Languages */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                {dataLanguages.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          checked={value.completed}
                          onClick={() => handleToggle(value.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt={`Avatar`} src={value.avatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={` ${value.language}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button onClick={handleClose} sx={{ m: 1 }} variant="contained">
              Edit
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default AddTutor;
