import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../utils/axiosRequest";
import paramsForUrl from "../../utils/paramsForUrl";
import { useSelector } from "react-redux";

export const getAllGroups = createAsyncThunk(
  "courses/getAllGroups",
  async ({ GroupStatus, ...param }, { rejectWithValue }) => {
    let obj = paramsForUrl({ ...param, ...(GroupStatus && { GroupStatus }) });
    try {
      const { data } = await axiosRequest.get(`Group/GetAllGroups${obj}`);
      console.log(obj);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const postGroup = createAsyncThunk(
  "group/postGroup",
  async ({ values, callback }, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await axiosRequest.post(`Group/AddNewGroup`, values);
      dispatch(getAllGroups({}));
      callback();
      return data;
    } catch (error) {
      rejectWithValue(error);
    }     
  }
);

export const putGroup = createAsyncThunk(
  "group/putGroup",
  async (param, { rejectWithValue, dispatch }) => {
    console.log(param);
    try {
      const { data } = await axiosRequest.put(`Group/UpdateGroup`, param);
      dispatch(getAllGroups({}));
      console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "group/postGroup",
  async (param, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.delete(
        `Group/DeleteGroup?GroupId=${param}`,
        param
      );
      dispatch(getAllGroups({}));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
