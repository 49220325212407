import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../utils/axiosRequest";
import paramsForUrl from "../../utils/paramsForUrl";
import toast from "react-hot-toast";

export const GetAllUITranslations = createAsyncThunk(
  "translate/GetAllUITranslations",
  async (param = {}, { rejectWithValue }) => {
    let obj = paramsForUrl(param);
    try {
      const { data } = await axiosRequest.get(
        `UITranslation/GetAllUITranslations${obj}`
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const postTranslation = createAsyncThunk(
  "translate/postTranslation",
  async ({ values, callback }, { dispatch }) => {
    try {
      console.log(values);
      const { data } = await axiosRequest.put(
        `UITranslation/AddOrUpdateUITranslation`,
        values
      );
      if (data.code == 200) {
        toast.success(data.message);
        dispatch(GetAllUITranslations({}));
        callback();
      }
    } catch (e) {
      toast.error(e);
    }
  }
);
export const deleteTranslation = createAsyncThunk(
  "translate/deleteTranslation",
  async (id, { dispatch }) => {
    try {
      const { data } = await axiosRequest.delete(
        `UITranslation/DeleteUITranslation?Id=${id}`
      );
      if (data.code == 200 || data.code == 404) {
        dispatch(GetAllUITranslations());
        toast.success(data.message);
      }

      console.log(data);
    } catch (error) {
      toast.error(error);
    }
  }
);
