class ItalicTool {
    static get isInline() {
      return true;
    }
  
    static get title() {
      return "Italic";
    }
  
    static get sanitize() {
      return {
        em: {},
      };
    }
  
    static get shortcut() {
      return "CMD+I";
    }
  
    render() {
      this.button = document.createElement("button");
      this.button.type = "button";
      this.button.innerHTML = "<em>I</em>";
      this.button.classList.add("ce-inline-tool");
  
      return this.button;
    }
  
    surround(range) {
      if (this.button.classList.contains("ce-inline-tool--active")) {
        document.execCommand("italic");
      } else {
        document.execCommand("italic", false, null);
      }
    }
  
    checkState() {
      const state = document.queryCommandState("italic");
      this.button.classList.toggle("ce-inline-tool--active", state);
    }
  
    static get toolbox() {
      return {
        title: "Italic",
        icon: '<i class="fas fa-italic"></i>',
      };
    }
  }
  
  export default ItalicTool;
  