import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import AIText from "@alkhipce/editorjs-aitext";
import TextVariantTune from "@editorjs/text-variant-tune";
import ItalicTool from "./ItalicTool";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import TextAlignment from "editorjs-text-alignment-blocktune";
import Underline from "@editorjs/underline";

export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    config: {
      alignment: {
        options: ["left", "center", "right", "justify"],
      },
    },
    tunes: ["anyTuneName"],
  },
  embed: Embed,
  table: Table,
  list: List,
  textAlignment: TextAlignment,
  warning: Warning,
  code: Code,
  anyTuneName: {
    class: AlignmentTuneTool,
    config: {
      default: "left",
      blocks: {
        header: "center",
        list: "right",
      },
    },
  },
  textVariant: TextVariantTune,
  linkTool: LinkTool,
  italic: ItalicTool,
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          console.log(file); // Log the file object to ensure it's correct

          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("File", file); // Append the file to the FormData with the correct key

            fetch(`https://webadminapi.softclub.tj/api/files`, {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((result) => {
                console.log("Upload response:", result); // Log the result

                if (result && result.isSuccess) {
                  resolve({
                    success: 1,
                    file: {
                      url: result.data, // Use the URL provided by the server
                    },
                  });
                } else {
                  reject(new Error("Image upload failed"));
                }
              })
              .catch((error) => {
                console.error("Upload error:", error); // Log any error
                reject(new Error("Image upload failed"));
              });
          });
        },
      },
    },
  },
  raw: Raw,
  header: {
    class: Header,
    tunes: ["anyTuneName"],
    config: {
      alignment: {
        options: ["left", "center", "right", "justify"],
      },
      placeholder: "Enter a header",
      levels: [1, 2, 3],
      defaultLevel: 3,
    },
  },
  quote: Quote,
  Marker: {
    class: Marker,
    shortcut: "CMD+SHIFT+M",
  },
  aiText: {
    class: AIText,
    config: {
      // here you need to provide your own suggestion provider (e.g., request to your backend)
      // this callback function must accept a string and return a Promise<string>
      callback: (text) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve("AI: " + text);
          }, 1000);
        });
      },
    },
  },
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  underline: Underline,
};
