import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "./axiosRequest";

const AuthCheck = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const user = getToken();
  useEffect(() => {
    if (token && user) {
      return navigate("/dashboard");
    }
  }, [navigate, token, user]);

  return props.children;
};

export default AuthCheck;
