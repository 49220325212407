import { Box, Divider, Drawer, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../App.css";
import PersonIcon from '@mui/icons-material/Person';
import Logo from "../../assets/logoSoftClub.png";
import { Calendar as CalendarIcon } from "../../icons/calendar";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import { Home as HomeIcon } from "../../icons/home";
import { Newspaper as NewspaperIcon } from "../../icons/newspaper";
import { OfficeBuilding as OfficeBuildingIcon } from "../../icons/office-building";
import { Users as UsersIcon } from "../../icons/users";
import { getToken } from "../../utils/axiosRequest";
import { Scrollbar } from "../scrollbar";
import DashboardSidebarSection from "./dashboard-sidebar-section";
import { useLocation } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Course from "../../icons/course.png"
import Translate from "../../icons/translate.png"
import { th } from "date-fns/locale";
const sections = [
    {
      items: [
        {
          title: "Dashboard",
          path: "/dashboard",
          icon: <HomeIcon fontSize="small" />,
        },
        {
          title:"Tutors",
          icon: <WorkspacePremiumIcon fontSize="small" />,
          path: "/dashboard/courses/:id/tutors",
        },
        {
          title: "Students",
          icon: <UsersIcon fontSize="small" />,
          path: "/dashboard/students",
        },
        {
          title: "Posts",
          path: "/dashboard/posts",
          icon: <NewspaperIcon fontSize="small" />,
        },
        // {
        //   title: "Groups",
        //   path: "/dashboard/groups",
        //   icon: <OfficeBuildingIcon fontSize="small" />,
        // },
        // {
        //   title: "Mentors",
        //   path: "/dashboard/mentors",
        //   icon: <UsersIcon fontSize="small" />,
        // },
        // {
        //   title: "TimeTable",
        //   path: "/dashboard/timetable",
        //   icon: <CalendarIcon fontSize="small" />,
        // },
        {
          title: "Courses",
          path: "/dashboard/courses",
          icon: <img  src={Course}/>,

          children: [
            {
              title: "Courses",
              path: "/dashboard/courses",
            },
            {
              title: "Group",
              path: "/dashboard/group",
            },
          ],
        },
        {
          title: "Translation",
          path: "/dashboard/translation",
          icon: <img  src={Translate}/>,
        },
       
        // {
        //   title: "Adminstration",
        //   path: "/dashboard/adminstration",
        //   icon: <AdminPanelSettingsIcon fontSize="small" />,
        //   children: [
        //     {
        //       title: "Users",
        //       path: "/dashboard/adminstration/users",
        //       icon: <UsersIcon fontSize="small" />,
        //     },
        //     {
        //       title: "Roles",
        //       path: "/dashboard/adminstration/roles",
        //       icon: <VerifiedUserIcon fontSize="small" />,
        //     },
        //   ],
        // },
      ],
    },
  ]

const DashboardSidebar = (props) => {
  const location = useLocation();

  const theme = useTheme()

  const { onClose, open } = props;
  const role = getToken()?.userType;
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });

  const handlePathChange = () => {
    if (open) {
      onClose?.();
    }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );



  const [modal,setModal] = useState(false)

  const content = (

    <>

{/* <button onClick={()=>setModal(true)}>ff</button> */}
     <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div>
            <Box sx={{pt:4,pl:3}}>
              <img
                src={Logo}
                alt="logo"
                style={{ filter: "brightness(150%)" }}
              />
            </Box>
          </div>
          <Divider
            sx={{
              borderColor: "#2D3748",
              my: 3,
            }}
          />
<Box sx={{ flexGrow: 1 }}>
  {sections?.map((section,index) => (
    <DashboardSidebarSection
      key={section?.title}
      path={location.pathname}
      sx={{
        mt: 1,
        color:theme.palette.mode == "light" ? theme.palette.secondary.main = "#ffffff":theme.palette.secondary.main = "#ffffff",
        "& + &": {
          mt: 2,
          
        },
      }}
      {...section}
    />
  ))}
</Box>
          <Divider
            sx={{
              borderColor: "#2D3748", // dark divider
            }}
          />
        </Box>
      </Scrollbar>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
            theme.palette.mode === "dark" ? 1 : 0,
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
