import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormInputDate } from "../../../components/custom/form-components/FormInputDate";
import { FormInputText } from "../../../components/custom/form-components/FormInputText";
import { useMounted } from "../../../hooks/use-mounted";
import editIcon from "../../../icons/edit.svg";
import uploadIcon from "../../../icons/upload.png";
import { handleLanguagesChange } from "../../../reducers/courses/courses";
import { axiosRequest } from "../../../utils/axiosRequest";
import { fileToBase64 } from "../../../utils/file-to-base64";
import { Close } from "@mui/icons-material";
import {
  getReviewerById,
  getReviewerByName,
} from "../../../api/courses/courses";
import { apiAssetsUrl } from "../../../config/config";

function EditReviewers() {
  const courses = useSelector(({ courses }) => courses.courses.courses);
  const reviewer = useSelector(({ courses }) => courses.courses.editReviewer);
  const reviewerById = useSelector(
    ({ courses }) => courses.courses.reviewerById
  );
  console.log(reviewerById);

  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );
  let fullName = reviewer?.fullName?.split(" ");

  const defaultValues = {
    FirstName: "",
    LastName: "",
    ReviewerType: "",
    PublishDate: new Date(),
    Description: "",
    Position: "",
    WorkPlace: "",
    Experience: "",
  };

  const [cover, setCover] = useState(reviewer?.reviewerFormat);
  const [coverVideo, setCoverVideo] = useState(null);
  const [groups, setGroups] = useState([]);
  const isMounted = useMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorFile, setErrorFile] = useState(false);
  const [value, setValue] = React.useState(0);
  const [month, setMonth] = useState("none");
  const [isGraduent, setIsGraduent] = useState(false);
  const [open, setOpen] = useState(false);
  const [idx, setIdx] = useState(1);
  const methods = useForm({ defaultValues: defaultValues });
  const { courseId, id } = useParams();

  const {
    handleSubmit,
    reset,
    register,
    watch,
    control,
    setValue: setFormValue,
  } = methods;
  // let img = cover?.includes("mp4") ? 2 : 1;

  const onSubmit = async (value2) => {
    console.log(value2);
    if (!value) {
      alert("please select the language");
    } else {
      const formData = new FormData();
      formData.append("Language", value?.toLowerCase());
      // formData.append("ReviewerFormat", img);
      formData.append("Id", id);
      formData.append("CourseId", courseId);
      const formattedDate = value2?.PublishDate?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      value2.PublishDate = formattedDate;
      console.log(value2);
      for (let key in value2) {
        formData.append(key, value2[key]);
      }

      try {
        const { data } = await axiosRequest.put(
          `Reviewer/UpdateReviewer`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        navigate(-1);
        console.log(data.data);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const { pathname } = useLocation();

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setFormValue("Language", event.target.innerText);
    setValue(newValue);
    dispatch(getReviewerById({ ReviewerId: id, lang: newValue || "rus" }));
  };
  const handleDropCover = async (name, file, type) => {
    let s = type.split("/")[0];
    if (s === "image") {
      const data = await fileToBase64(file);
      setCover(data);
      setFormValue(name, file);
      return;
    } else if (s === "video") {
      setCoverVideo(file);
      setFormValue(name, file);
      return;
    }
    return;
  };

  const handleRemove = (name) => {
    setCover(null);
    setCoverVideo(null);
    setFormValue(name, "");
  };

  useEffect(() => {
    dispatch(getReviewerById({ ReviewerId: id, lang: value || "rus" }));
  }, []);
  useEffect(() => {
    reset({
      FirstName: reviewerById?.firstName,
      LastName: reviewerById?.lastName,
      ReviewerType: reviewerById?.reviewerType,
      PublishDate: new Date(reviewerById?.publishDate) || new Date(),
      Description: reviewerById?.description,
      Position: reviewerById?.position,
      WorkPlace: reviewerById?.workPlace || " ",
      Experience: reviewerById?.experience || 0,
    });
    setCover(reviewerById?.reviewerFormat);
    setIdx(reviewerById?.id);
  }, [methods, reset, reviewerById]);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ marginLeft: 1 }}>
            <Typography variant="h4">
              <Button>
                {" "}
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginRight: "5px" }}
                />
              </Button>
              Courses / Reviwers / Edit reviewer
            </Typography>
          </Box>
          <Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "435px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              {dataLanguages
                .filter((elem) => elem.completed)
                .map((e) => {
                  return (
                    <Tab
                      key={e.id}
                      icon={<img src={e.avatar} alt="russianIcon" />}
                      iconPosition="start"
                      value={e.name}
                      label={e.name}
                    >
                      <span
                        style={{
                          width: "1px",
                          height: "24px",
                          border: "1px solid rgb(163 163 155)",
                          marginTop: "12px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      ></span>
                    </Tab>
                  );
                })}
              <Tab
                onClick={handleClickOpen}
                icon={<img src={editIcon} alt="engIcon" />}
                style={{
                  height: "41px",
                  width: "41px",
                  background: "#E6E6E6",
                  lineHeight: "24px",
                  marginLeft: "15px",
                }}
                iconPosition="start"
              >
                {" "}
              </Tab>
            </Tabs>
          </Box>
        </Grid>

        {/* Add Course */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Card sx={{ mt: 4, position: "relative" }}>
                  <CardContent
                    sx={{
                      width: { xs: "full", sm: "500", md: "550", lg: "600px" },
                    }}
                  >
                    <Typography variant="h6">Basic details</Typography>
                    <Box sx={{ mt: 3 }}>
                      <label style={{ marginBottom: "10px" }} for="FirstName">
                        Name
                      </label>
                      <FormInputText name="FirstName" control={control} />

                      <Box sx={{ mt: 2 }}>
                        <label for="Surname">Surname</label>
                        <FormInputText name="LastName" control={control} />
                      </Box>

                      <Box
                        sx={{
                          mt: 2,
                          // width: "150px",
                          borderRadius: "16px",
                          height: "88px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          Type
                        </Typography>
                        <FormInputText
                          name="ReviewerType"
                          control={control}
                          callback={(value) => {
                            if (value === "2") {
                              setIsGraduent(true);
                            } else {
                              setIsGraduent(false);
                            }
                          }}
                          select={true}
                          options={[
                            { value: "1", label: "Student" },
                            { value: "2", label: "Graduent" },
                          ]}
                        />
                      </Box>
                      {isGraduent ? (
                        <>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Possition
                            </Typography>
                            <FormInputText name="Position" control={control} />
                          </Box>

                          <Box sx={{ mt: 3 }}>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Work place
                            </Typography>
                            <FormInputText name="WorkPlace" control={control} />
                          </Box>

                          <Box sx={{ mt: 3 }}>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Experience(year)
                            </Typography>
                            <FormInputText
                              name="Experience"
                              control={control}
                            />
                          </Box>
                        </>
                      ) : null}
                      <Box style={{ position: "absolute", right: 50, top: 10 }}>
                        <label for="smallLogo">
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "20px",
                              textAlign: "center",
                            }}
                          >
                            Upload image or video
                          </Typography>
                          <Box
                            sx={{
                              width: "238px",
                              height: "220px",
                              border: "2px dashed #C9DCEC",
                              borderRadius: "20px",
                              mt: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {!cover || cover === 1 ? (
                              <>
                                <img
                                  src={`${apiAssetsUrl}${reviewerById?.file}`}
                                  alt="alt"
                                  style={{
                                    width: "100%",
                                    height: 245,
                                    borderRadius: 20,
                                    objectFit: "cover",
                                  }}
                                />
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: 60,
                                    borderRadius: "50%",
                                    right: 20,
                                    color: "#fff",
                                    background: "rgba(0, 0, 0, 0.48)",
                                    "&:hover": {
                                      background: "rgba(0, 0, 0, 0.48)",
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove("File");
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </>
                            ) : cover ? (
                              <>
                                <img
                                  src={cover}
                                  alt="alt"
                                  style={{
                                    width: "100%",
                                    height: 245,
                                    borderRadius: 20,
                                    objectFit: "cover",
                                  }}
                                />
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: 60,
                                    borderRadius: "50%",
                                    right: 20,
                                    color: "#fff",
                                    background: "rgba(0, 0, 0, 0.48)",
                                    "&:hover": {
                                      background: "rgba(0, 0, 0, 0.48)",
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove("File");
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </>
                            ) : coverVideo ? (
                              <>
                                <video width="100%" height={245} controls>
                                  <source
                                    src={URL.createObjectURL(coverVideo)}
                                  />
                                </video>
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: 60,
                                    borderRadius: "50%",
                                    right: 20,
                                    color: "#fff",
                                    background: "rgba(0, 0, 0, 0.48)",
                                    "&:hover": {
                                      background: "rgba(0, 0, 0, 0.48)",
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove("File");
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </>
                            ) : (
                              <Box
                                sx={{
                                  backgroundColor: "#F2F4F5",
                                  borderRadius: "100px",
                                  width: "64px",
                                  height: "64px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={uploadIcon} alt="upload" />
                              </Box>
                            )}
                          </Box>
                          <input
                            type="file"
                            id="smallLogo"
                            {...register("File")}
                            onChange={(event) => {
                              handleDropCover(
                                "File",
                                event.target.files[0],
                                event.target.files[0].type
                              );
                            }}
                            style={{ display: "none" }}
                          />
                        </label>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <label for="PublishDate">PublishDate</label>
                        <FormInputDate name="PublishDate" control={control} />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <label for="Description">Description</label>
                        <FormInputText
                          name="Description"
                          multiline={true}
                          rows={4}
                          control={control}
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Button type="submit" variant="contained" value="submit">
                    Edit
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    sx={{ border: "2px solid #B1C4D7", borderRadius: "16px" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Modal for Languages */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                {dataLanguages.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          checked={value.completed}
                          onClick={() => handleToggle(value.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt={`Avatar`} src={value.avatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={` ${value.language}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button onClick={handleClose} sx={{ m: 1 }} variant="contained">
              Edit
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default EditReviewers;
