import { List, ListItem } from "@material-ui/core";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Link,
  TablePagination,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import deleteIcon from "../../../icons/delete.svg";
import editIcon from "../../../icons/edit.svg";
import { axiosRequest, getToken } from "../../../utils/axiosRequest";
import TemporaryDrawer from "../../modal/DrawerCard.jsx";
import Modal from "../../modal/Modal";
import DrawerAddToGroupCard from "./drawer-addToGroup-card";
import DrawerContactsCard from "./drawer-contacts-card";
import DrawerGroupsCard from "./drawer-groups-card";
import HaveAccess from "../../../utils/HaveAccess";
import { apiAssetsUrl } from "../../../config/config";

function StudentCard(props) {
  const {
    students,
    setStudents,
    studentsCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    deleteMentor,
    button,
    drawer,
    postStudentToGroup,
    groups,
    postInviteStudent,
    blockStudent,
    unBlockStudent,
    resetPasswordStudent,
    ...other
  } = props;
  const [studentId, setStudentId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [Opened, setOpened] = useState(false);
  const role = getToken()?.userType;
  const [id, setId] = useState(null);

  const HandleDialogOpen = () => {
    setIsOpen(true);
  };
  const HandleDialogClose = () => {
    setIsOpen(false);
  };
  const [drawerOpen, setDrawerOpen] = useState(false);

  const deleteStudent = async (id) => {
    try {
      const { data } = await axiosRequest.delete(`Student/${id}`);
      if (data.statusCode === 200) {
        setStudents(students.filter((i) => i.id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };
  // useEffect(() => {}, [students, groups]);
  return (
    <div {...other}>
      {button}

      <Grid container spacing={2}>
        {students.length > 0 &&
          students.map((post) => {
            let fullname = `${post.firstName} ${
              post.lastName ? post.lastName : ""
            }`;
            return (
              <Grid item md={4} lg={4} sm={6} xs={12}>
                <Card
                  raised
                  sx={{
                    width: { xs: "300px", sm: "300px" },
                    margin: "10px",
                    position: "relative",
                    cursor: "pointer",
                    borderRadius: "12px",
                    mx: "auto",
                  }}
                  key={post?.id}
                  onClick={() => {
                    setDrawerOpen(true);
                    setStudentId(post?.id);
                  }}
                >
                  <TemporaryDrawer
                    isOpen={drawerOpen}
                    width="1000px"
                    height="1000px"
                    style={{ zIndex: 2 }}
                    child="hello"
                    header={"Students Info"}
                    access={
                      +role === 0 || +role === 1 || +role === 2 ? true : false
                    }
                  >
                    <List
                      sx={{
                        marginBottom: "100px",
                        width: { xs: "99vw", sm: "100%" },
                      }}
                    >
                      <ListItem
                        sx={{
                          margin: "0 auto",
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <DrawerContactsCard
                          student={post}
                          postInviteStudent={postInviteStudent}
                          blockStudent={blockStudent}
                          unBlockStudent={unBlockStudent}
                          resetPasswordStudent={resetPasswordStudent}
                        />
                        <DrawerGroupsCard student={post} />
                        <HaveAccess
                          role={role}
                          access={+role === 0 || +role === 1 ? true : false}
                        >
                          <DrawerAddToGroupCard
                            groups={groups}
                            student={studentId}
                            postStudentToGroup={postStudentToGroup}
                          />

                          <Card
                            sx={{
                              // marginTop: "24px",
                              // height: "132px",
                              borderRadius: "12px",
                              width: { xs: "90vw", sm: "464px" },
                            }}
                          >
                            <CardContent
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "18px",
                              }}
                            >
                              <Grid
                                container
                                direction="column"
                                sx={{
                                  justifyContent: "space-between",
                                  gap: "20px",
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  sx={{ justifyContent: "space-between" }}
                                >
                                  <Grid item sx={{ margin: "auto 0" }}>
                                    <Typography>Delete Student</Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                    }}
                                  >
                                    <Button
                                      onClick={() => setOpened(true)}
                                      variant="outlined"
                                      color="error"
                                      sx={{ borderRadius: "100px" }}
                                      startIcon={
                                        <DeleteOutlinedIcon
                                          sx={{ color: pink[500] }}
                                        />
                                      }
                                    >
                                      Delete
                                    </Button>
                                    <Modal
                                      isOpen={Opened}
                                      handleClose={() => setOpened(false)}
                                      title={`Do you really want to delete student?`}
                                    >
                                      <Button
                                        component="a"
                                        sx={{
                                          marginLeft: "auto",
                                          height: "24px",
                                          width: "90px",
                                          padding: "0",
                                        }}
                                        onClick={() => {
                                          deleteStudent(post?.id);
                                          setOpened(false);
                                        }}
                                      >
                                        delete!
                                        <DeleteOutlinedIcon
                                          sx={{ color: pink[500] }}
                                        />
                                      </Button>
                                    </Modal>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </HaveAccess>
                      </ListItem>
                    </List>
                  </TemporaryDrawer>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "24px",
                      paddingBottom: "16px",
                    }}
                  >
                    <img
                      src={`${apiAssetsUrl + post?.photo}`}
                      alt="Mentor"
                      width={84}
                      height={84}
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <CardContent sx={{ padding: "10px 15px" }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        textAlign: "center",
                        // color: "#212121",
                        fontSize: "18px",
                        paddingBottom: "8px",
                      }}
                    >
                      {fullname}
                    </Typography>
                    {/* <div>hello</div> */}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        textAlign: "center",
                        fontSize: 12,
                        // color: "#212121",
                      }}
                    >
                      Phone: {post?.phone}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <Link
                      to={`/dashboard/students/edit/${post.id}`}
                      component={RouterLink}
                    >
                      <IconButton component="a">
                        <img src={editIcon} alt="edit" />
                      </IconButton>
                    </Link>
                    <IconButton component="a" sx={{ marginLeft: "auto" }}>
                      <img
                        src={deleteIcon}
                        alt="delete"
                        onClick={() => {
                          HandleDialogOpen();
                          setId(post?.id);
                        }}
                      />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      <Modal
        isOpen={isOpen}
        handleClose={HandleDialogClose}
        title={`Do you really want to delete student?`}
      >
        <Button
          component="a"
          sx={{
            marginLeft: "auto",
            height: "24px",
            width: "90px",
            padding: "0",
          }}
          onClick={() => {
            deleteStudent(id);
            // deleteMentor(post?.id);
            HandleDialogClose();
          }}
        >
          delete!
          <DeleteOutlinedIcon sx={{ color: pink[500] }} />
        </Button>
      </Modal>
      <TablePagination
        component="div"
        count={studentsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </div>
  );
}

export default StudentCard;
