import { createSlice } from "@reduxjs/toolkit";
import {
  deleteGroup,
  getAllGroups,
  postGroup,
  putGroup,
} from "../../api/courses/group";

export const slice = createSlice({
  name: "group",
  initialState: {
    group: [],
    groupList: [],
    totalPages: 0,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getAllGroups.pending]: (state) => {
      state.loading = true;
    },
    [getAllGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalPages = action.payload.totalPages;
      if (action.payload.data) {
        state.group = action.payload.data;
        let arr = [];
        action.payload.data.forEach((elem) => {
          arr.push({ label: elem.name, value: String(elem.id) });
        });
        state.groupList = arr;
      }
    },
    [getAllGroups.rejected]: (state) => {
      state.loading = false;
    },
    [postGroup.pending]: (state) => {
      state.loading = true;
    },
    [postGroup.fulfilled]: (state) => {
      state.loading = false;
    },
    [postGroup.rejected]: (state) => {
      state.loading = false;
    },
    [putGroup.pending]: (state) => {
      state.loading = true;
    },
    [putGroup.fulfilled]: (state) => {
      state.loading = false;
    },
    [putGroup.rejected]: (state) => {
      state.loading = false;
    },
    [deleteGroup.pending]: (state) => {
      state.loading = true;
    },
    [deleteGroup.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteGroup.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;
