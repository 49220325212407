import React from "react";
import "./Loading.css";

const Loading = (props) => {
  const { width } = props;
  return (
    <div
      className="loader-container"
      style={width ? { width: "calc(100% - 280px)" } : { width: "100%" }}
    >
      <span class="loader"></span>
    </div>
  );
};

export default Loading;
