import React, { useCallback, useRef } from "react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./tools/tools";

export default function Editor({ data, setData }) {
  if (typeof data === "string") {
    data = JSON.parse(data);
  }

  const editorCore = useRef(null);
  const ReactEditorJS = createReactEditorJS();

  const handleInitialize = useCallback((instance) => {
    instance._editorJS.isReady
      .then(() => {
        editorCore.current = instance;
      })
      .catch((err) => console.log("An error occurred", err));
  }, []);

  const handleSave = useCallback(async () => {
    try {
      const savedData = await editorCore.current.save();
      setData(savedData);
    } catch (err) {
      console.log("An error occurred while saving data", err);
    }
  }, [setData]);

  return (
    <div className="editor-container dark:bg-slate-50">
      <h4 className="edit-mode-alert">! Edit Mode Enabled</h4>
      <ReactEditorJS
        onInitialize={handleInitialize}
        tools={EDITOR_JS_TOOLS}
        defaultValue={data}
        onChange={handleSave}
      />
    </div>
  );
}
