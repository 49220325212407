import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export const FormInputText = ({
  name,
  control,
  type = "text",
  label,
  callback = () => {},
  multiline = false,
  rows,
  prop,
  select = false,
  options,
  defaultValue,
  disabled,
}) => 
{

  if (select) {
    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {
          callback(value);
          return (
            <TextField
              helperText={error ? error.message : null}
              size="small"
              error={!!error}
              onChange={onChange}
              value={value}
              required={true}
              select
              fullWidth
              {...prop}
              InputProps={{
                inputProps: { style: { borderRadius: "16px" } },
                style: { borderRadius: "16px" },
              }}
              placeholder={name}
              variant="outlined"
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          );
        }}
      />
    );
  }

  if (multiline) {
    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <TextField
            helperText={error ? error.message : null}
            size="small"
            error={!!error}
            onChange={onChange}
            value={value}
            required={true}
            multiline={true}
            rows={rows}
            fullWidth
            {...prop}
            InputProps={{
              inputProps: {
                style: {
                  borderRadius: "16px",
                  textOverflow: "ellipsis",
                  padding: 10,
                },
              },
              style: { borderRadius: "16px" },
            }}
            placeholder={name}
            variant="outlined"
          />
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          disabled={disabled}
          type={type}
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          required={true}
          onChange={onChange}
          value={value}
          fullWidth
          {...prop}
          InputProps={{
            inputProps: { style: { borderRadius: "16px" } },
            style: { borderRadius: "16px" },
          }}
          placeholder={name === "DisPrice" ? "0 c" : name}
          variant="outlined"
        />
      )}
    />
  );
}
