import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {
  Box,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  TableCell,
  TableRow,
  Pagination,
} from "@mui/material";
import { FormInputRadio } from "../../../components/custom/form-components/FormInputRadio";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import StudentCard from "../../../components/dashboard/student/student-card";
import { StudentListTable } from "../../../components/dashboard/student/student-list-table";
import { useMounted } from "../../../hooks/use-mounted";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Plus as PlusIcon } from "../../../icons/plus";
import { Search as SearchIcon } from "../../../icons/search";
import { axiosRequest } from "../../../utils/axiosRequest";
import MenuItem from "@mui/material/MenuItem";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Loading from "../../../components/custom/Loading";
import CustomTable from "../../../components/dashboard/courses/CustomTable";
import Select from "@mui/material/Select";
import { Label } from "recharts";
import TemporaryDrawer from "../../../components/modal/DrawerTable";

import { useDispatch } from "react-redux";
import { GetAllStudents, getStudents } from "../../../api/students/students";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { FormInputText } from "../../../components/custom/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { postStudents } from "../../../api/students/students";
import { getAllGroups } from "../../../api/courses/group";

import Temporary from "../../../components/dashboard/student/Temporary";
import { el } from "date-fns/locale";

let durationTypeList = [
  {
    value: "",
    label: "",
  },
];

const defaultValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "john@gmail.com",
  registrationDate: `${new Date().getFullYear()}-${
    new Date().getMonth() + 1 < 10
      ? "0" + (new Date().getMonth() + 1)
      : new Date().getMonth() + 1
  }-${
    new Date().getDate() < 10
      ? "0" + new Date().getDate()
      : new Date().getDate()
  }`,
  // birthDate: new Date(),
  groupId: 0,
  status: 0,
};

function Students() {
  // const [students, setStudents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [student, setStudent] = useState(null);
  const [total, setTotal] = useState(0);
  const { width } = useWindowDimensions();
  const [isCard, setIsCard] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMounted = useMounted();
  const [groupId, setGroupId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [age, setAge] = useState("Select by group");
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    reset,
    register,
    watch,
    control,
    setValue: setFormValue,
  } = methods;
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    values.groupId = +values.groupId;
    values.status = +values.status;
    dispatch(
      postStudents({
        values,
        callback: () => {
          setOpen(false);
        },
      })
    );
  };

  const students = useSelector(({ students }) => students.students);

  const totalPages = useSelector(({ students }) => students.totalPages);
  const group = useSelector(({ courses }) => courses.group.group);
  const groupList = useSelector(({ courses }) => courses.group.groupList);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(students)

  useEffect(() => {
    dispatch(
      getAllGroups({
        Name: "",
        CourseId: "",
        GroupStatus: "",
        PageNumber: page === 0 ? 1 : page,
        PageSize: 10,
      })
    );
    dispatch(
      GetAllStudents({
        FullName: "",
        Phone: "",
        StudentStatus: "",
        GroupId: "",
        PageNumber: page === 0 ? 1 : page,
        PageSize: 10,
      })
    );
    window.scrollTo(0, 0);
  }, [page, rowsPerPage]);

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    dispatch(
      GetAllStudents({
        FullName: searchTerm,
        Phone: "",
        StudentStatus: "",
        GroupId: "",
        PageNumber: page === 0 ? 1 : page,
        PageSize: 10,
      })
    );
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const [select, setSelect] = useState("ALL");

  return (
    <>
      {isLoading ? (
        width >= 1200 ? (
          <Loading width={true} />
        ) : (
          <Loading width={false} />
        )
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 4 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography variant="h4">Students</Typography>
              </Grid>
              <Grid item>
                <Box>
                  <IconButton
                    onClick={handleClickOpen}
                    style={{
                      background: "#506A85",
                      color: "#fff",
                      borderRadius: "16px",

                      height: "48px",
                      width: "150px",
                    }}
                  >
                    <AddIcon />
                    <Typography
                      sx={{
                        ml: "5px",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontStyle: "normal",
                      }}
                    >
                      Add new
                    </Typography>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "40px",
            }}
          >
            <Box
              // component="form"
              // onSubmit={handleQueryChange}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <TextField
                value={searchTerm}
                onChange={handleSearchInputChange}
                defaultValue=""
                name="firstName"
                size="small"
                sx={{
                  borderRadius: "10px !important",
                  border: "2px solid #B1C4D7",
                  backgroundColor: "white",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search student by name"
              />
            </Box>

            <Select
              sx={{
                border: "2px solid #B1C4D7",
                borderRadius: "16px",
                width: "240px",
                height: "48px",
              }}
              id="demo-simple-select-helper"
              value={select}
              onChange={(event) => setSelect(event.target.value)}
            >
              <MenuItem value="ALL">Group by course</MenuItem>
              {group.map((elem) => (
                <MenuItem key={elem.Id} value={elem.name}>
                  {elem.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* Table  */}

          {/* <Select value={select} onChange={(event)=>setSelect(event.target.value)} id="group">
    {group.map((elem) => (
      <MenuItem key={elem.id}>
        {elem.name}
      </MenuItem>
    ))}
  </Select>     */}

          <CustomTable headers={["Full name", "Phone", "Group", "Status"]}>
            {students.length > 0 &&
              students
                .filter((elem) =>
                  select == "ALL"
                    ? elem
                    : elem.groupName
                        .toLowerCase()
                        .includes(select.toLowerCase())
                )
                .map((elem, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        sx={{ fontSize: "16px" }}
                        onClick={() => {
                          setStudent(elem);
                          setDrawerOpen(true);
                        }}
                      >
                        {index + 1}.{elem?.fullName}
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: "center", fontSize: "16px" }}
                        onClick={() => {
                          setStudent(elem);

                          setDrawerOpen(true);
                        }}
                      >
                        {elem?.phone}
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: "center", fontSize: "16px" }}
                        onClick={() => {
                          setStudent(elem);
                          setDrawerOpen(true);
                        }}
                      >
                        {group?.find((el) => el.id === elem?.groupId)?.name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Button
                          sx={{
                            backgroundColor: "#DCFCE7",
                            width: "78px",
                            height: "28px",
                            borderRadius: "100px",
                          }}
                        >
                          {elem?.status}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </CustomTable>
        </Container>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "end",
            mr: "20px",
            pb: "30px",
          }}
        >
          <Pagination
            color="primary"
            page={page}
            count={totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
          />
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardContent>
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 486,
                    bgcolor: "background.paper",
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="firstName">
                      Name
                    </label>
                    <FormInputText name="firstName" control={control} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="lastName">
                      Surname
                    </label>
                    <FormInputText name="lastName" control={control} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="phone">
                      Phone
                    </label>
                    <FormInputText name="phone" control={control} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="groupId">
                      Group
                    </label>
                    <FormInputText
                      name="groupId"
                      control={control}
                      select={true}
                      options={groupList}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "500px",
                      paddingTop: "8px",
                      gap: "30px",
                    }}
                  >
                    <Box>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Status
                        </FormLabel>
                        <Box>
                          <FormInputRadio
                            control={control}
                            options={[
                              { value: "1", label: "Active" },
                              { value: "2", label: "Hidden" },
                            ]}
                            name="status"
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </Box>
                </List>
              </CardContent>
            </Card>
            <Box
              container
              sx={{
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                type="submit"
                sx={{ m: 1 }}
                variant="contained"
                onClick={handleClose}
              >
                Add
              </Button>

              <Button
                sx={{
                  m: 1,
                  mr: "auto",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Temporary
        elem={student}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
}

export default Students;
