import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ButtonBase,
  IconButton,
  Toolbar,
  useTheme,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu as MenuIcon } from "../../icons/menu";
import { Bell as BellIcon } from "../../icons/bell";
import { Search as SearchIcon } from "../../icons/search";
import { UserCircle as UserCircleIcon } from "../../icons/user-circle";
import { Users as UsersIcon } from "../../icons/users";
import { UserCircleBlue } from "../../icons/user-circle-blue";
import { AccountPopover } from "./account-popover";
import { ContactsPopover } from "./contacts-popover";
import { ContentSearchDialog } from "./content-search-dialog";
import { NotificationsPopover } from "./notifications-popover";
import { LanguagePopover } from "./language-popover";
import InputBase from "@mui/material/InputBase";
import { height } from "@mui/system";
import { Title } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { getToken } from "../../utils/axiosRequest";

const languages = {
  en: "/static/icons/uk_flag.svg",
  de: "/static/icons/de_flag.svg",
  es: "/static/icons/es_flag.svg",
};

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === "light"
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        boxShadow: "none",
      }),
}));

const LanguageButton = () => {
  const anchorRef = useRef(null);
  const { i18n } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <IconButton onClick={handleOpenPopover} ref={anchorRef} sx={{ ml: 1 }}>
        <Box
          sx={{
            display: "flex",
            height: 20,
            width: 20,
            "& img": {
              width: "100%",
            },
          }}
        >
          <img alt="" src={languages[i18n.language]} />
        </Box>
      </IconButton>
      <LanguagePopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

const ContentSearchButton = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenSearchDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseSearchDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box
        width="276px"
        height="40px"
        display="flex"
        border="1px solid #DFE3E7"
        borderRadius="100px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>
      {/* <Tooltip title="Search">
        <IconButton onClick={handleOpenSearchDialog} sx={{ ml: 1 }}>
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}
      {/* <ContentSearchDialog
        onClose={handleCloseSearchDialog}
        open={openDialog}
      /> */}
    </>
  );
};

const ContactsButton = () => {
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      {/* <Tooltip title="Contacts">
        <IconButton onClick={handleOpenPopover} sx={{ ml: 1 }} ref={anchorRef}>
          <UsersIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}
      {/* <ContactsPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      /> */}
    </>
  );
};

const NotificationsButton = () => {
  const anchorRef = useRef(null);
  const [unread, setUnread] = useState(0);
  const [openPopover, setOpenPopover] = useState(false);
  // Unread notifications should come from a context and be shared with both this component and
  // notifications popover. To simplify the demo, we get it from the popover

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleUpdateUnread = (value) => {
    setUnread(value);
  };

  return (
    <>
      <Box
        width="40px"
        height="40px"
        display="flex"
        border="1px solid #DFE3E7"
        borderRadius="100px"
        ml="30px"
      >
        <Tooltip title="Notifications">
          <IconButton ref={anchorRef} sx={{}} onClick={handleOpenPopover}>
            <Badge color="error" badgeContent={unread}>
              <BellIcon fontSize="small" />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <NotificationsPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        onUpdateUnread={handleUpdateUnread}
        open={openPopover}
      />
    </>
  );
};

const AccountButtonforLocation = () => {
  let cnt = 0;

  let [modal, setModal] = useState(false);

  return <>{/* <button onClick={()=>setModal(true)}>Close</button> */}</>;
};

const AccountButton = () => {
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const user = getToken();

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  console.log(getToken());

  const theme = useTheme();

  return (
    <>
      <Box
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 4,
          color: "black",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            pr: "12px",
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "14px", color: "#9E9E9E" }}
          >
            {user?.UserName}
          </Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: "12px", color: "#9E9E9E" }}
          >
            {user?.Email}
          </Typography>
        </Box>
        <Avatar
          component={ButtonBase}
          sx={{
            height: 40,
            width: 40,
            backgroundColor: "#E2E7EC",
          }}
          src={user?.avatar}
        >
          <UserCircleBlue />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export const DashboardNavbar = (props) => {
  const { onOpenSidebar, ...other } = props;

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280,
          },
          width: {
            lg: "calc(100% - 280px)",
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            display: "flex",
            gap: "40px",
            px: 2,
          }}
        >
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <AccountButtonforLocation />
          <ContentSearchButton />
          <ContactsButton />
          <Box sx={{ position: "relative", right: "30px" }}>
            <NotificationsButton />
          </Box>
          <AccountButton />
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
