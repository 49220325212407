import { Delete, Link } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import * as Yup from "yup";
import CustomTable from "../../../components/dashboard/courses/CustomTable";
import editIcon from "../../../icons/edit.svg";
import engIcon from "../../../icons/english.png";
import russianIcon from "../../../icons/rusian.png";
import tjkIcon from "../../../icons/tjk.png";
import { axiosRequest } from "../../../utils/axiosRequest";
import { wait } from "../../../utils/wait";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { handleLanguagesChange } from "../../../reducers/courses/courses";
import { deleteSallaries, getSallaries } from "../../../api/courses/courses";

const label = { inputProps: { "aria-label": "Switch demo" } };

function SalaryPerCompanies() {
  const [open, setOpen] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();
  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );
  const sallaries = useSelector(({ courses }) => courses.courses.sallaries);
  const { id } = useParams();
  const [value, setValue] = React.useState(
    dataLanguages.filter((elem) => elem.completed)[0]?.name
  );
  const [idx, setIdx] = useState(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      levelName: "",
      price: "",
      experience: 0,
    },

    validationSchema: Yup.object({
      levelName: Yup.string().max(255).required("levelName is required"),
    }),
    onSubmit: async (values, helpers) => {
      console.log(values);
      try {
        // NOTE: Make API request
        values.courseId = +id;
        values.language = value;
        values.price = +values.price;
        values.experience = +values.experience;
        const { data } = await axiosRequest.post("Salary/AddNewSalary", values);
        await wait(500);
        if (data?.code !== 200) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: "" });
          toast.error(
            `statusCode: ${data?.statusCode} errorMessage: ${data?.errors?.join(
              " "
            )}`
          );
        }
        if (data?.code === 200) {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          setAddModal(false);
          toast.success(data.message);
          getSallaries({ page: 1, Language: "rus" });
          setRefresh((prev) => !prev);
          values.levelName = "";
          values.price = "";
          values.experience = 0;
          setOpen(false);
        }
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong!");
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });
  const formik_update = useFormik({
    initialValues: {
      levelName: "",
      price: "",
      experience: 0,
    },

    validationSchema: Yup.object({
      levelName: Yup.string().max(255).required("levelName is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        values.courseId = +id;
        values.language = value;
        values.price = +values.price;
        values.experience = +values.experience;
        values.id = idx;
        const { data } = await axiosRequest.put("Salary/UpdateSalary", values);
        await wait(500);
        if (data?.code !== 200) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: "" });
          toast.error(
            `statusCode: ${data?.statusCode} errorMessage: ${data?.errors?.join(
              " "
            )}`
          );
        }
        if (data?.code === 200) {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          setAddModal(false);
          toast.success(data.message);
          dispatch(
            getSallaries({ Language: value ? value : "rus", CourseId: id })
          );
          setEditModal(false);
        }
        setOpen(false);
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong!");
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    dispatch(getSallaries({ Language: value ? value : "rus", CourseId: id }));
  }, [dispatch, refresh, value, id]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              sx={{ marginBottom: 2 }}
            >
              <Box sx={{ marginLeft: 2.5 }}>
                <Typography variant="h4">
                  <Button>
                    {" "}
                    <ArrowBackIcon
                      onClick={() => {
                        navigate(-1);
                      }}
                      sx={{ marginRight: "20px" }}
                    />
                  </Button>
                  Courses / Salary 
                </Typography>
              </Box>
              <Box>
                <Tabs
                  style={{
                    backgroundColor: "#C5C5C5",
                    width: "435px",
                    gap: "6px",
                    height: "52px",
                    padding: "5px",
                    borderRadius: "16px",
                  }}
                  value={value}
                  onChange={handleChange}
                  sx={{
                    "& .MuiTab-root": {
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#212121",
                      gap: "10px",
                      minWidth: 0,
                      minHeight: 0,
                      borderRadius: "12px",
                    },
                    "& button": { width: "106px", textAlign: "center" },
                    "& button:hover": { backgroundColor: "white" },
                    "& button:focus": { backgroundColor: "white" },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "white !important",
                    },
                  }}
                >
                  {dataLanguages
                    .filter((elem) => elem.completed)
                    .map((e) => {
                      return (
                        <Tab
                          key={e.id}
                          icon={<img src={e.avatar} alt="russianIcon" />}
                          iconPosition="start"
                          value={e.name.toLowerCase()}
                          label={e.name}
                        >
                          <span
                            style={{
                              width: "1px",
                              height: "24px",
                              border: "1px solid rgb(163 163 155)",
                              marginTop: "12px",
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          ></span>
                        </Tab>
                      );
                    })}
                  <Tab
                    onClick={() => handleClickOpen()}
                    icon={<img src={editIcon} alt="engIcon" />}
                    style={{
                      height: "41px",
                      width: "41px",
                      background: "#E6E6E6",
                      lineHeight: "24px",
                      marginLeft: "15px",
                    }}
                    iconPosition="start"
                  >
                    {" "}
                  </Tab>
                </Tabs>
              </Box>
            </Grid>
          </Box>
          <Grid
            container
            justifyContent="flex-end"
            sx={{ marginBottom: 3, marginTop: "50px" }}
          >
            <IconButton
              onClick={() => {
                if (!value) {
                  alert("Please select language");
                  return;
                } else {
                  setAddModal(true);
                }
              }}
              style={{
                background: "#506A85",
                color: "#fff",
                borderRadius: "16px",
                height: "48px",
                width: "150px",
              }}
            >
              <AddIcon />
              <Typography
                sx={{
                  ml: "5px",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontStyle: "normal",
                }}
              >
                Add new
              </Typography>
            </IconButton>
          </Grid>
          <Grid>
            <CustomTable headers={["Title", "Experience", "Price", "Action"]}>
              {sallaries?.length > 0 &&
                sallaries.map((elem) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{elem.levelName}</TableCell>
                      <TableCell align="center">
                        {elem.experience}year
                      </TableCell>
                      <TableCell align="center">{elem.price}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            if (!value) {
                              alert("Please select language");
                              return;
                            } else {
                              formik_update.setFieldValue(
                                "levelName",
                                elem.levelName
                              );
                              formik_update.setFieldValue(
                                "experience",
                                elem.experience
                              );
                              formik_update.setFieldValue("price", elem.price);
                              setEditModal(true);
                              setIdx(elem.id);
                            }
                          }}
                          sx={{
                            padding: "20px",
                            backgroundColor: "white",
                            borderRadius: "100%",
                          }}
                        >
                          <img src={editIcon} alt="edit" />
                        </IconButton>

                        <IconButton
                          onClick={async () => {
                            await dispatch(deleteSallaries(elem.id));
                            const updatedSallaries = await dispatch(
                              getSallaries({ page: 1, Language: "RUS" })
                            );
                            dispatch({
                              type: "SET_SALLARIES",
                              payload: updatedSallaries,
                            });
                          }}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </CustomTable>
          </Grid>
        </Container>
        <Dialog
          open={addModal}
          onClose={() => setAddModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ backgroundColor: "#E6E6E6" }}>
            <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%" }}>
                        <label style={{ marginBottom: "10px" }} for="levelName">
                          Level Name
                        </label>
                        <TextField
                          fullWidth
                          InputProps={{
                            inputProps: { style: { borderRadius: "16px" } },
                            style: { borderRadius: "16px" },
                          }}
                          name="levelName"
                          placeholder="Junior"
                          error={Boolean(
                            formik.touched.levelName && formik.errors.levelName
                          )}
                          helperText={
                            formik.touched.levelName && formik.errors.levelName
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.levelName}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <label style={{ marginBottom: "10px" }} for="Price">
                        Price
                      </label>
                      <TextField
                        fullWidth
                        InputProps={{
                          inputProps: { style: { borderRadius: "16px" } },
                          style: { borderRadius: "16px" },
                        }}
                        name="price"
                        placeholder="Price"
                        error={Boolean(
                          formik.touched.price && formik.errors.price
                        )}
                        helperText={formik.touched.price && formik.errors.price}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.price}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <label style={{ marginBottom: "10px" }} for="Experience">
                        Experience (year)
                      </label>
                      <TextField
                        fullWidth
                        InputProps={{
                          inputProps: { style: { borderRadius: "16px" } },
                          style: { borderRadius: "16px" },
                        }}
                        type="number"
                        name="experience"
                        placeholder="Experience"
                        error={Boolean(
                          formik.touched.experience && formik.errors.experience
                        )}
                        helperText={
                          formik.touched.experience && formik.errors.experience
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.experience}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Box
                container
                sx={{
                  flexWrap: "wrap",
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 1,
                }}
              >
                <Button
                  disabled={formik.isSubmitting}
                  type="submit"
                  sx={{ m: 1 }}
                  variant="contained"
                >
                  Add
                </Button>

                <Button
                  component="a"
                  disabled={formik.isSubmitting}
                  sx={{
                    m: 1,
                    mr: "auto",
                  }}
                  variant="outlined"
                  onClick={() => setAddModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
        <Dialog
          open={editModal}
          onClose={() => setEditModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ backgroundColor: "#E6E6E6" }}>
            <DialogTitle id="alert-dialog-title">{"Edit"}</DialogTitle>
            <form onSubmit={formik_update.handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%" }}>
                        <label style={{ marginBottom: "10px" }} for="levelName">
                          Level Name
                        </label>
                        <TextField
                          fullWidth
                          InputProps={{
                            inputProps: { style: { borderRadius: "16px" } },
                            style: { borderRadius: "16px" },
                          }}
                          name="levelName"
                          placeholder="Junior"
                          error={Boolean(
                            formik_update.touched.levelName &&
                              formik_update.errors.levelName
                          )}
                          helperText={
                            formik_update.touched.levelName &&
                            formik_update.errors.levelName
                          }
                          onBlur={formik_update.handleBlur}
                          onChange={formik_update.handleChange}
                          value={formik_update.values.levelName}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <label style={{ marginBottom: "10px" }} for="Price">
                        Price
                      </label>
                      <TextField
                        fullWidth
                        InputProps={{
                          inputProps: { style: { borderRadius: "16px" } },
                          style: { borderRadius: "16px" },
                        }}
                        name="price"
                        placeholder="Price"
                        error={Boolean(
                          formik_update.touched.price &&
                            formik_update.errors.price
                        )}
                        helperText={
                          formik_update.touched.price &&
                          formik_update.errors.price
                        }
                        onBlur={formik_update.handleBlur}
                        onChange={formik_update.handleChange}
                        value={formik_update.values.price}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <label style={{ marginBottom: "10px" }} for="Experience">
                        Experience (year)
                      </label>
                      <TextField
                        fullWidth
                        InputProps={{
                          inputProps: { style: { borderRadius: "16px" } },
                          style: { borderRadius: "16px" },
                        }}
                        type="number"
                        name="experience"
                        placeholder="Experience"
                        error={Boolean(
                          formik_update.touched.experience &&
                            formik_update.errors.experience
                        )}
                        helperText={
                          formik_update.touched.experience &&
                          formik_update.errors.experience
                        }
                        onBlur={formik_update.handleBlur}
                        onChange={formik_update.handleChange}
                        value={formik_update.values.experience}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Box
                container
                sx={{
                  flexWrap: "wrap",
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 1,
                }}
              >
                <Button
                  disabled={formik_update.isSubmitting}
                  type="submit"
                  sx={{ m: 1 }}
                  variant="contained"
                >
                  Edit
                </Button>

                <Button
                  component="a"
                  disabled={formik_update.isSubmitting}
                  sx={{
                    m: 1,
                    mr: "auto",
                  }}
                  variant="outlined"
                  onClick={() => setEditModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>

        {/* Modal for Languages */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
            <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

            <Card>
              <CardContent>
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 486,
                    bgcolor: "background.paper",
                  }}
                >
                  {dataLanguages.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                      <ListItem
                        key={value}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            checked={value.completed}
                            onClick={() => handleToggle(value.id)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemAvatar>
                            <Avatar alt={`Avatar`} src={value.avatar} />
                          </ListItemAvatar>

                          <ListItemText
                            id={labelId}
                            primary={` ${value.language}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </CardContent>
            </Card>
            <Box
              container
              sx={{
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button onClick={handleClose} sx={{ m: 1 }} variant="contained">
                Edit
              </Button>

              <Button
                sx={{
                  m: 1,
                  mr: "auto",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

export default SalaryPerCompanies;
