import { createSlice } from "@reduxjs/toolkit";
import { GetAllPosts, getPostById } from "../../api/posts/posts";
import { act } from "react-dom/test-utils";

export const slice = createSlice({
  name: "posts",
  initialState: {
    posts: [],
    postById: {},
    totalPages: 0,
  },
  reducers: {},
  extraReducers: {
    [GetAllPosts.pending]: (state) => {
      state.loading = false;
    },
    [GetAllPosts.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalPages = action.payload.totalPages;
      if (action.payload.data) {
        state.posts = action.payload.data;
      }
    },
    [GetAllPosts.rejected]: (state) => {
      state.loading = false;
    },
    [getPostById.pending]: (state, action) => {
      console.log(action);
      state.loading = false;
    },
    [getPostById.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action);
      if (action.payload.data) {
        state.postById = action.payload.data;
      }
    },
    [getPostById.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;
