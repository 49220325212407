import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import editIcon from "../../../icons/edit.svg";
import engIcon from "../../../icons/english.png";
import russianIcon from "../../../icons/rusian.png";
import tjkIcon from "../../../icons/tjk.png";
import { useDispatch } from "react-redux";
import { axiosRequest } from "../../../utils/axiosRequest";
import { da, id } from "date-fns/locale";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import { handleLanguagesChange } from "../../../reducers/courses/courses";
import {
  addSyllbus,
  deleteSyllabus,
  deleteSyllabusById,
  getSyllabus,
  putSyllabus,
} from "../../../api/courses/courses";
import toast, { Toast } from "react-hot-toast";

function Syllbus() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [arr, setArr] = useState([]);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [value, setValue] = useState("rus");
  const dispatch = useDispatch();

  const data = useSelector(({ courses }) => courses.courses.syllabus);

  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleChange = (event, newValue) => {
    console.log(event, newValue);
    setValue(newValue);
  };
  const handleRemove = (id) => {
    console.log(id);
    const arr1 = data.filter((elem) => elem.id !== id);
    setArr(arr1);
  };

  const [modal, setModal] = useState(false);

  const [language, setLanguage] = useState("");
  const [courseId, setCourseId] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [idx, setIdx] = useState(null);
  console.log(title);

  // const [url,setUrl]=useState('')

  const handleAdd = async () => {
    try {
      await dispatch(
        addSyllbus({
          courseId: id, // Предполагается, что id - строка; преобразуйте его в число при необходимости
          syllabuses: [
            {
              language: value,
              title: "string",
              description: "srtring",
              id: +Math.random(0, 1).toString().charAt(0),
            },
          ],
        })
      );
      dispatch(getSyllabus()).unwrap();
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getSyllabus({ Language: value, courseId: id }));
  }, [dispatch, value, id]);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ marginLeft: 2.5 }}>
            <Typography variant="h4">
              <Button>
                {" "}
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginRight: "20px" }}
                />
              </Button>
              New course / Syllabus
            </Typography>
            {/* <input type="file" onChange={(e)=>console.log(URL.createObjectURL(e.target.files[0]))}  /> */}
          </Box>
          <Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "380px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              <Tab
                icon={<img src={russianIcon} alt="russianIcon" />}
                iconPosition="start"
                label="RUS"
                value={"RUS"}
              />
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  border: "1px solid rgb(163 163 155)",
                  marginTop: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></span>
              <Tab
                icon={<img src={tjkIcon} alt="tjkIcon" />}
                iconPosition="start"
                label="TAJ"
                value={"TAJ"}
              />
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  border: "1px solid rgb(163 163 155)",
                  marginTop: "12px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></span>
              <Tab
                icon={<img src={engIcon} alt="engIcon" />}
                iconPosition="start"
                label="ENG"
                value="ENG"
              />
            </Tabs>
          </Box>
        </Grid>
        {data?.length > 0 &&
          data?.map((elem, i) => {
            console.log(data);
            return (
              <div
                key={elem.id}
                style={{
                  borderRadius: "20px",
                  position: "relative",
                }}
              >
                <IconButton
                  sx={{ position: "absolute", zIndex: 2, right: 10, top: 5 }}
                  onClick={async () => {
                    try {
                      await dispatch(
                        deleteSyllabusById({
                          id: elem.id,
                          obj: {
                            courseId: id,
                            Language: value,
                          },
                        })
                      ).unwrap();
                    } catch (error) {
                      console.error("Error deleting syllabus:", error);
                    }
                  }}
                >
                  <ClearIcon />
                </IconButton>
                <Accordion
                  expanded={expandedAccordion === elem.id}
                  onChange={() => {
                    setTitle(elem.title);
                    setDescription(elem.description);
                    setIdx(elem.id);
                    setExpandedAccordion(
                      expandedAccordion === elem.id ? null : elem.id
                    );
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <Typography
                          sx={{
                            marginRight: "13px",
                            fontWeight: 700,
                            fontSize: "20px",
                            lineHeight: "24px",
                          }}
                        >
                          Syllabus {i + 1}
                        </Typography>
                      </div>
                      <ExpandMoreIcon />
                    </div>
                  </AccordionSummary>

                  <AccordionDetails sx={{ rowGap: 4 }}>
                    <Box>
                      <Typography>Title</Typography>
                      <Box sx={{ mt: 1 }}>
                        <TextField
                          fullWidth
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          name="title"
                        />
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography>Description</Typography>
                      <Box sx={{ mt: 1 }}>
                        <TextField
                          fullWidth
                          value={description}
                          name="description"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}

        <Box
          sx={{
            width: "200px",
            height: "56px",
            borderRadius: "16px",
            border: "2px dashed #B1C4D7",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "40px",
            marginLeft: "40px",
          }}
        >
          <Button
            sx={{ width: "200px" }}
            onClick={() => {
              handleAdd();
            }}
          >
            <AddIcon fontSize="small" /> Add new
          </Button>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              width: "200px",
              height: "48px",
              borderRadius: "16px",
              backgroundColor: "#506A85",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "40px",
              marginLeft: "40px",
            }}
          >
            <Button
              onClick={() => {
                dispatch(
                  addSyllbus({
                    courseId: id,
                    syllabuses: [
                      {
                        language: value,
                        title: title,
                        description: description,
                        id: idx,
                      },
                    ],
                  })
                );
                getSyllabus({ language: value ? value : "rus" });
                setExpandedAccordion(null);
              }}
              sx={{ width: "200px", color: "white" }}
            >
              Add
            </Button>
          </Box>
          {modal ? (
            <div>
              <input
                type="text"
                name="courseId"
                value={courseId}
                onChange={(e) => setCourseId(Number(e.target.value))}
              />
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                type="text"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <button onClick={handleAdd}>Add</button>
              <button onClick={() => setModal(false)}>Cancel</button>
            </div>
          ) : null}
          <Box
            sx={{
              width: "200px",
              height: "48px",
              borderRadius: "16px",
              border: "2px solid #506A85",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "40px",
              marginLeft: "40px",
            }}
          >
            <Button sx={{ width: "200px" }}>Cancel</Button>
          </Box>
        </Box>
      </Container>
      {/* Modal for Languages */}
    </Box>
  );
}

export default Syllbus;
