import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Loading from "../../../components/custom/Loading";
import CustomTable from "../../../components/dashboard/courses/CustomTable";
import { useMounted } from "../../../hooks/use-mounted";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import editIcon from "../../../icons/edit.svg";
import engIcon from "../../../icons/english.png";
import russianIcon from "../../../icons/rusian.png";
import tjkIcon from "../../../icons/tjk.png";
import { useSelector } from "react-redux";
import { Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { deleteReviewer, getReviewers } from "../../../api/courses/courses";
import { setEditReviewer } from "../../../reducers/courses/courses";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Reviewers = () => {
  const reviewers = useSelector(({ courses }) => courses.courses.reviewers);
  console.log(reviewers);
  const isMounted = useMounted();
  const [isCard, setIsCard] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );
  const [value, setValue] = React.useState(dataLanguages[0]?.name);

  const handleChangeTab = (event, newValue) => {
    console.log(event, newValue);
    setValue(newValue);
    dispatch(
      getReviewers({
        Language:
          newValue == 0
            ? "RUS"
            : newValue == 1
            ? "TAJ"
            : newValue == 2
            ? "ENG"
            : "",
      })
    );
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(
    () => {
      window.scrollTo(0, 0);
      dispatch(
        getReviewers({
          Language: value,
          CourseId: id,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {isLoading ? (
        width >= 1200 ? (
          <Loading width={true} />
        ) : (
          <Loading width={false} />
        )
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              sx={{ marginBottom: 2 }}
            >
              <Box sx={{ marginLeft: 2.5 }}>
                <Typography variant="h4">
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ArrowBackIcon sx={{ marginRight: "20px" }} />
                  </Button>
                  New Courses / Reviewers
                </Typography>
              </Box>
              <Box>
                {/* Languages */}
                <Tabs
                  style={{
                    backgroundColor: "#C5C5C5",
                    width: "435px",
                    gap: "6px",
                    height: "52px",
                    padding: "5px",
                    borderRadius: "16px",
                  }}
                  value={value}
                  onChange={handleChangeTab}
                  sx={{
                    "& .MuiTab-root": {
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#212121",
                      gap: "10px",
                      minWidth: 0,
                      minHeight: 0,
                      borderRadius: "12px",
                    },
                    "& button": { width: "106px", textAlign: "center" },
                    "& button:hover": { backgroundColor: "white" },
                    "& button:focus": { backgroundColor: "white" },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "white !important",
                    },
                  }}
                >
                  <Tab
                    icon={<img src={russianIcon} alt="russianIcon" />}
                    iconPosition="start"
                    label="RUS"
                  />
                  <span
                    style={{
                      width: "1px",
                      height: "24px",
                      border: "1px solid rgb(163 163 155)",
                      marginTop: "12px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  ></span>
                  <Tab
                    icon={<img src={tjkIcon} alt="tjkIcon" />}
                    iconPosition="start"
                    label="TAJ"
                  />
                  <span
                    style={{
                      width: "1px",
                      height: "24px",
                      border: "1px solid rgb(163 163 155)",
                      marginTop: "12px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  ></span>
                  <Tab
                    icon={<img src={engIcon} alt="engIcon" />}
                    iconPosition="start"
                    label="ENG"
                  />
                  <span
                    style={{
                      width: "1px",
                      height: "24px",
                      border: "1px solid rgb(163 163 155)",
                      marginTop: "12px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  ></span>
                  <Tab
                    icon={<img src={editIcon} alt="engIcon" />}
                    style={{
                      height: "41px",
                      width: "41px",
                      background: "#E6E6E6",
                      lineHeight: "24px",
                    }}
                    iconPosition="start"
                  />
                </Tabs>
              </Box>
            </Grid>

            {/* + Add Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginY: "40px",
              }}
            >
              <IconButton
                onClick={() => {
                  navigate(`${pathname}/add`);
                }}
                style={{
                  background: "#506A85",
                  color: "#fff",
                  borderRadius: "16px",
                  height: "48px",
                  width: "150px",
                }}
              >
                <AddIcon />
                <Typography
                  sx={{
                    ml: "5px",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontStyle: "normal",
                  }}
                >
                  Add new
                </Typography>
              </IconButton>
            </Box>
          </Box>

          {/* Custom Table Component */}
          <CustomTable
            headers={[
              "Full name",
              "Position",
              "Work place",
              "Experience",
              "Action",
            ]}
          >
            {reviewers?.length > 0 &&
              reviewers.map((elem, i) => {
                let Name = elem.fullName.split(" ").join("AND");
                return (
                  <TableRow key={i}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {elem?.fullName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {elem?.position ? elem?.position : 0}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {elem?.workPlace ? elem?.workPlace : "unemployed"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {elem?.experience}
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        to={`${pathname}/${elem.id}/edit`}
                        component={RouterLink}
                      >
                        <IconButton
                          onClick={() => dispatch(setEditReviewer(elem))}
                          component="a"
                          sx={{
                            padding: "20px",
                            backgroundColor: "white",
                            borderRadius: "100%",
                          }}
                        >
                          <img src={editIcon} alt="edit" />
                        </IconButton>
                      </Link>

                      <IconButton
                        onClick={async () => {
                          try {
                            await dispatch(deleteReviewer(elem.id));

                            const { data: updatedReviewers } = await dispatch(
                              getReviewers({ Language: value })
                            );

                            dispatch({
                              type: "SET_REVIEWERS",
                              payload: updatedReviewers,
                            });
                          } catch (error) {
                            console.error("Error deleting reviewer:", error);
                          }
                        }}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </CustomTable>
        </Container>
      </Box>
    </>
  );
};

export default Reviewers;
