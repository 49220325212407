import axios from "axios";
import jwt_decode from "jwt-decode";
import { apiUrl } from "../config/config";

const axiosLogin = axios.create({
  baseURL: apiUrl,
});

const axiosRequest = axios.create({
  baseURL: apiUrl,
});

axiosRequest.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "access_token"
  )}`;
  return config;
});

axiosRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location = "/";
    }
  }
);

function saveToken(access_token, refresh_token) {
  localStorage.setItem("access_token", access_token);
}

function getToken() {
  try {
    return jwt_decode(localStorage.getItem("access_token"));
  } catch (error) {}
}

function destroyToken() {
  localStorage.removeItem("access_token");
}
export { axiosLogin, axiosRequest, getToken, saveToken, destroyToken };
