import { combineReducers } from "@reduxjs/toolkit";
import courses from "./courses";
import posts from "./posts";
import students from "./students/students";
import translate from "./translate";

const rootReducer = combineReducers({
  courses: courses,
  posts: posts,
  students: students,
  translate:translate,
});

export default rootReducer;
