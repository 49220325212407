import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller } from "react-hook-form";

export const FormInputDate = ({ name, control, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => (
        <DatePicker
          // inputFormat="dd.MM.yyyy"
          {...field}
          required
          renderInput={(inputProps) => <TextField fullWidth {...inputProps} />}
        />
      )}
    />
  );
};
