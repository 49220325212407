import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Divider from "@mui/material/Divider";
import { PencilAlt as PencilAltIcon } from "../../../icons/pencil-alt";

import {
  TableCell,
  TableRow,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Pagination,
  Tabs,
  Tab,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import CourseListCard from "../../../components/dashboard/courses/course-list-card";
import { CourseListTable } from "../../../components/dashboard/courses/course-list-table";
import { useMounted } from "../../../hooks/use-mounted";
import { Plus as PlusIcon } from "../../../icons/plus";
import { Search as SearchIcon } from "../../../icons/search";
import { axiosRequest } from "../../../utils/axiosRequest";
import { wait } from "../../../utils/wait";
import Switch from "@mui/material/Switch";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Loading from "../../../components/custom/Loading";
import AddIcon from "@mui/icons-material/Add";
import { borderRadius } from "@mui/system";
import {
  Link as RouterLink,
  Navigate,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { getALLCourses, getCourseById } from "../../../api/courses/courses";
import { useSelector } from "react-redux";
import CustomTable from "../../../components/dashboard/courses/CustomTable";
import CustomDropDown from "../../../components/dashboard/courses/CustomDropDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { number } from "prop-types";
import { apiAssetsUrl } from "../../../config/config";
import engIcon from "../../../icons/english.png";
import fromList from "../../../icons/formList.png";
import russianIcon from "../../../icons/rusian.png";
import tjkIcon from "../../../icons/tjk.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import {
  handleLanguagesChange,
  reset,
} from "../../../reducers/courses/courses";

const label = { inputProps: { "aria-label": "Switch demo" } };
const Course = () => {
  const dispatch = useDispatch();
  const courses = useSelector(({ courses }) => courses.courses.courses);
  const totalPages = useSelector(({ courses }) => courses.courses.totalPages);

  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );
  // const [courses, setCourses] = useState([]);
  const isMounted = useMounted();
  const [isCard, setIsCard] = useState(true);
  const [value, setValue] = React.useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [idx, setIdx] = useState(null);
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [lang, setLang] = useState("");
  const { width } = useWindowDimensions();
  console.log(lang);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
    dispatch(
      getCourseById({
        CourseId: idx.id,
        Language: lang,
      })
    );
  }

  const handleChangeTab = (event, newValue) => {
    console.log(event, newValue);
    setValue(newValue);
  };
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  useEffect(
    () => {
      window.scrollTo(0, 0);
      dispatch(reset());
      // getCourses();

      dispatch(
        getALLCourses({
          Name: "",
          Language: value ? value : "rus",
          TeachingFormat: "",
          DurationType: "",
          Enabled: "",
          PageNumber: page === 0 ? 1 : page,
          PageSize: 10,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, rowsPerPage, value]
  );

  return (
    <>
      {isLoading ? (
        width >= 1200 ? (
          <Loading width={true} />
        ) : (
          <Loading width={false} />
        )
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              sx={{ marginBottom: 3 }}
            >
              <Grid sx={{ marginLeft: 2.5 }}>
                <Typography variant="h4">Courses</Typography>
              </Grid>
              <Tabs
                style={{
                  backgroundColor: "#C5C5C5",
                  width: "380px",
                  gap: "6px",
                  height: "52px",
                  padding: "5px",
                  borderRadius: "16px",
                }}
                value={value}
                onChange={handleChangeTab}
                sx={{
                  "& .MuiTab-root": {
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#212121",
                    gap: "10px",
                    minWidth: 0,
                    minHeight: 0,
                    borderRadius: "12px",
                  },
                  "& button": { width: "106px", textAlign: "center" },
                  "& button:hover": { backgroundColor: "white" },
                  "& button:focus": { backgroundColor: "white" },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "transparent",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "white !important",
                  },
                }}
              >
                <Tab
                  icon={<img src={russianIcon} alt="russianIcon" />}
                  iconPosition="start"
                  label="RUS"
                  value={"RUS"}
                />
                <span
                  style={{
                    width: "1px",
                    height: "24px",
                    border: "1px solid rgb(163 163 155)",
                    marginTop: "12px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                ></span>
                <Tab
                  icon={<img src={tjkIcon} alt="tjkIcon" />}
                  iconPosition="start"
                  label="TAJ"
                  value={"TAJ"}
                />
                <span
                  style={{
                    width: "1px",
                    height: "24px",
                    border: "1px solid rgb(163 163 155)",
                    marginTop: "12px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                ></span>
                <Tab
                  icon={<img src={engIcon} alt="engIcon" />}
                  iconPosition="start"
                  label="ENG"
                  value="ENG"
                />
              </Tabs>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "45px" }}>
                <Box
                  sx={{
                    width: "216px",
                    height: "48px",
                    background: "#D4D4D4",
                    borderRadius: "16px",
                    padding: "4px",
                  }}
                >
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled elevation buttons"
                  >
                    <IconButton
                      sx={
                        isCard === true
                          ? { color: "#212121", background: "#fff" }
                          : {}
                      }
                      onClick={() => setIsCard(true)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "14px",
                          marginX: "10px",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <FormatListBulletedIcon />
                        <Typography>List</Typography>
                      </Box>
                    </IconButton>
                    <IconButton
                      sx={
                        isCard === false
                          ? { color: "#212121", background: "#fff" }
                          : {}
                      }
                      onClick={() => setIsCard(false)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "14px",
                          marginX: "10px",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <AppsIcon />
                        <Typography>Grid</Typography>
                      </Box>
                    </IconButton>
                  </ButtonGroup>
                </Box>

                <Box>
                  <IconButton
                    onClick={handleClickOpen}
                    style={{
                      background: "#506A85",
                      color: "#fff",
                      borderRadius: "16px",

                      height: "48px",
                      width: "150px",
                    }}
                  >
                    <AddIcon />
                    <Typography
                      sx={{
                        ml: "5px",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontStyle: "normal",
                      }}
                    >
                      Add new
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Box>
          {isCard && (
            <CustomTable
              headers={[
                "Title",
                "Price",
                "Duration",
                "Duration type",
                "Logo small",
                "Action",
              ]}
            >
              {courses.length > 0 &&
                courses.map((elem) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ textAlign: "center", fontSize: "16px" }}>
                        {elem?.title}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", fontSize: "16px" }}>
                        {elem?.price}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", fontSize: "16px" }}>
                        {elem?.duration}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", fontSize: "16px" }}>
                        {elem?.durationType}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <img
                          style={{
                            width: "58px",
                            height: "58px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          src={`${process.env.REACT_APP_ASSETS_URL}${elem?.logo}`}
                          alt="smallLogo"
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              handleClickOpen2();
                              setIdx(elem);
                            }}
                            component="a"
                          >
                            <PencilAltIcon />
                          </IconButton>

                          <CustomDropDown
                            values={{
                              PageNumber: page === 0 ? 1 : page,
                              PageSize: 10,
                              Language: value ? value : "rus",
                            }}
                            id={elem.id}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </CustomTable>
          )}
          {isCard === false && (
            <CourseListCard
              courses={courses}
              coursesCount={courses.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          )}
        </Container>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "end",
            mr: "20px",
            pb: "30px",
          }}
        >
          <Pagination
            color="primary"
            page={page}
            count={totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
          />
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                {dataLanguages.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          checked={value.completed}
                          edge="end"
                          onClick={() => handleToggle(value.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt={`Avatar`} src={value.avatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={` ${value.language}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              onClick={() => navigate(`${pathname}/add`)}
              sx={{ m: 1 }}
              variant="contained"
            >
              Add
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Edit"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                {dataLanguages.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          checked={value.completed}
                          edge="end"
                          onClick={() => {
                            handleToggle(value.id);
                            setLang(value.name);
                          }}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt={`Avatar`} src={value.avatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={` ${value.language}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              onClick={() => navigate(`${pathname}/${idx.id}/edit`)}
              sx={{ m: 1 }}
              variant="contained"
            >
              Add
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={handleClose2}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Course;
