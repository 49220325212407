import React, { useCallback, useEffect, useState } from "react";
import { useMounted } from "../../hooks/use-mounted";
import { axiosRequest } from "../../utils/axiosRequest";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Loading from "../../components/custom/Loading";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import axios from "axios";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

// import useWindowDimensions from "../../../hooks/useWindowDimensions";
// import Loading from "../../../components/custom/Loading";

let arr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function Overview() {
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [statistic, setStatistic] = useState([]);
  const isMounted = useMounted();

  const getStatistics = useCallback(async () => {
    try {
      const { data } = await axiosRequest.get(`Statistics`)

      if (isMounted()) {
        let arr1 = [];
        const { statisticStudents, ...other } = data;
        setStats(other);
        statisticStudents.forEach((element) => {
          let obj = {
            name: arr[element.month - 1],
            students: element.countOfStudents,
          };
          arr1.push(obj);
        });
        console.log(arr1);
        setStatistic(arr1);
      }
    } catch (err) {
      console.log(err)
    }
  }, [isMounted]);

  useEffect(() => {
    window.scrollTo(0, 0);

    getStatistics();
  }, []);

  const handleDismissBanner = () => {
    // Update the persistent state
    // globalThis.sessionStorage.setItem('dismiss-banner', 'true');
  };
  // console.log(statistic);
  return (
    <>
      {isLoading ? (
        width >= 1200 ? (
          <Loading width={true} />
        ) : (
          <Loading width={false} />
        )
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={2} style={{ marginBottom: 40 }}>
            <Grid item md={4} lg={4} sm={12} xs={12}>
              <Card sx={{ height: 100, padding: 0, borderRadius: "12px" }}>
                <CardContent sx={{ padding: 2 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "700",
                          fontSize: "13px",
                          lineHeight: "24px",
                        }}
                        component="p"
                      >
                        Students
                      </Typography>
                      <span
                        style={{
                          alignSelf: "center",
                          fontWeight: 700,
                          fontSize: 28,
                        }}
                      >
                        {stats?.countOfStudents}
                      </span>
                    </div>
                    <CircularProgress
                                        thickness={8}
                                        size={60}
                      variant="determinate"
                      sx={{ color: "#39A0CC", alignSelf: "center" }}
                      value={stats?.countOfStudents}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} lg={4} sm={12} xs={12}>
              <Card sx={{ height: 100, padding: 0, borderRadius: "12px" }}>
                <CardContent sx={{ padding: 2 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "24px",
                        }}
                        component="p"
                      >
                        Course
                      </Typography>
                      <span
                        style={{
                          alignSelf: "center",
                          fontWeight: 700,
                          fontSize: 28,
                        }}
                      >
                        {stats?.countOfCourses}
                      </span>
                    </div>
                    <CircularProgress
                    thickness={8}
                    size={60}
                      variant="determinate"
                      sx={{ color: "#FD8539", alignSelf: "center" }}
                      value={stats?.countOfCourses}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} lg={4} sm={12} xs={12}>
              <Card sx={{ height: 100, padding: 0, borderRadius: "12px" }}>
                <CardContent sx={{ padding: 2 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "700",
                          fontSize: "13px",
                          lineHeight: "24px",
                        }}
                        component="p"
                      >
                        Posts
                      </Typography>
                      <span
                        style={{
                          alignSelf: "center",
                          fontWeight: 700,
                          fontSize: 28,
                        }}
                      >
                        {stats?.countOfPosts}
                      </span>
                    </div>
                    <CircularProgress
                      thickness={8}
                      size={60}
                      variant="determinate"
                      sx={{ color: "#2ED480", alignSelf: "center" }}
                      value={stats?.countOfPosts}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Paper sx={{ padding: "30px 0px", marginBottom: "20px" }}>
            <Typography
              variant="h4"
              sx={{ marginBottom: "10px", textAlign: "center" }}
            >
              Статистика
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                {statistic.length > 0 && (
                  <ResponsiveContainer width="100%" height={250}>
                    <AreaChart
                      width={500}
                      height={400}
                      data={statistic}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="students"
                        stroke="#39A0CC"
                        fill="#E0F6FF"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default Overview;
