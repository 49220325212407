import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "./axiosRequest";

const ProtectedRout = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const user = getToken();
  console.log(user);
  useEffect(() => {
    if (!token || !user) {
      return navigate("/");
    }
  }, [navigate, token,user]);

  return props.children;
};

export default ProtectedRout;
