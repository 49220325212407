import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React from "react";
  
  const CustomTable = (props) => {
    const { headers } = props;
    return (
      <Table
        sx={{
          // minWidth: 360,
          width: "100%",
          borderRadius: "12px",

          overflow: "hidden",
          borderCollapse: "separate",
          '& .MuiTableCell-root:first-child': {
            
            textAlign:"start !important"
          },            
        }}
      >
        <TableHead
          sx={{
            padding: "10px",
          }}
        >
          <TableRow sx={{ backgroundColor: "#D4D4D4", padding: "0px 0px 0px 20px" }}>
            {headers.map((elem) => {
              return (
                <TableCell
                  style={{
                    // alignItems : "center",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "13px",
                    lineHeight: "20px", 
                    textAlign:"center",
                  }} 
                >
                  {elem} 
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody >{props.children}</TableBody>
      </Table>
    );
  };
  
  export default CustomTable;
  