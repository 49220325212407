import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addCourseTutors,
  deleteTutors,
  editUser,
  getAllTutors,
  getCourseById,
} from "../../../api/courses/courses";
import Loading from "../../../components/custom/Loading";
import CustomTable from "../../../components/dashboard/courses/CustomTable";
import Temporary from "../../../components/dashboard/student/Temporary.jsx";
import { useMounted } from "../../../hooks/use-mounted";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import delet from "../../../icons/delete.svg";
import engIcon from "../../../icons/english.png";
import { PencilAlt as PencilAltIcon } from "../../../icons/pencil-alt.js";
import russianIcon from "../../../icons/rusian.png";
import tjkIcon from "../../../icons/tjk.png";
import {
  handleLanguagesChange,
  setEditTutors,
} from "../../../reducers/courses/courses.js";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Tutors = () => {
  // const [courses, setCourses] = useState([]);
  const tutors = useSelector(({ courses }) => courses.courses.tutors);
  const course = useSelector(({ courses }) => courses.courses.course);
  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );
  const editTutors = useSelector(({ courses }) => courses.courses.editTutors);
  const isMounted = useMounted();
  const [isCard, setIsCard] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [value, setValue] = React.useState();
  const [addTutor, setAddTutor] = useState("");
  const dispatch = useDispatch();
  const [arr, setArr] = useState({});
  const [open2, setOpen2] = useState(false);
  // const tutors = useSelector((state))

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  ////////Temperory.jsx

  const [student, setStudent] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const { id } = useParams();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [idx, setIdx] = useState(false);
  const [modal, setModal] = useState(false);
  const [text1, setText1] = useState("");
  // const [text2,setText2] = useState("")
  const [text3, setText3] = useState("");
  const [text4, setText4] = useState("");
  const [text5, setText5] = useState("");
  const [text6, setText6] = useState("");

  // const dispatch = useDispatch()

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  useEffect(() => {
    setArr({
      courseId: +id,
      teacherId: +addTutor,
    });
  }, [id, addTutor]);
  useEffect(() => {
    dispatch(getCourseById({ CourseId: id, Language: value ? value : "rus" }));
    dispatch(getAllTutors({ Language: value ? value : "rus" }));
  }, [dispatch, value, id]);

  return (
    <>
      {isLoading ? (
        width >= 1200 ? (
          <Loading width={true} />
        ) : (
          <Loading width={false} />
        )
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              sx={{ marginBottom: 2 }}
            >
              <Box sx={{ marginLeft: 2.5 }}>
                <Typography variant="h4">
                  <Button>
                    {" "}
                    <ArrowBackIcon
                      onClick={() => {
                        navigate(-1);
                      }}
                      sx={{ marginRight: "20px" }}
                    />
                  </Button>
                  {id === ":id" ? "Tutors" : "Courses / Tutors"}
                </Typography>
              </Box>
              <Box>
                {/* ///////////////   LANGUGES  /////////////////////// */}

                <Tabs
                  style={{
                    backgroundColor: "#C5C5C5",
                    width: "380px",
                    gap: "6px",
                    height: "52px",
                    padding: "5px",
                    borderRadius: "16px",
                  }}
                  value={value}
                  onChange={handleChangeTab}
                  sx={{
                    "& .MuiTab-root": {
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#212121",
                      gap: "10px",
                      minWidth: 0,
                      minHeight: 0,
                      borderRadius: "12px",
                    },
                    "& button": { width: "106px", textAlign: "center" },
                    "& button:hover": { backgroundColor: "white" },
                    "& button:focus": { backgroundColor: "white" },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "white !important",
                    },
                  }}
                >
                  <Tab
                    icon={<img src={russianIcon} alt="russianIcon" />}
                    iconPosition="start"
                    label="RUS"
                    value={"RUS"}
                  />
                  <span
                    style={{
                      width: "1px",
                      height: "24px",
                      border: "1px solid rgb(163 163 155)",
                      marginTop: "12px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  ></span>
                  <Tab
                    icon={<img src={tjkIcon} alt="tjkIcon" />}
                    iconPosition="start"
                    label="TAJ"
                    value={"TAJ"}
                  />
                  <span
                    style={{
                      width: "1px",
                      height: "24px",
                      border: "1px solid rgb(163 163 155)",
                      marginTop: "12px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  ></span>
                  <Tab
                    icon={<img src={engIcon} alt="engIcon" />}
                    iconPosition="start"
                    label="ENG"
                    value="ENG"
                  />
                </Tabs>
              </Box>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              justifyContent="flex-end"
              sx={{ marginBottom: "40px", marginTop: "50px" }}
            >
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Box>
                  {id !== ":id" ? (
                    <IconButton
                      onClick={() => {
                        handleClickOpen();
                      }}
                      style={{
                        background: "#506A85",
                        color: "#fff",
                        borderRadius: "16px",
                        height: "48px",
                        width: "150px",
                      }}
                    >
                      <AddIcon />
                      <Typography
                        sx={{
                          ml: "5px",
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontStyle: "normal",
                        }}
                      >
                        Add new
                      </Typography>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        // navigate(`${pathname}/addtutor`);
                        handleClickOpen2();
                      }}
                      style={{
                        background: "#506A85",
                        color: "#fff",
                        borderRadius: "16px",
                        height: "48px",
                        width: "150px",
                      }}
                    >
                      <AddIcon />
                      <Typography
                        sx={{
                          ml: "5px",
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontStyle: "normal",
                        }}
                      >
                        Add new
                      </Typography>
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
          {/* Custom Table Component */}
          <CustomTable
            headers={[
              "Full name",
              "Position",
              "Work place",
              "Experience",
              "Action",
            ]}
          >
            {id === ":id"
              ? tutors?.length > 0 &&
                tutors?.map((elem, index) => (
                  <TableRow>
                    <TableCell
                      onClick={() => {
                        setStudent(elem);
                        setDrawerOpen(true);
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      {index + 1}. {elem?.fullName}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        setStudent(elem);
                        setDrawerOpen(true);
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      {elem?.position}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        setStudent(elem);
                        setDrawerOpen(true);
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      {elem?.workplace}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        setStudent(elem);
                        setDrawerOpen(true);
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      {elem?.experience}year
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        onClick={() => {
                          dispatch(setEditTutors(elem));
                          navigate(`${pathname}/${elem.id}/edit`);
                        }}
                        sx={{ padding: 0 }}
                      >
                        {" "}
                        <PencilAltIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(deleteTutors(elem.id));
                          dispatch(
                            getAllTutors({ Language: value ? value : "rus" })
                          );
                        }}
                      >
                        <img src={delet} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : course?.teachers?.length > 0 &&
                course?.teachers?.map((elem, index) => (
                  <TableRow>
                    <TableCell
                      onClick={() => {
                        setStudent(elem);
                        setDrawerOpen(true);
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      {index + 1}. {elem?.fullName}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        setStudent(elem);
                        setDrawerOpen(true);
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      {elem?.position}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        setStudent(elem);
                        setDrawerOpen(true);
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      {elem?.workplace}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        setStudent(elem);
                        setDrawerOpen(true);
                      }}
                      sx={{ textAlign: "center" }}
                    >
                      {elem?.experience}year
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        onClick={() => {
                          dispatch(setEditTutors(elem));
                          navigate(`${pathname}/${elem.id}/edit`);
                        }}
                        sx={{ padding: 0 }}
                      >
                        {" "}
                        <PencilAltIcon />
                      </Button>
                      <Button
                        onClick={async () => {
                          await dispatch(
                            deleteTutors({
                              courseId: +id,
                              teacherId: elem.id,
                            })
                          );
                          dispatch(
                            getCourseById({
                              CourseId: id,
                              Language: value ? value : "rus",
                            })
                          );
                        }}
                      >
                        <img src={delet} alt="delete" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </CustomTable>
        </Container>
        <Dialog open={modal}>
          <input
            value={text1}
            onChange={(e) => setText1(e.target.value)}
            type="text"
            name=""
            id=""
          />
          {/* <input value={text2} onChange={(e)=>setText2(e.target.value)} type="text" name="" id="" /> */}
          <input
            value={text3}
            onChange={(e) => setText3(e.target.value)}
            type="text"
            name=""
            id=""
          />
          <input
            value={text4}
            onChange={(e) => setText4(e.target.value)}
            type="text"
            name=""
            id=""
          />
          <input
            value={text5}
            onChange={(e) => setText5(e.target.value)}
            type="text"
            name=""
            id=""
          />
          <input
            value={text6}
            onChange={(e) => setText6(e.target.value)}
            type="text"
            name=""
            id=""
          />
          <button
            onClick={() =>
              dispatch(
                editUser({
                  id: idx,
                  fullName: text1,
                  position: text3,
                  workplace: text4,
                  experience: text5,
                  education: text6,
                })
              )
            }
          >
            Submit
          </button>
        </Dialog>
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "550px", // Set your width here
              },
            },
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, fontFamily: "Montserrat" }}
            >
              Add Tutor
            </Typography>
            <IconButton aria-label="Close" onClick={handleClose}>
              &times;
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{ marginY: 4, paddingTop: "10px", height: "200px" }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={tutors}
              // value={formik.values.employeeId}
              // onChange={(event, newValue) => {
              //     formik.setFieldValue('employeeId', option?.id);
              // }}

              // sx={{ height: "48px" }}
              getOptionLabel={(option) => option.fullName}
              onChange={(e, value) => {
                setAddTutor(value?.id);
                // formik.setFieldValue('employeeId', value?.id)
              }}
              renderInput={(params) => (
                <TextField
                  label="Tutors"
                  id="outlined-required"
                  size="medium"
                  defaultValue="Hello World"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      // borderRadius: 20,
                      background: "#FAFAFA",
                      padding: "7px",
                      // height: "48px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 16px",
                    },
                  }}
                  {...params}
                />
              )}
            />
            <Button
              sx={{ m: 1 }}
              variant="contained"
              onClick={async () => {
                await dispatch(addCourseTutors(arr));
                dispatch(
                  getCourseById({
                    CourseId: id,
                    Language: value ? value : "rus",
                  })
                );

                setOpen(false);
              }}
            >
              add
            </Button>
          </DialogContent>
        </Dialog>
      </Box>
      <Temporary
        elem={student}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                {dataLanguages.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          checked={value.completed}
                          edge="end"
                          onClick={() => handleToggle(value.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt={`Avatar`} src={value.avatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={` ${value.language}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button
              onClick={() => navigate(`${pathname}/addtutor`)}
              sx={{ m: 1 }}
              variant="contained"
            >
              Add
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Tutors;
