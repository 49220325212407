import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Typography,
  TableCell,
  TableRow,
  MenuItem,
  TextField,
  InputAdornment,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  Pagination,
} from "@mui/material";
import CustomTable from "../../../components/dashboard/courses/CustomTable";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import { Search as SearchIcon } from "../../../icons/search";
import { pink } from "@mui/material/colors";
import nextIcon from "../../../icons/next.png";
import "@uiw/react-markdown-preview/markdown.css";
import "@uiw/react-md-editor/markdown-editor.css";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "../../../components/modal/Modal";
import { PencilAlt as PencilAltIcon } from "../../../icons/pencil-alt";
import { Plus as PlusIcon } from "../../../icons/plus";
import Vector from "../../../icons/Vector.png";
import importLogo from "../../../icons/import.png";
// import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { axiosRequest } from "../../../utils/axiosRequest";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Loading from "../../../components/custom/Loading";
import { apiAssetsUrl } from "../../../config/config";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { height } from "@mui/system";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PostListCard from "../../../components/dashboard/Posts/postsCard";
import { FormInputText } from "../../../components/custom/form-components/FormInputText";
import { useDispatch } from "react-redux";
import {
  GetAllUITranslations,
  deleteTranslation,
  postTranslation,
} from "../../../api/Translate/translate";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { data } from "autoprefixer";
const defaultValues = {
  group: "",
  key: "",
  translations: [],
};

function Translation() {
  const [value, setValue] = useState("");
  const [isCard, setIsCard] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  // const { id } = useParams();
  const [idx, setId] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  // modal
  const [isOpen, setIsOpen] = useState(false);
  const [age, setAge] = useState("Status");
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    reset,
    register,
    watch,
    control,
    setValue: setFormValue,
  } = methods;

  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    let obj = {
      group: values.group.trim(),
      key: values.key.trim(),
      translations: [
        {
          key: "rus",
          value: values.rus,
        },
        {
          key: "eng",
          value: values.eng,
        },
        {
          key: "taj",
          value: values.taj,
        },
      ],
    };
    dispatch(
      postTranslation({
        values: obj,
        callback: () => {
          setOpen(false);
          reset({
            group: "",
            key: "",
            rus: "",
            eng: "",
            taj: "",
          });
        },
      })
    );
  };
  const onSubmit2 = async (values) => {
    let obj = {
      id: idx,
      group: values.group,
      key: values.key,
      translations: [
        {
          key: "rus",
          value: values.rus,
        },
        {
          key: "eng",
          value: values.eng,
        },
        {
          key: "taj",
          value: values.taj,
        },
      ],
    };
    dispatch(
      postTranslation({
        values: obj,
        callback: () => {
          setOpen(false);
          reset({
            group: "",
            key: "",
            rus: "",
            eng: "",
            taj: "",
          });
        },
      })
    );
  };

  const translate = useSelector(
    ({ translate }) => translate.translate.translate
  );
  const totalPages = useSelector(
    ({ translate }) => translate.translate.totalPages
  );

  const handleClickOpen = () => {
    setOpen(true);
    reset({
      group: "",
      key: "",
      rus: "",
      eng: "",
      taj: "",
    });
  };
  const handleEdit = (translationData) => {
    setId(translationData.id);
    reset({
      group: translationData.group,
      key: translationData.key,
      rus: translationData.translations.find((t) => t.key === "rus").value,
      eng: translationData.translations.find((t) => t.key === "eng").value,
      taj: translationData.translations.find((t) => t.key === "taj").value,
    });
    // Additional logic or state updates if needed
    setDrawerOpen(true); // Open the edit modal
    // Additional logic or state updates if needed
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setDrawerOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const HandleDialogOpen = () => {
    setIsOpen(true);
  };
  const HandleDialogClose = () => {
    setIsOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    dispatch(
      GetAllUITranslations({
        Group: "",
        Key: "",
        Language: "",
        PageNumber: page === 0 ? 1 : page,
        PageSize: 10,
      })
    );
  }, [dispatch, page]);

  return (
    <>
      {isLoading ? (
        width >= 1200 ? (
          <Loading width={true} />
        ) : (
          <Loading width={false} />
        )
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          px: 2,
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 4 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item sx={{ marginTo: 0 }}>
                <Typography variant="h4" sx={{ fontSize: "24px" }}>
                  Translation
                </Typography>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  justifyContent="space-between"
                  gap="14px"
                  display="flex"
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      width: "131px",
                      height: "48px",
                      background: "#D4D4D4",
                      borderRadius: "16px",
                      padding: "4px",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Vector}
                      style={{ width: "15px", height: "15px" }}
                    />
                    <Typography>Export</Typography>
                  </Box>
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      width: "131px",
                      height: "48px",
                      background: "#D4D4D4",
                      borderRadius: "16px",
                      padding: "4px",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={importLogo}
                      style={{ width: "15px", height: "15px" }}
                    />
                    <Typography>Import</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <IconButton
                      onClick={handleClickOpen}
                      style={{
                        background: "#506A85",
                        color: "#fff",
                        borderRadius: "16px",

                        height: "48px",
                        width: "150px",
                      }}
                    >
                      <AddIcon />
                      <Typography
                        sx={{
                          ml: "5px",
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontStyle: "normal",
                        }}
                      >
                        Add new
                      </Typography>
                    </IconButton>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "40px",
            }}
          >
            <Box
              component="form"
              // onSubmit={handleQueryChange}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                gap: "14px",
                flexWrap: "wrap",
              }}
            >
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                defaultValue=""
                name="firstName"
                size="small"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "10px !important",
                  border: "2px solid #B1C4D7",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />

              <Box
                sx={{
                  display: "flex",
                  width: "150px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Show</Typography>
                <Box
                  sx={{
                    backgroundColor: "white",
                    width: "48px",
                    height: "48px",
                    border: "2px solid #B1C4D7",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {translate.length}
                </Box>
                <Typography>entries</Typography>
              </Box>
            </Box>
          </Box>
          {/* Table  */}

          <CustomTable
            headers={["Group", "Key", "Русский", "English", "Точики", "   "]}
          >
            {translate.length > 0 &&
              translate
                .filter((elem) => {
                  return elem.group
                    .toLowerCase()
                    .trim()
                    .includes(search.toLowerCase());
                })
                .map((elem, index) => {
                  return (
                    <TableRow
                      hover={true}
                      onClick={() => {
                        handleEdit(elem);
                      }}
                    >
                      <TableCell>
                        {index + 1}.{elem?.group}
                      </TableCell>
                      <TableCell sx={{ width: "250px", textAlign: "center" }}>
                        {elem?.key}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {elem?.translations
                          ?.find((e) => e.key == "rus")
                          ?.value.slice(0, 50)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {elem?.translations
                          ?.find((e) => e.key == "eng")
                          ?.value.slice(0, 50)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {elem?.translations
                          ?.find((e) => e.key == "taj")
                          ?.value.slice(0, 50)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}></TableCell>
                    </TableRow>
                  );
                })}
          </CustomTable>
        </Container>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "end",
            mr: "20px",
            pb: "30px",
          }}
        >
          <Pagination
            color="primary"
            page={page}
            count={totalPages}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
          />
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">
            {"Add new translation"}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardContent>
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 486,
                    bgcolor: "background.paper",
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="group">
                      Group
                    </label>
                    <FormInputText name="group" control={control} />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="key">
                      Key
                    </label>
                    <FormInputText name="key" control={control} />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="Russian">
                      Русский
                    </label>
                    <FormInputText name="rus" control={control} />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="English">
                      English
                    </label>
                    <FormInputText name="eng" control={control} />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="Tajik">
                      Точики
                    </label>
                    <FormInputText name="taj" control={control} />
                  </Box>
                </List>
              </CardContent>
            </Card>
            <Box
              container
              sx={{
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                type="submit"
                sx={{ m: 1 }}
                variant="contained"
                onClick={handleClose}
              >
                Add
              </Button>

              <Button
                sx={{
                  m: 1,
                  mr: "auto",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={drawerOpen}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">
            {"Edit translation"}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit2)}>
            <Card>
              <CardContent>
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 486,
                    bgcolor: "background.paper",
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="group">
                      Group
                    </label>
                    <FormInputText
                      disabled={true}
                      name="group"
                      control={control}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="key">
                      Key
                    </label>
                    <FormInputText
                      disabled={true}
                      name="key"
                      control={control}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="Russian">
                      Русский
                    </label>
                    <FormInputText name="rus" control={control} />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="English">
                      English
                    </label>
                    <FormInputText name="eng" control={control} />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <label style={{ marginLeft: "5px" }} for="Tajik">
                      Точики
                    </label>
                    <FormInputText name="taj" control={control} />
                  </Box>
                </List>
              </CardContent>
            </Card>
            <Box
              container
              sx={{
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Box>
                <Button
                  type="submit"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleClose2}
                >
                  Save
                </Button>

                <Button
                  sx={{
                    m: 1,
                    mr: "auto",
                  }}
                  variant="outlined"
                  onClick={handleClose2}
                >
                  Cancel
                </Button>
              </Box>
              {/* <Box>
                <IconButton
                  onClick={() => {
                    dispatch(deleteTranslation(idx));
                    setDrawerOpen(false);
                  }}
                >
                  <DeleteOutlinedIcon color="error" />
                </IconButton>
              </Box> */}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Translation;
