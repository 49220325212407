import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Collapse, ListItem } from "@mui/material";
import { ChevronDown as ChevronDownIcon } from "../../icons/chevron-down";
import { ChevronRight as ChevronRightIcon } from "../../icons/chevron-right";
import { Link, NavLink } from "react-router-dom";

export const DashboardSidebarItem = (props) => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
  const [open, setOpen] = useState(!!openProp);
  
  
  const [isActive, setIsActive] = useState(!!active);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setIsActive((prevActive) => !prevActive);
  };

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }


  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          "& .MuiButton-startIcon": {
            color: active ? "secondary.main" : " #39A0CC",
          },
          // backgroundColor: active ? "rgba(255,255,255, 0.08)" : "",
          display: "block",
          mb: 0.5,
          py: 0,
          px: 2,
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronDownIcon fontSize="small" />
            )
          }
          disableRipple
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            // color: active ? "secondary.main" : "neutral.300",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: 3,
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            // "&:hover": {
            //   backgroundColor: "rgba(255,255,255, 0.08)",
            // },
            // "& .MuiButton-startIcon": {
            //   color: active ? "secondary.main" : "neutral.400",
            // },
            // "& .MuiButton-endIcon": {
            //   color: "neutral.400",
            // },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse
          in={open}
          sx={{ 
            mt: 0.5,
            "& .MuiButton-startIcon": {
              color: !open ? "secondary.main" : "#39A0CC",
            },
          }}
        >
          {children}
        </Collapse>
      </ListItem>
    );
  
  }

  // console.log(active);
  // Leaf
  return (
    <NavLink
      to={path}
      style={{
        textDecoration: "none",
        ...(active && {
          backgroundColor: "rgba(255,255,255, 0.08)",
          color: "transparent",
          fontWeight: "fontWeightBold",
        }),
        "& .MuiButton-startIcon": {
          color: "transparent",
        },
      }}
      // style={{ backgroundColor: "#FFFFFF" }}
    >
      <ListItem
        disableGutters
        sx={{
          display: "flex",
          mb: 0.5,
          py: 0,
          px: 2,
          ...(active && {
            "& .MuiButton-startIcon": {
              color: isActive ? "secondary.main" : "#39A0CC",
            },
            // backgroundColor: "rgba(255,255,255, 0.08)",
            // color: "secondary.main",
          }),
          "& .MuiButton-startIcon": {
            color: active ? "secondary.main" : "#39A0CC",
          },
        }}
      >
        <Button
          component="a"
          startIcon={icon}
          
          endIcon={chip}
          disableRipple
          sx={{
            borderRadius: 1,
            // color: "neutral.300",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: 3,
            textAlign: "left",
            textDecoration: "none",
            textTransform: "none",
            border:"none",
            width: "100%",
            ...(active && {
              backgroundColor: "rgba(255,255,255, 0.08)",
              color: "secondary.main",
              textDecoration:"none",
              fontWeight: "fontWeightBold",
            }),
            "& .MuiButton-startIcon": {
              color: active ? "secondary.main" : "#39A0CC",
              
            },
            "&:hover": {
              backgroundColor: "rgba(255,255,255, 0.08)",
            },
            "&:active": {
              backgroundColor: "rgba(255,255,255, 0.08)",
              color:"#39A0CC"
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
      </ListItem>
    </NavLink>
  );
};

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DashboardSidebarItem.defaultProps = {
  active: false,
  open: false,
};
