import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormInputRadio } from "../../../components/custom/form-components/FormInputRadio";
import { FormInputText } from "../../../components/custom/form-components/FormInputText";
import editIcon from "../../../icons/edit.svg";
import uploadIcon from "../../../icons/upload.png";
import { handleLanguagesChange } from "../../../reducers/courses/courses";
import { fileToBase64 } from "../../../utils/file-to-base64";
import { Close } from "@mui/icons-material";
import {
  durationTypeList,
  durationTypeListEng,
  durationTypeListRus,
  durationTypeListTaj,
} from "./enums";
import { getALLCourses, postCourse } from "../../../api/courses/courses";

import { FormInputDate } from "../../../components/custom/form-components/FormInputDate";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const defaultValues = {
  Logo: "",
  Language: "",
  Title: "",
  SubTitle: "",
  Description: "",
  TeachingFormat: "",
  StartDate: "",
  Duration: "",
  DurationType: "",
  NeededStudents: "",
  Price: "",
  DescriptionPrice: "",
  Enabled: false,
};

function Add() {
  const dataLanguages = useSelector(
    ({ courses }) => courses.courses.dataLanguages
  );
  const [value, setValue] = React.useState(
    dataLanguages.filter((elem) => elem.completed)[0]?.name
  );
  const [cover, setCover] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [color, setColor] = useState("#ffff");
  console.log(color);
  // const [month, setMonth] = useState("none");
  const [open, setOpen] = useState(false);
  const methods = useForm({ defaultValues: defaultValues });
  const [fieldValue, setFieldValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState(false);

  const { handleSubmit, register, control, setValue: setFormValue } = methods;

  const onSubmit = async (values) => {
    if (!values.Language) {
      values.Language = value;
    }
    console.log(dayjs(values.StartDate).format("MM/DD/YYYY"));
    values.StartDate = dayjs(values.StartDate).format("MM/DD/YYYY");
    const formData = new FormData();

    for (let key in values) {
      formData.append(key, values[key]);
    }
    formData.append("Color", color);
    if (!value) {
      toast("Select language", {
        icon: "🙏",
      });
    } else {
      await dispatch(
        postCourse({
          formData,
          callback: () => {
            navigate(-1);
          },
        })
      );

      dispatch(
        getALLCourses({
          Language: value ? value : "rus",
        })
      );
    }
  };

  // const { pathname } = useLocation();

  function handleToggle(id) {
    dispatch(handleLanguagesChange(id));
  }
  function changeDescValue(value) {
    setFieldValue(value);
    setFormValue("Description", fieldValue);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSwitchChange = (event) => {
    setDiscount(event.target.checked);
    setFormValue("Enabled", event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChangeMonth = (event) => {
  //   setMonth(event.target.value);
  // };
  const handleColor = (e) => {
    setColor(e.target.value);
  };

  const handleChange = (event, newValue) => {
    setFormValue("Language", newValue);
    setValue(newValue);
    setIsLoading(true);
  };
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
  console.log(isLoading);
  const handleDropCover = async (name, file) => {
    const data = await fileToBase64(file);
    setCover(data);
    setFormValue(name, file);
    return;
  };

  const handleRemove = (name) => {
    setCover(null);
    setFormValue(name, "");
  };
  useEffect(
    () => {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ marginLeft: 1 }}>
            <Typography variant="h4">
              <Button>
                {" "}
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginRight: "5px" }}
                />
              </Button>
              New course / Main
            </Typography>
          </Box>
          <Box>
            <Tabs
              style={{
                backgroundColor: "#C5C5C5",
                width: "435px",
                gap: "6px",
                height: "52px",
                padding: "5px",
                borderRadius: "16px",
              }}
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#212121",
                  gap: "10px",
                  minWidth: 0,
                  minHeight: 0,
                  borderRadius: "12px",
                },
                "& button": { width: "106px", textAlign: "center" },
                "& button:hover": { backgroundColor: "white" },
                "& button:focus": { backgroundColor: "white" },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
                "& .Mui-selected": {
                  backgroundColor: "white !important",
                },
              }}
            >
              {dataLanguages
                .filter((elem) => elem.completed)
                .map((e) => {
                  return (
                    <Tab
                      key={e.id}
                      icon={<img src={e.avatar} alt="russianIcon" />}
                      iconPosition="start"
                      value={e.name}
                      label={e.name}
                    >
                      <span
                        style={{
                          width: "1px",
                          height: "24px",
                          border: "1px solid rgb(163 163 155)",
                          marginTop: "12px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      ></span>
                    </Tab>
                  );
                })}
              <Tab
                onClick={handleClickOpen}
                icon={<img src={editIcon} alt="engIcon" />}
                style={{
                  height: "41px",
                  width: "41px",
                  background: "#E6E6E6",
                  lineHeight: "24px",
                  marginLeft: "15px",
                }}
                iconPosition="start"
              >
                {" "}
              </Tab>
            </Tabs>
          </Box>
        </Grid>

        {/* Add Course */}
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Card sx={{ mt: 4, position: "relative" }}>
                    <CardContent
                      sx={{
                        width: {
                          xs: "full",
                          sm: "500",
                          md: "550",
                          lg: "600px",
                        },
                      }}
                    >
                      <Typography variant="h6">Basic details</Typography>
                      <Box sx={{ mt: 3 }}>
                        <label style={{ marginBottom: "10px" }} for="Title">
                          Title
                        </label>
                        <FormInputText name="Title" control={control} />

                        <Box sx={{ mt: 2 }}>
                          <label for="Subtitle">Subtitle</label>
                          <FormInputText
                            multiline={true}
                            name="SubTitle"
                            control={control}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "500px",
                            paddingTop: "8px",
                            gap: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            Teaching format
                          </Typography>
                          <Box>
                            <FormInputRadio
                              control={control}
                              options={[
                                { value: "1", label: "Offline" },
                                { value: "2", label: "Online" },
                              ]}
                              name="TeachingFormat"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              mt: 2,
                              height: "88px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Price
                            </Typography>
                            <FormInputText
                              type="number"
                              name="Price"
                              control={control}
                            />
                          </Box>

                          <Box
                            sx={{
                              mt: 2,
                              width: "150px",
                              height: "88px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Duration
                            </Typography>
                            <FormInputText
                              name="Duration"
                              type="number"
                              control={control}
                            />
                          </Box>

                          <Box
                            sx={{
                              mt: 2,
                              width: "150px",
                              borderRadius: "16px",
                              height: "88px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Duration type
                            </Typography>
                            <Box sx={{ minWidth: 150 }}>
                              <FormInputText
                                name="DurationType"
                                control={control}
                                select={true}
                                options={
                                  value === "eng"
                                    ? durationTypeListEng
                                    : value === "taj"
                                    ? durationTypeListTaj
                                    : durationTypeListRus
                                }
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Start date
                            </Typography>
                            <FormInputDate name="StartDate" control={control} />
                          </Box>
                          <Box>
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                }}
                              >
                                Needed Students
                              </Typography>
                              <FormInputText
                                type="number"
                                name="NeededStudents"
                                control={control}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            position: "absolute",
                            right: 50,
                            top: 10,
                            width: "250px",
                          }}
                        >
                          <label for="Logo">
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "20px",
                              }}
                            >
                              Logo
                            </Typography>
                            <Box
                              sx={{
                                width: "250px",
                                height: "250px",
                                border: "2px dashed #C9DCEC",
                                borderRadius: "20px",
                                mt: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {cover ? (
                                <>
                                  <img
                                    src={cover}
                                    alt="alt"
                                    style={{
                                      width: "100%",
                                      height: 245,
                                      borderRadius: 20,
                                    }}
                                  />
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      top: 60,
                                      borderRadius: "50%",
                                      right: 20,
                                      color: "#fff",
                                      background: "rgba(0, 0, 0, 0.48)",
                                      "&:hover": {
                                        background: "rgba(0, 0, 0, 0.48)",
                                      },
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemove("Logo");
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </>
                              ) : (
                                <Box
                                  sx={{
                                    backgroundColor: "#F2F4F5",
                                    borderRadius: "100px",
                                    width: "64px",
                                    height: "64px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src={uploadIcon} alt="upload" />
                                </Box>
                              )}
                            </Box>
                            <input
                              type="file"
                              id="Logo"
                              {...register("Logo")}
                              onChange={(event) => {
                                handleDropCover("Logo", event.target.files[0]);
                              }}
                              style={{ display: "none" }}
                            />
                          </label>
                          <Box
                            sx={{
                              mt: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "20px",
                                mb: 1,
                              }}
                            >
                              Color
                            </Typography>
                            <TextField
                              type="color"
                              sx={{ width: "100%" }}
                              value={color}
                              onChange={handleColor}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              mt: 2,
                              height: "48px",
                              display: "flex",
                              width: "50%",
                              gap: "10px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderTop: "1px solid gray",
                              borderBottom: "1px solid gray",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Discount
                            </Typography>
                            <Switch
                              checked={discount}
                              onChange={handleSwitchChange}
                            />
                          </Box>

                          <Box
                            sx={{
                              mt: 2,
                              height: "88px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              Price
                            </Typography>
                            <FormInputText
                              disabled={!discount}
                              type="number"
                              name="DescriptionPrice"
                              control={control}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>

                    <Box sx={{ p: 3 }}>
                      <Typography>Description</Typography>
                      <Box
                        sx={{
                          height: "283px",
                          borderRadius: "16px",
                        }}
                      >
                        <ReactQuill
                          value={fieldValue}
                          onChange={(value) => changeDescValue(value)}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: [] }],
                              [{ size: [] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],

                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "image", "video"],
                              ["clean"],
                            ],
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "link",
                            "image",
                            "video",
                          ]}
                          theme="snow"
                          readOnly={false}
                          placeholder="Type something..."
                          className="custom-quill-editor"
                          style={{ height: "200px", borderRadius: "16px" }}
                          bounds=".scrollable-container"
                          scrollingContainer=".scrollable-container"
                        />
                      </Box>
                    </Box>
                  </Card>
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Button type="submit" variant="contained" value="submit">
                      Add
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      sx={{ border: "2px solid #B1C4D7", borderRadius: "16px" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>

      {/* Modal for Languages */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ backgroundColor: "#E6E6E6", width: "550px" }}>
          <DialogTitle id="alert-dialog-title">{"Add new"}</DialogTitle>

          <Card>
            <CardContent>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 486,
                  bgcolor: "background.paper",
                }}
              >
                {dataLanguages.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          checked={value.completed}
                          onClick={() => handleToggle(value.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt={`Avatar`} src={value.avatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={` ${value.language}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
          <Box
            container
            sx={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <Button onClick={handleClose} sx={{ m: 1 }} variant="contained">
              Edit
            </Button>

            <Button
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Add;
