import { createSvgIcon } from '@mui/material/utils';

export const UserCircleBlue = createSvgIcon(
    <svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
     fillRule="evenodd"
     clipRule="evenodd"
    d="M9.00165 0C11.4879 0 13.5016 2.01375 13.5016 4.5C13.5016 6.98625 11.4879 9 9.00165 9C6.5154 9 4.50165 6.98625 4.50165 4.5C4.50165 2.01375 6.5154 0 9.00165 0ZM9.00165 18C9.00165 18 18.0016 18 18.0016 15.75C18.0016 13.05 13.6141 10.125 9.00165 10.125C4.38915 10.125 0.00164795 13.05 0.00164795 15.75C0.00164795 18 9.00165 18 9.00165 18Z" fill="#506A85"/>
    </svg>
      , 'UserCircle');
