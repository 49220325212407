import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { axiosRequest } from "../../utils/axiosRequest";
import paramsForUrl2 from "../../utils/paramsForUrl2";

export const GetAllPosts = createAsyncThunk(
  "posts/GetAllPosts",
  async (param, { rejectWithValue }) => {
    let obj = paramsForUrl2(param).slice(1);
    try {
      const { data } = await axiosRequest.get(`Posts/GetAllPosts?${obj}`);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const postPost = createAsyncThunk(
  "posts/postPost",
  async ({ formData, callback }) => {
    try {
      const { data } = await axiosRequest.post(`Posts/AddNewPost`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (data.isSuccess) {
        callback();
        toast.success(data.message);
      }
      console.log(data);
    } catch (e) {}
  }
);

export const deletePost = createAsyncThunk("posts/deletePost", async (id) => {
  try {
    const { data } = await axiosRequest.delete(`Posts/DeletePost?PostId=${id}`);
    if (data.code === 200) {
      toast.success(data.message);
    }
    console.log(data);
  } catch (e) {
    toast.error(e);
  }
});
export const getPostById = createAsyncThunk(
  "posts/getPostById",
  async (props) => {
    console.log(props);
    try {
      const { data } = await axiosRequest.get(
        `Posts/GetPostById?PostId=${props.PostId}&Language=${props.Language}`
      );
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  }
);
export const updatePost = createAsyncThunk(
  "posts/updatePost",
  async ({ formData, callback }) => {
    try {
      const { data } = await axiosRequest.put(`Posts/UpdatePost`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (data.isSuccess) {
        callback();
        toast.success(data.message);
      }
      console.log(data);
    } catch (e) {}
  }
);
